import React from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";


// import './App.css';
import "@fortawesome/fontawesome-free/css/all.css"
import ReactGA from 'react-ga'
import MainPage from "./MainPage"
import { createTheme, responsiveFontSizes } from "@mui/material"


let theme = createTheme({
    typography: {
        fontFamily: "Rubik",
    },
    palette: {
      primary: {
        main: "#0050a9",
        light: "#3373BA",
        dark: "#004087",
        contrastText: "#FFFFFF",
      },
      custom: {
        primaryBlue: "#0050a9"
      },
      whiteOnBlue: {
        main: "#FFFFFF",
        light: "#FFFFFF",
        dark: "rgb(213,232,255)",
        contrastText: "#0050a9",
      },
    }
})
theme = responsiveFontSizes(theme)

ReactGA.initialize('UA-165571594-1');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainPage />
    </ThemeProvider>
  );
}

export default App;
