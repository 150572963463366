import "styles/patents.scss";
import React from "react";
import vae from "res/assets/Images/Patents/3dVariational.jpg";
import bioactivityAndPose from "res/assets/Images/Patents/BioactivityAndPoseClassification.jpg";
import knowledgeGraph from "res/assets/Images/Patents/KnowledgeGraph.jpg";
import knowledgeGraphDB from "res/assets/Images/Patents/KnowledgeGraphDB.jpg";
import pointCloud from "res/assets/Images/Patents/PointCloud.jpg";
import ct from "res/assets/Images/Patents/clinicalTrials.jpg";
import attentionMap from "res/assets/Images/Patents/AttentionMap.jpg";
import Retrosynthesis from "res/assets/Images/Patents/retrosynthesis_patent.png";
import ToxicSubstr from "res/assets/Images/Patents/Colored_molecule.png";

const patentData = [
  {
    name: "Data Platform For Automated Pharmaceutical Research Using Knowledge Graph",
    number: "US11080607B1",
    date: "08.03.2021",
    picture: knowledgeGraph,
    description: (
      <>
        Core technology at Ro5 that powers our target prioritization, knowledge
        graph, and clinical trial analytics capabilities. It is based on a
        concept of the knowledge graph, but one of the differentiating factors
        that differentiates it from other graphs is that we have implemented
        horizontal searching through computed relationships, which can include
        molecule similarity, protein sequence similarity, binding site
        similarity, etc.
      </>
    ),
  },
  {
    name: "System And Method For Prediction Of Protein-Ligand Interactions And Their Bioactivity",
    number: "US11176462B1",
    date: "11.16.2021",
    picture: attentionMap,
    description: (
      <>
        Leverages FASTA sequence (1D representation of the protein) and QSAR
        model in combination to predict the Bioactivity in low data regimes.
        Proprietary technology that leverages protein sequence data to increase
        the accuracy of the model, when the protein structure is not available.
      </>
    ),
  },
  {
    name: "System And Method For Biomarker-Outcome Prediction And Medical Literature Exploration",
    number: "US11257594B1",
    date: "22.02.2022",
    picture: ct,
    description: (
      <>
        Ro5’s Clinical Trial Analytics module identifies the most important
        biomarkers and contextualizes biological processes using the knowledge
        graph. Pharmaceutical companies can thus react fast during the ongoing
        trials, leveraging the tool’s ability to highlight the important signals
        and interrogate them with biological context extracted from our
        knowledge graph.
      </>
    ),
  },
  {
    name: "System And Method For Prediction Of Protein-Ligand Bioactivity Using Point-Cloud Machine Learning",
    number: "US11256995B1",
    date: "22.02.2022",
    picture: pointCloud,
    description: (
      <>
        A unique and proprietary 3D Bioactivity model that is based on point
        cloud technology. It leverages the 3D structure of the binding site and
        the 3D structure of the molecule and predicts how each atom of the
        ligand interacts with every atom of the binding site, through attention
        mechanisms. Moreover, using attention, we can visualize the specific
        interactions that contribute positively or negatively to bioactivity.
      </>
    ),
  },
  {
    name: "System And Method For Prediction Of Protein-Ligand Bioactivity And Pose Propriety",
    number: "US11256994B1",
    date: "22.02.2022",
    picture: bioactivityAndPose,
    description: (
      <>
        A unique and proprietary 3D Convolutional Neural Network system that
        learns spatial information of the molecule-protein complex to predict
        bioactivity. It consists of a bioactivity predictor and pose classifier.
        Once a correct pose is chosen, the bioactivity can be predicted. It is
        superior to other state-of-the-art models due to its ability to
        disentangle whether the pose is correct or incorrect. This model is one
        of the core components of our virtual screening capabilities, as well as
        3D de novo, which guides the generation of the molecule.
      </>
    ),
  },
  {
    name: "System And Method For Molecular Reconstruction And Probability Distributions Using A 3d Variational - Conditioned Generative Adversarial Network",
    number: "US11263534B1",
    date: "01.03.2022",
    picture: vae,
    noName: true,
    description: (
      <>
        {/* <div style={{ fontWeight: "bolder" }}>
          System And Method For Molecular Reconstruction And Probability
          Distributions Using A 3d Variational - Conditioned Generative
          Adversarial Network
        </div>
        <br />
        Sophisticated generative process allows end-to-end modelling of
        molecules in 3D space. Molecules and their pharmacological features are
        represented in 3D space and modeled using deep learning generative
        techniques. This allows for continuous optimization of chemical
        properties, generate new molecules with the same pharmacophores,
        scaffold hopping from lead compounds, and maintaining functional groups.
        <br />
        <br />
        Our modular 3D approach allows integration with bioactivity models,
        which can signal the generative processes to efficiently explore the
        chemical space to create potent, novel, patent-free molecular
        representations.
        <br />
        <br /> */}
        <div style={{ fontWeight: "bolder" }}>
          System And Method For Molecular Reconstruction And Probability
          Distributions Using A 3d Variational - Conditioned Generative
          Adversarial Network
        </div>
        <br />
        Sophisticated generative process with transcription from 3D spaces to
        SMILES that allows for the optimization of chemical properties and
        bioactivity, generating new molecules with similar pharmacophore
        distributions while maintaining key functional groups. Currently, there
        is no effective way to traverse between 3D space to a comprehensible and
        computer-interpretable representation without Ro5’s platform - this is
        one of the key components that makes our 3D De Novo both reliable and
        scalable.
      </>
    ),
  },
  {
    name: "System And Method For Automated Pharmaceutical Research Utilizing Context Workspaces",
    number: "US11264140B1",
    date: "01.03.2022",
    picture: knowledgeGraphDB,
    description: (
      <>
        A framework to store biomedical data and contextualize it using existing
        scientific knowledge. During the drug discovery process, or related
        academic work, researchers need to deal with large datasets, which
        limits their ability to take into account all of this data and see the
        complete picture. By storing and contextualizing these datasets using
        Ro5’s knowledge graph, AI and EDA platforms, we provide a big picture
        view to the scientist of their work, its relationship with all existing
        work, and new hypotheses.
      </>
    ),
  },
  {
    name: "System And Method For Automated Retrosynthesis",
    number: "US11354582B1",
    date: "07.06.2022",
    picture: Retrosynthesis,
    description: (
      <>
        Novel and/or optimized compounds must be synthesizable, both for
        industrial purposes and for verification (screening). The ease of
        synthesis highly influences the scale of compounds to be tested in
        vitro. For that matter, it is paramount to verify that compounds can be
        synthesized, preferentially from commercially available precursors. To
        that end, our AI-based retrosynthesis system constructs a backward chain
        of reactions that successively leads to the target (root) molecule,
        deconstructing a rather complex, and often commercially unavailable
        target molecule with its synthesizable descendants.
      </>
    ),
  },
  {
    name: "Toxic Substructure Extraction Using Clustering And Scaffold Extraction",
    number: "US11367006B1",
    date: "21.06.2022",
    picture: ToxicSubstr,
    description: (
      <>
        The scalable substructure extraction and clustering tool allows us to
        operate around molecules based on their ADMET properties and activity,
        thereby providing deeper insights. Chemists typically do not have the
        tools to draw such insights from large amounts of data, although this
        can be effectively accomplished with Ro5’s substructure and scaffold
        extraction combined with our clustering algorithms.
      </>
    ),
  },
];

export default patentData;
