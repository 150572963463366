/**
 * Component for the ADMET Ro5 platforms.
 *  Contains a header and body using the plattform components.
 * @author Amit Setty
 */
import React, { useEffect } from "react"

import PlatformHeader from "./PlatformHeader"
import PlatformComponent from "./PlatformComponent"
import ADMETInfographic from "res/assets/Infographics/ADMET.svg"
import ADMETGIF from 'res/assets/Images/ADMET/admet_small_optimised.gif';
import ADMETDRAWER from 'res/assets/Images/ADMET/admet_small1.png';
import ADMETASSAYS from 'res/assets/Images/ADMET/admet_small2.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import macbookAir from "../../res/assets/Images/Macbook/Macbook_Air.png";
import ServicesWidget from "Pages/Services/ServicesWidget"
import { useNavigate } from "react-router"
import { PageTemplate } from "../PageTemplate"
const pictures = [ADMETInfographic];
const whyChooseUs = [
  "ADMET models achieve over 75% accuracy over a wide range of ADME-T prediction tasks.",
  "Attention maps allow the identification of the relevant features of the molecule."
]

const benefitsList = [
  "Rapidly evaluate molecules through virtual ADME-Tox screening and structural alerts",
  "Assess compounds simultaneously across multiple assays",
  "Dive deep into the results using an interactive and user-friendly analytics platform",
  "Leverage custom ADME-Tox models through Ro5’s fine-tuning pipeline, which can incorporate your data for better results"]

function ADMET() {
  const navigate = useNavigate()

  useEffect(() => {document.title = "ADMET"}, [])

  const settings = {
    dots: true,
    appendDots: dots => (<div id="macbookSlickDots">{dots}</div>),
    // nextArrow: <NextArrow />,
    // previousArrow: <PreviousArrow />,
    lazyLoad: false,

    afterChange: function (index) {
    }
  };
  const settingsMobile = {
    dots: true,
    appendDots: dots => (<div>{dots}</div>),
    arrows: false,
    lazyLoad: false,

    afterChange: function (index) {
    }
  };

  useEffect(() => {
    pictures.forEach(img => new Image().src = img)
  }, [])

  return (
    <PageTemplate>
      <div>
        <PlatformHeader
          title="ADMET"
          infographic={ADMETInfographic}
          desc={`Ro5’s ADME-Tox platform allows chemists to quickly estimate important pharmacokinetics properties for potential drugs.
          Using millions of molecules for pre-training infuses the ADME-Tox models with knowledge of a wide chemical space.
`}
        />
      </div>

      {/* Page Content */}
      <div className="desktopFlex">
        <div style={{ flex: 1 }} />
        <div style={{ flex: 2.5 }} >

          <PlatformComponent
            title="Instantly predict a potential drug’s pharmacokinetic properties"
            desc={
              <div className="description">
                <p>Ro5’s cutting edge ADME-Tox models can provide insights into compound pharmacokinetics and pharmacodynamics, by predicting the outcomes of assays such as:</p>
                <ul style={{ fontSize: '1em', lineHeight: '1.2em' }}>
                  <li>Absorption</li>
                  <ul className="subList">
                    <li>Caco-2 permeability</li>
                    <li>PAMPA permeability</li>
                    <li>Human intestinal absorption (HIA)</li>
                  </ul>
                  <li>Distribution</li>
                  <ul className="subList">
                    <li>Blood-brain barrier (BBB) permeability</li>
                    <li>P-glycoprotein (P-gp) inhibitor/substrate</li>
                  </ul>
                  <li>Metabolism</li>
                  <ul className="subList">
                    <li>Cytochrome P450 (CYP450) inhibitor/substrate</li>
                    <ul className="subSubList">
                      <li>CYP2D6, CYP3A4, CYP2C9, CYP1A2, CYP2C19</li>
                    </ul>
                    <li>Microsomal stability</li>
                  </ul>
                  <li>Toxicity</li>
                  <ul className="subList">
                    <li>Hepatotoxicity</li>
                    <li>Human ether-a-go-go-related gene gene (hERG) inhibition</li>
                    <li>Ames mutagenicity</li>
                  </ul>
                </ul>
                <p>You may request a custom model for a specific assay enriched by your in-house data.</p>
                <p>To counter the low availability of data for ADME-Tox, our machine learning models infuse information on related properties of millions of molecules -- knowledge from a vast molecular space to increase the accuracy of the predictions.</p>
                <p>Moreover, Ro5 has developed an extensive library of structural alerts that allow for the early identification of toxic molecules as well as potentially risky substructures, ensuring the highest success rate for your top compounds.</p>
              </div>
            }
          />
        </div>
        <div style={{ flex: .5 }} />
        <div style={{ flex: 1.9 }} >
          <ServicesWidget
            listName={"Why Choose Us?"}
            isWhite={false}
            list={whyChooseUs}
          />
          <ServicesWidget
            listName={"Benefits At a Glance"}
            isWhite={false}
            list={benefitsList}
          />
          {/* <BenefitsDialogBox benefits={benefitsList} /> */}
          <div style={{ paddingTop: '2em' }} />

        </div>
        <div style={{ flex: .7 }} />
      </div>
      <center>
      <button onClick={() => navigate("/contact-us")} className="form-submit-button btn btn-primary">
        Contact us
      </button>
      </center>
    
      {/*<center>*/}
      {/*  <Suspense fallback="">*/}
      {/*    <Slider {...options}>*/}
      {/*      {*/}
      {/*        images.map((image, idx) => (*/}
      {/*          <div key={idx}>*/}
      {/*            <img style={{ maxWidth: '60%', minWidth: '10%' }} src={image} alt='ADMET Thumbnail' />*/}
      {/*          </div>*/}
      {/*        ))*/}
      {/*      }*/}
      {/*    </Slider>*/}
      {/*  </Suspense>*/}
      {/*</center>*/}

      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <center className="desktopKnowledgeGraphPictures" style={{ position: 'relative', width: '50%', height: '50%' }}>
          <img className="macbookAir" alt={"MacbookAir"} src={macbookAir} />

          <div style={{ position: 'absolute', zIndex: 5, width: '100%', height: '100%', top: '9.7%', left: '13%' }}>


            <Slider {...settings}>
              {[1, 2, 3].map(id =>
                id === 1 ?
                  <img key={id} style={{ zIndex: 2, }} src={ADMETGIF} alt="De Novo" />
                  : id === 2 ?
                    <img key={id} style={{ zIndex: 2, }} src={ADMETDRAWER} alt="De Novo" /> :
                    <img key={id} style={{ zIndex: 2, }} src={ADMETASSAYS} alt="De Novo" />
              )}
            </Slider>
          </div>
        </center>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <center className="mobileKGSlider" style={{ width: '80%', height: '80%' }}>
          <Slider {...settingsMobile}>
            {[1, 2, 3].map(id =>
              id === 1 ?
                <img key={id} style={{ zIndex: 2, }} src={ADMETGIF} alt="De Novo" />
                : id === 2 ?
                  <img key={id} style={{ zIndex: 2, }} src={ADMETDRAWER} alt="De Novo" /> :
                  <img key={id} style={{ zIndex: 2, }} src={ADMETASSAYS} alt="De Novo" />
            )}
          </Slider>
        </center>
      </div>
    </PageTemplate>
  )
}

export default ADMET