/**
 *  Modal displaying more info about a team member.
 * Is displayed onClick of a TeamMemberCard
 *
 * @author Amit Setty
 */
import React from "react";
import { Modal } from "react-bootstrap";
function MyTeamModal(props) {
  const { isModalOpen, closeModal, currentPerson, advisoryBoardMember } = props;
  return (
    <Modal size="lg" show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ width: "100%" }}>
          <center style={{ marginLeft: 45 }}>{currentPerson.name}</center>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ paddingLeft: "1em" }}>
          <center>
            <img
              style={{ maxWidth: "50%" }}
              src={currentPerson.image}
              alt={currentPerson}
            />
            <br />
            <br />
            {advisoryBoardMember ? (
              currentPerson &&
              currentPerson.bioRole.map((role) => (
                <div >
                  <b style={{fontSize: "1.5rem"}}>{role}</b>
                </div>
              ))
            ) : (
              <b style={{fontSize: "1.5rem"}}>{currentPerson.headline ? currentPerson.headline : currentPerson.branch}</b>
            )}
          </center>
          <div style={{ paddingTop: "1.2em" }} />
          <span style={{ textAlign: "center", fontSize: "1.5rem" }}>{currentPerson.content}</span>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default MyTeamModal;
