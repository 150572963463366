/**
 * Component containing landing page information on the site
 * @author Amit Setty
 */
import React, { lazy, Suspense } from "react"
import AboutRo5 from "./LandingPageSegments/AboutRo5"
import OurImpact from "./LandingPageSegments/OurImpact"
import OurSolutions from "./LandingPageSegments/OurSolutions"

const HowWeWorkPromise = import('./LandingPageSegments/HowWeWork')
const CoreValuesPromise = import('./LandingPageSegments/CoreValues')
const PlatformsPromise = import('./LandingPageSegments/Platforms')
const WidgetsPromise = import('./LandingPageSegments/Widgets')
const TheChallengePromise = import('./LandingPageSegments/TheChallenge')
const OurPartnersPromise = import('./LandingPageSegments/OurPartners')

const HowWeWork = lazy(() => HowWeWorkPromise)
const CoreValues = lazy(() => CoreValuesPromise)
const Platforms = lazy(() => PlatformsPromise)
const Widgets = lazy(() => WidgetsPromise)
const TheChallenge = lazy(() => TheChallengePromise)
const OurPartners = lazy(() => OurPartnersPromise)

function LandingPageContent() {

    return (
        <div style={{ position: 'relative' }}>
            <div>
                <AboutRo5 />
            </div>

            <div>
                <Widgets />
            </div>

            <div style={{ paddingTop: '2.5em' }} />
            <TheChallenge/>
            <OurSolutions/>
       
            <Suspense fallback={<div></div>}>
                <Platforms />
            </Suspense>

            <OurImpact id="Our Impact" />
            <div style={{ paddingTop: '2.5em' }} />

            <Suspense fallback={<div></div>}>
                <OurPartners />
            </Suspense>

            <Suspense fallback={<div></div>}>
                <HowWeWork id="How We Work" />
            </Suspense>

            <Suspense fallback={<div></div>}>
                <CoreValues />
            </Suspense>
        </div>
    )
}

export default LandingPageContent
