import React, { useEffect } from "react";
import "styles/history.scss";
import picture from "../../../res/assets/Images/NewsArticles/brock.jpg";
import quote from "res/assets/Background/quote.svg";
import PlatformComponent from "Pages/platforms/PlatformComponent";

function NewMemberBrock() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const storyStyle = {
    width: "45%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: "67.5px",
    fontFamily: "rubikRegular",
  };
  return (
    <>
      <center
        className="historyHeader"
        style={{ position: "relative", marginBottom: "1.5em" }}
      >
        <div style={storyStyle}>Ro5 News</div>
        <div className="story">17.01.2022</div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="newMemberPage">
        <center>
          <img src={picture} className="newMemberMobilePagePhoto" alt="Brock" />
        </center>
        <div className="newMemberPageTop">
          <div className="newMemberArticle">
            <PlatformComponent
              title="Ro5 Welcomes Dr. Brock Luty As Principal Computational Chemist"
              desc="Dr. Brock Luty’s 25-plus years of applying software engineering and informatics to drug discovery 
              range from his undergrad degree in chemical engineering to a Ph.D. in chemical physics under Dr. Andy 
              McCammon--then ETH in Zurich in the Human Frontiers program with Prof. Wilfred van Gunsteren. Helping 
              Agouron Pharmaceuticals develop novel SBDD methods, he rose to associate research fellow in Pfizer’s 
              Computational Science Center of Excellence. He directed the scientific computing department at Dart 
              Neuroscience and later served in ChemAxon’s core architecture team. In fall 2021, he joined Ro5 as principal
              computational chemist, further augmenting the platform with his experience and knowledge of molecular
              interactions and drug discovery informatics."
            />
          </div>
          <div>
            <img src={picture} className="newMemberPagePhoto" alt="Brock" />
          </div>
        </div>
      </div>
      <div
        className="blueBg"
        style={{ display: "flex", position: "relative", marginTop: "8vh" }}
        id="Our Impact"
      >
        <div
          className="bg-quote"
          style={{ backgroundImage: `url(${quote})` }}
        />
        <div
          style={{
            width: "70vw",
            margin: "auto",
            paddingTop: "5em",
            paddingBottom: "5em",
          }}
        >
          <div style={{}}>
            <blockquote>
              <p
                className="quoteFont"
                style={{ color: "white", lineHeight: "1.2em" }}
              >
                “
                <b>
                  After 25 years of honing deep knowledge of fundamental
                  molecular interactions and solvation effects important to drug
                  discovery, it’s exciting now to help Ro5 take its platform
                  even further.
                </b>
                ”
              </p>

              <div style={{ display: "flex", alignItems: "center" }}>
                <cite>
                  <span className="bold">- Dr. Brock Luty</span> <br />
                </cite>
              </div>
            </blockquote>
          </div>
        </div>
        <div style={{ lex: 1 }}></div>
      </div>
    </>
  );
}

export default NewMemberBrock;
