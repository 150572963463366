/**
 * Component that is Part of the landing page
 * @author Amit Setty
 */

import React from "react";
import moleculeBG from "res/assets/Background/moleculeRotated.svg";

function ChallengeComponent(props) {
  const { title, desc } = props;

  const descStyle = {
    textAlign: "center",
    paddingTop: "1em",
    boxShadow: "9px 23px 15px -3px rgba(0,0,0,0.1)",
    backgroundColor: "",
    borderRadius: 20,
    padding: 20,
  };

  return (
    <div>
      <div className="bold" style={{ color: "white", fontSize: "1.6em" }}>
        {title}
      </div>
      <div className="blueBg" style={{ color: "white", ...descStyle }}>
        {desc}
      </div>
    </div>
  );
}

function OurSolutions() {

  const scrollToHtmlElement = (htmlElementId) => {
    const htmlElement = document.getElementById(htmlElementId);
    htmlElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }

  return (
    <div
      style={{ position: "relative", paddingTop: "5em", paddingBottom: "5em" }}
      id="How We Work"
    >
      <center className="categoryHeader">Our Solutions</center>
      <center
        className="categoryTitle"
        style={{ width: "75%", margin: "auto" }}
      >
        Ro5 makes AI accessible to the biopharma industry by providing the
        expertise, infrastructure, software, models, and data necessary for
        digital transformation. We are creating generalizable solutions for the
        whole biopharma industry.
      </center>
      <div style={{ paddingBottom: "2.5em" }} />

      <div style={{ marginLeft: 30 }}>
        <center className="responsiveGridCore responsiveGrid3 closeGrid700">
          <ChallengeComponent desc="We provide the expertise and support for biopharma companies to integrate AI into their workflow" />
          <ChallengeComponent desc="Ro5 machine learning platforms shorten drug discovery timelines, increase accuracy, and lower costs exponentially by satisfying data analytics needs across the whole drug discovery pipeline" />
          <ChallengeComponent desc="Our software platforms and proprietary AI models keep our clients’ data secure while converting their data into actionable insights" />
          {/* <li>We provide the expertise and support for biopharma companies to integrate AI into their workflow.</li>
                    <li>Our software platforms and proprietary AI models keep our clients’ data secure while converting their data into actionable insights.</li>
                    <li>Ro5 machine learning platforms shorten drug discovery timelines, increase accuracy, and lower costs exponentially by satisfying data analytics needs across the whole drug discovery pipeline.</li> */}
        </center>
      </div>
      <center style={{ width: "85%", margin: "auto", marginTop: 30 }}>
        <br />
        Our products are developed for real-world challenges, solving the unique
        problems faced by our biopharma clients that have their boots on the
        ground of R&D.
        <br />
        <br />
      </center>
      <center>
        <button
          style={{ margin: 10, width: 300 }}
          className="form-submit-button btn btn-primary"
          onClick={() => scrollToHtmlElement("platforms")}
        >
          AI Platforms
        </button>
        <button
          style={{ margin: 10, width: 300 }}
          className="form-submit-button btn btn-primary"
        >
          <a style={{ textTransform: "none" }} href="/services">
            Services
          </a>
        </button>
      </center>

      <div
        className="bg-molecule-2"
        style={{ backgroundImage: `url(${moleculeBG})` }}
      />
    </div>
  );
}

export default OurSolutions;
