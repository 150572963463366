import React from "react";
import { useNavigate } from "react-router-dom";
import calendarIcon from "res/assets/Icons/Utility/calendar-blue.png";
import { titleToPath } from "utils";

function CaseStudiesWidget({ title, date, picture }) {
  const navigate = useNavigate();

  function handleCaseStudyClick() {
    navigate(`/case-studies/${titleToPath(String(title))}`);
  }

  return (
    <div className="patentWidget" onClick={() => handleCaseStudyClick()}>
      <img src={picture} alt="Case Study" className="patentPicture" />
      <div
        style={{ fontSize: "1.7rem", fontWeight: "500", marginBottom: "3vh", marginTop: "3vh" }}
      >
        {title}
      </div>
      <div style={{ width: "95%", position: "absolute", bottom: 0 }}>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "0.8vh",
          }}
        >
          {date && (
            <div style={{ display: "flex" }}>
              <img
                src={calendarIcon}
                className="calendarIcon"
                alt="Calendar Icon"
              />
              <div style={{ marginLeft: "0.5vw", fontSize: "1.2rem" }}>
                {date}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CaseStudiesWidget;

