import React from "react"
import { createBrowserRouter, Navigate, RouterProvider, } from "react-router-dom"
import JoinUs from "Pages/JoinUs/JoinUs"
import JobPage from "Pages/JoinUs/JobPage"
import Applications from "./Pages/Applications/Applications"
import ADMET from "Pages/platforms/ADMET"
import Bioactivity from "Pages/platforms/Bioactivity"
import ClinicalTrials from "Pages/platforms/ClinicalTrials"
import KnowledgeGraph from "Pages/platforms/KnowledgeGraph"
import ContactUs from "Pages/ContactUs"
import DeNovo from "Pages/platforms/DeNovo"
import LandingPage from "Pages/landingPage/LandingPage"
import PatentPage from "Pages/PatentPage/PatentPage"
import PublicationPage from "Pages/PublicationPage/PublicationPage"
import MeetTheTeam from "Pages/Team/MeetTheTeam"
import AdvisoryBoard from "Pages/AdvisoryBoard/AdvisoryBoard"
import NewsMasterPage from "Pages/news/NewsMasterPage/NewsMasterPage"
import NewsPageHandler from "Pages/news/NewsMasterPage/NewsPageHandler"
import Thanks from "Pages/Thanks"
import Services from "Pages/Services/Services"
import ClinicalTrialAnalytics from "Pages/Services/ClinicalTrialAnalytics"
import TargetEvalPrio from "Pages/Services/TargetEvalPrio"
import HitIdentification from "Pages/Services/HitIdentification"
import HitLeadOpt from "Pages/Services/HitLeadOpt"
import { useNavigate } from "react-router"
import { CircularProgress } from "@mui/material"
import CaseStudiesMasterPage from "Pages/CaseStudies/CaseStudiesMasterPage"
import CaseStudiesPageHandler from "Pages/CaseStudies/CaseStudiesPageHandler"

const router = createBrowserRouter([
  { path: "/", index: true, Component: LandingPage },
  { path: "/the-ro5-team", Component: MeetTheTeam },
  { path: "/the-ro5-advisory-board", Component: AdvisoryBoard },
  { path: "/de-novo-drug-discovery", Component: DeNovo },
  { path: "/bioactivity", Component: Bioactivity },
  { path: "/ADMET", Component: ADMET },
  { path: "/clinical-trial-analytics", Component: ClinicalTrials },
  { path: "/knowledge-graph", Component: KnowledgeGraph },
  { path: "/applications", Component: Applications },
  { path: "/services", Component: Services },
  { path: "/services/client-programs", Component: Services },
  { path: "/services/target-eval-and-prio", Component: TargetEvalPrio },
  { path: "/services/hit-identification", Component: HitIdentification },
  { path: "/services/hit-to-lead-and-lead-optimization", Component: HitLeadOpt },
  { path: "/services/clinical-trial-programs", Component: ClinicalTrialAnalytics },
  { path: "/patent-page", Component: PatentPage },
  { path: "/publication-page", Component: PublicationPage },
  { path: "/client-programs", loader: [() => {useNavigate()("/services/client-programs", { state: { pagePart: "clientProg" } })}] },
  { path: "/join-us", Component: JoinUs },
  { path: "/join-us/:jobId", Component: JobPage },
  { path: "/contact-us", Component: ContactUs },
  { path: "/news", Component: NewsMasterPage },
  { path: "/news/:articleId", Component: NewsPageHandler },
  { path: "/case-studies", Component: CaseStudiesMasterPage },
  { path: "/case-studies/:articleId", Component: CaseStudiesPageHandler },
  { path: "/thanks", Component: Thanks },
  { path: "*", element: <Navigate to="/" replace />  }
])

function MainPage () {

  return <RouterProvider router={router} fallbackElement={<CircularProgress />} />
}

export default MainPage
