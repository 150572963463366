/**
 * Component for the Bioactivity Ro5 platforms.
 *  Contains a header and body using the plattform components.
 * @author Amit Setty
 */
import React, { useEffect } from "react"
import bioactivityInfographic from "res/assets/Infographics/Bioactivity.svg";
import PlatformHeader from "./PlatformHeader";
import PlatformComponent from "./PlatformComponent";
import BioGif from "res/assets/Images/Bioactivity/protein_viewer_loop_optimised.gif";
import BioTable from "res/assets/Images/Bioactivity/bio2.png";
import BioDataBot from "res/assets/Images/Bioactivity/bioactivity.png";
import ServicesWidget, { BulletedServicesWidget } from "Pages/Services/ServicesWidget";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import macbookAir from "../../res/assets/Images/Macbook/Macbook_Air.png";
import { PageTemplate } from "../PageTemplate"

const whyChooseUs = {
  "The Structure-based method allows": [
    "2x improvement in accuracy over docking scores. Identification of the correct binding pose in 85% of cases across all protein families.",
    "In virtual screening, capturing the complete diversity of existing hits in the top 1% of suggested compounds",
    "In virtual screening, 18% hit recovery in the top 1% of the ranked compounds, and 63.9% hit recovery in the top 20% of the ranked compounds."
  ],
  "The ligand-based method allows": [
    "In iterative HTS experiments, >60% hit recovery, with only 15% of the library screened."
  ]
}
const benefits = [
  "Uses state-of-the-art transformer architecture",
  "Rapidly and accurately screen large compound libraries against a protein target",
  "Leverage custom models enriched by client data to sharpen predictions",
  "Understand the model predictions, thanks to transparent and explainable systems",
  "Utilize a suite of powerful visualizations and data-analysis tools through an intuitive user interface",
];

function Bioactivity() {

  useEffect(() => {document.title = "Bioactivity"}, [])

  const settings = {
    dots: true,
    appendDots: (dots) => <div id="macbookSlickDots">{dots}</div>,
    lazyLoad: false,

    afterChange: function (index) {
    },
  };

  const settingsMobile = {
    dots: true,
    appendDots: (dots) => <div>{dots}</div>,
    arrows: false,
    lazyLoad: false,

    afterChange: function (index) {
    },
  };

  return (
    <PageTemplate>
      <PlatformHeader
        title="Bioactivity"
        infographic={bioactivityInfographic}
        desc="Ro5’s Bioactivity platform enables rapid screening of ligands against a target protein of choice, predicting binding affinities based on observed interactions  in order to enrich hit rates in downstream experiments."
      />

      {/* Page Content */}
      <div className="desktopFlex">
        <div style={{ flex: 1 }} />
        <div style={{ flex: 1.9 }}>
          {/* <BenefitsDialogBox benefits={benefits} /> */}
          <div style={{ marginBottom: "3vh" }}>
            <BulletedServicesWidget
              listName={"Why Choose Us"}
              isWhite={false}
              mappedLists={whyChooseUs}
            />
            <ServicesWidget
              listName={"Benefits At a Glance"}
              isWhite={false}
              list={benefits}
            />
          </div>
        </div>
        <div style={{ flex: 0.5 }} />
        <div style={{ flex: 2.5 }}>
        <PlatformComponent
            title="Structure-Based, transformer-powered bioactivity prediction"
            desc="Ro5’s machine learning models are trained on an internal database of millions of protein-ligand complexes to accurately predict protein-ligand binding affinities in a fraction of the time and cost of experiments — enriching hit rates in all subsequent stages of drug discovery."
          />
          <PlatformComponent
            title="Interpretable Predictions"
            desc="Our bioactivity models highlight the interactions on which the predictions are based in graphic “attention maps,” allowing chemists to quickly assess key predictions and confidently rank candidate molecules.
                  The power of Ro5’s platforms is to analyze, visualize and pave the shortest path to the most promising molecular structures. The cheminformatics and machine learning tools seamlessly integrate with your in-house workflow."
          />
          <PlatformComponent
            title="Ligand-Based Bioactivity prediction"
            desc="Our ligand-based bioactivity solutions allow the selection of the most appropriate model and molecule representation for a given task. We can leverage publicly available or client data and finetune the models for the chemical space in question and rapidly provide bioactivity predictions for thousands of molecules."
          />
        </div>

        <div style={{ flex: 0.7 }} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <center
          style={{ position: "relative", width: "50%", height: "50%" }}
          className="bioactivityDesktopSlider"
        >
          <img className="macbookAir" alt={"MacbookAir"} src={macbookAir} />

          <div
            style={{
              position: "absolute",
              zIndex: 5,
              width: "100%",
              height: "100%",
              top: "9.7%",
              left: "13%",
            }}
          >
            <Slider {...settings}>
              {[1, 2, 3].map((id) =>
                id === 1 ? (
                  <img
                    key={id}
                    style={{ zIndex: 2 }}
                    src={BioGif}
                    alt="Bioactivity"
                  />
                ) : id === 2 ? (
                  <img
                    key={id}
                    style={{ zIndex: 2 }}
                    src={BioTable}
                    alt="Bioactivity"
                  />
                ) : (
                  <img
                    key={id}
                    style={{ zIndex: 2 }}
                    src={BioDataBot}
                    alt="Bioactivity"
                  />
                )
              )}
            </Slider>
          </div>
        </center>
        <center
          style={{ width: "90%", height: "90%" }}
          className="bioactivityMobileSlider"
        >
          <div
            style={{
              zIndex: 5,
              width: "100%",
              height: "100%",
              top: "9.7%",
              left: "13%",
              marginTop: "5vh",
            }}
          >
            <Slider {...settingsMobile}>
              {[1, 2, 3].map((id) =>
                id === 1 ? (
                  <img
                    key={id}
                    style={{ zIndex: 2 }}
                    src={BioGif}
                    alt="Bioactivity"
                    loading="lazy"
                  />
                ) : id === 2 ? (
                  <img
                    key={id}
                    style={{ zIndex: 2 }}
                    src={BioTable}
                    alt="Bioactivity"
                  />
                ) : (
                  <img
                    key={id}
                    style={{ zIndex: 2 }}
                    src={BioDataBot}
                    alt="Bioactivity"
                  />
                )
              )}
            </Slider>
          </div>
        </center>
      </div>
    </PageTemplate>
  );
}

export default Bioactivity;
