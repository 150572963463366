/**
 * General component for the headers of all platforms. This component maintains the 
 * overall structure of the content of the platform pages
 * @author Amit Setty
 */
import React from "react"
import 'styles/platforms.scss'

function PlatformHeader(props) {
    const { infographic, title, desc } = props

    let className = () => {
        switch (true) {
            case /ADMET/.test(title):
                return "platform-admet-bg"
            case /Bioactivity/.test(title):
                return "platform-bio-bg"
            case /Clinical/.test(title):
                return "platform-clinical-bg"
            case /Novo/.test(title):
                return "platform-novo-bg"
            case /Knowledge/.test(title):
                return "platform-data-bg"
            default:
                return ""
        }
    };

    return (
        <div className="deNovoFlex deNovoHeader" style={{ position: 'relative' }}>
            <div style={{ flex: .2 }} />

            <div className='mobile90W' style={{ paddingTop: '20%', textAlign: 'left', flex: .8 }}>
                <h1 className="bold">{ title }</h1>
                <h4 className="light platformDesc">{ desc }</h4>
            </div>

            <center style={{ flex: 1.7 }}>
                <div style={{ paddingTop: '2em' }} className="desktop" />
                <object
                    style={{ paddingBottom: '2em', width: '80%', height: '90%', paddingTop: '10%', margin: 'auto' }}
                    alt="infographic"
                    type="image/svg+xml"
                    data={infographic}
                >
                    Infographic
                </object>
            </center>
            <div className={className()} />
        </div>
    )
}
export default PlatformHeader