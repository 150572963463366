/**
 * Contact Us Component
 *
 * The weird js here isn't the most intuitive but is essentially
 * just creating a custom select element
 *
 *
 * @author Amit Setty
 */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import { Alert as MuiAlert } from "@mui/material";

import "styles/contact.scss";
import { PageTemplate } from "./PageTemplate"

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Contact us"
  }, []);

  useEffect(() => {
    var x, i, j, selElmnt, a, b, c;
    /*look for any elements with the class "custom-select":*/
    x = document.getElementsByClassName("custom-select");
    if (x[0].childElementCount > 1) return;
    for (i = 0; i < 1; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      /*for each element, create a new DIV that will act as the selected item:*/
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      /*for each element, create a new DIV that will contain the option list:*/
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < selElmnt.length; j++) {
        /*for each option in the original select element,
        create a new DIV that will act as an option item:*/
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function () {
          /*when an item is clicked, update the original select box,
          and the selected item:*/
          var y, i, k, s, h;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          h = this.parentNode.previousSibling;
          for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML === this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              for (k = 0; k < y.length; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function (e) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }

    function closeAllSelect(elmnt) {
      /*a function that will close all select boxes in the document,
      except the current select box:*/
      var x,
        y,
        i,
        arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      for (i = 0; i < y.length; i++) {
        if (elmnt === y[i]) {
          arrNo.push(i);
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }
    /*if the user clicks anywhere outside the select box,
    then close all select boxes:*/
    document.addEventListener("click", closeAllSelect);
  }, []);

  let currentPath = useLocation().pathname.substring(1);
  const [inquiryType, setInquiryType] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    setInquiryType(currentPath.substring(currentPath.indexOf("/") + 1));
  }, [currentPath]);


  return (
    <PageTemplate>
      <div className="contactUsHeader">
        <center className="bold contact-header-content">Contact</center>
        <div className="bg-polygon-outline" />
        <div className="bg-rounded-polygon" />
      </div>

      <div
        className="contactDiv"
        style={{ display: "flex", width: "80%", margin: "auto", gap: 25 }}
      >
        <div className="contact-general">
          <center className="generalEnquiries">
            <h3 className="bold primary-text">General Enquiries</h3>
            <a className="light" href="mailto:info@ro5.ai">
              <h4 style={{ textTransform: "lowercase" }}>info@ro5.ai</h4>
            </a>
          </center>
          <div className="bg-molecule" />
        </div>
        <div style={{ flex: 3 }} className="contactInput">
          <div className="contact-general-mobile">
            <center>
              <h3 className="bold primary-text">General Enquiries</h3>
              <a className="light" href="mailto:info@ro5.ai">
                <h4 style={{ textTransform: "lowercase" }}>info@ro5.ai</h4>
              </a>
            </center>
            <div className="bg-molecule" />
          </div>
          <input
            value={name}
            className="formBox formLeft"
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Name*"
          />
          <input
            value={email}
            className="formBox formRight"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email*"
          />

          <div className="desktop" style={{ paddingTop: "2.7em" }} />
          <input
            value={company}
            className="formBox formLeft "
            onChange={(e) => setCompany(e.target.value)}
            type="text"
            placeholder="Company"
          />
          <div>
            <div
              style={{ paddingBottom: "2em", width: "100%" }}
              className="custom-select"
            >
              <select
                value={inquiryType}
                onChange={(e) => setInquiryType(e.target.value)}
                placeholder="Request"
              >
                {window.innerWidth > 530 ? (
                  <option value="">Select an Inquiry Type*</option>
                ) : (
                  <option value="">Inquiry Type*</option>
                )}
                <option value="Ro5 Platforms">Ro5 Platforms</option>
                <option value="Partnerships">Partnerships</option>
                <option value="Media">Media</option>
              </select>
            </div>
          </div>

          <div style={{ paddingTop: "3em" }} />
          <textarea
            value={message}
            className="formBox"
            onChange={(e) => setMessage(e.target.value)}
            style={{ width: "100%", height: "14em" }}
            placeholder="Message*"
          />
          <center
            style={{
              width: "20%",
              margin: "auto",
              minWidth: 100,
              overflow: "visible",
            }}
          >
            <p className="form-submit-button"><a href={`mailto:info@ro5.ai?subject=${inquiryType}&body=${message}`}>Submit</a></p>
          </center>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          Please enter in all required fields
        </Alert>
      </Snackbar>
    </PageTemplate>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default ContactUs;
