import React, { useEffect, useState } from "react"
import "styles/history.scss";
import PUBLICATION_DATA from "./PublicationData";
import PublicationWidget from "./PublicationWidget";
import PublicationModal from "./PublicationModal";
import { PageTemplate } from "../PageTemplate"

function PublicationPage() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPublication, setCurrentPublication] = useState("");

  useEffect(() => {document.title = "Publications"}, [])

  const closeModal = () => setModalOpen(false);

  const storyStyle = {
    width: "45%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: ".4em",
    fontFamily: "rubikRegular",
  };

  return (<PageTemplate>
    <center>
      <center
        className="historyHeader"
        style={{ position: "relative", marginBottom: "1.5em" }}
      >
        Ro5 Publications
        <div style={storyStyle}>
          Explore Ro5’s collection of science publications that unveil the transformative impact of our software solutions in drug discovery. Discover the insights, innovations, and discoveries at the intersection of groundbreaking research and cutting-edge software.
        </div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="widgetHolder">
        {PUBLICATION_DATA.map((singlePatent, id) => (
          <PublicationWidget
            key={id}
            name={singlePatent.name}
            noName={singlePatent.noName}
            number={singlePatent.number}
            description={singlePatent.description}
            picture={singlePatent.picture}
            date={singlePatent.date}
            setModalOpen={setModalOpen}
            setCurrentPatent={setCurrentPublication}
          />
        ))}
      </div>
      <PublicationModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        currentPublication={currentPublication}
      />
    </center>
  </PageTemplate>
  );
}

export default PublicationPage;
