import React, { useEffect } from "react";
import "styles/history.scss";
import sarahPicture from "../../../res/assets/Images/NewsArticles/Sarah_Flatters_photo_for_website.jpg";
import quote from "res/assets/Background/quote.svg";
import PlatformComponent from "Pages/platforms/PlatformComponent";
import { NEWS_TITLES } from "../NewsMasterPage/NewsPageHandler"

function NewMemberSarah() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const storyStyle = {
    width: "45%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: "67.5px",
    fontFamily: "rubikRegular",
  };
  return (
    <>
      <center
        className="historyHeader"
        style={{ position: "relative", marginBottom: "1.5em" }}
      >
        <div style={storyStyle}>Ro5 News</div>
        <div className="story">17.01.2022</div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="newMemberPage">
        <center>
          <img
            src={sarahPicture}
            className="newMemberMobilePagePhoto"
            alt="Sarah"
          />
        </center>
        <div className="newMemberPageTop">
          <div className="newMemberArticle">
            <PlatformComponent
              title={NEWS_TITLES.SARAH_FLATTERS_JOINS}
              desc="We are excited to announce that Sarah Flatters, Ph.D., is the
              Principal Translational Medicine Data Scientist at Ro5. Her education at University College London (UCL), and
                fellowships at McGill University and Harvard Medical School, led
                to a tenured position at King’s College London. Her biomedical
                research has led to 29 peer-reviewed articles and collaborations
                with pharma companies and the NHS. Analyzing complex patient data
                drew her to the advantages of data science techniques in
                understanding medical problems. In 2021, she left academia to
                fully focus on data science."
            />
          </div>
          <div>
            <img src={sarahPicture} className="newMemberPagePhoto" alt="Sarah" />
          </div>
        </div>
      </div>
      <div
        className="blueBg"
        style={{ display: "flex", position: "relative", marginTop: "8vh" }}
        id="Our Impact"
      >
        <div
          className="bg-quote"
          style={{ backgroundImage: `url(${quote})` }}
        />
        <div
          style={{
            width: "70vw",
            margin: "auto",
            paddingTop: "5em",
            paddingBottom: "5em",
          }}
        >
          <div style={{}}>
            <blockquote>
              <p
                className="quoteFont"
                style={{ color: "white", lineHeight: "1.2em" }}
              >
                “
                <b>
                  I’m excited to harness the power of AI to help revolutionize
                  drug discovery and clinical trial analytics at Ro5.
                </b>
                ”
              </p>

              <div style={{ display: "flex", alignItems: "center" }}>
                <cite>
                  <span className="bold">- Dr. Sarah Flatters</span> <br />
                </cite>
              </div>
            </blockquote>
          </div>
        </div>
        <div style={{ lex: 1 }}></div>
      </div>
    </>
  );
}

export default NewMemberSarah;
