import React, { useEffect } from "react"

import { Link, Typography } from "@mui/material"


export function NewsParagraph ({children}) {
    return <Typography variant="h6" sx={{fontSize: "1.2rem", lineHeight: "1.2em", mb: "1em"}}>{children}</Typography>
}

export function NewsLink ({href, children}) {
    return <Link href={href} target="_blank" rel="noreferrer" sx={{textTransform: "none", fontStyle: "italic"}}>
        <Typography component="span" sx={{fontStyle: "italic", fontSize: "1.2rem", lineHeight: "1.2em"}}>
            {children}
        </Typography>
    </Link>
}

function NewsSectionTemplate({title, dateStr, images, contentList}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = `News - ${title}`
    }, [title]);

    const storyStyle = {
        width: "45%",
        minWidth: 250,
        paddingTop: ".3em",
        margin: "auto",
        fontSize: "67.5px",
        fontFamily: "rubikRegular",
    };

    // TODO: make NewsSectionTemplate support multiple images
    let image = images[0].image
    let imageAlt = images[0].imageAlt

    return (
        <>
            <center
                className="historyHeader"
                style={{ position: "relative", marginBottom: "1.5em" }}
            >
                <div style={storyStyle}>Ro5 News</div>
                <div className="story">{dateStr}</div>
                <div className="bg-molecule" />
                <div className="bg-rounded-polygon" />
                <div className="bg-polygon-outline" />
            </center>

            <div style={{  paddingLeft: "5vw", paddingRight: "5vw"}}>
                <center className="row col-12 d-block d-lg-none">
                    <img
                        src={image}
                        style={{ width: "100%" }}
                        alt={imageAlt}
                    />
                </center>
                <div className="row" style={{ display: "flex", justifyContent: "space-between"}}>
                    <div className="col-12 col-lg-6">
                        <p className="primaryColor" style={{ fontSize: '42px' }}>
                            {title}
                        </p>
                        <div style={{ fontSize: '1.2rem', lineHeight: '1.2em' }}>{contentList.map((p, i) => {
                            return typeof p === "object" ? <div key={i} style={{marginBottom: "1em"}}>{p}</div> : <NewsParagraph key={i}>{p}</NewsParagraph>})}
                        </div>
                        <div style={{ paddingTop: '1.2em' }} />
                    </div>
                    <div className="col-6 d-none d-lg-block d-xl-block">
                        <img src={image} style={{width: "inherit", height: "maxContent"}} alt={imageAlt} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsSectionTemplate;