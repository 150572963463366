import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import "styles/history.scss"
import CovidNews from "../Covid19PaperPublished"
import NewMemberSarah from "../NewsArticles/NewMemberSarah"
import NewMemberBrock from "../NewsArticles/NewMemberBrock"
import Nelfinavir from "../NewsArticles/Nelfinavir"
import NewMemberGeneral from "../NewsArticles/NewMemberGeneral"
import teamData from "Pages/Team/teamData"
import newMemberHisham from "../../../res/assets/Team/HishamAbdelAty.png"
import newMemberPiero from "../../../res/assets/Team/piero.png"
import newMemberTanya from "../../../res/assets/Team/tanya_small.png"
import { titleToPath } from "../../../utils"
import { PageTemplate } from "../../PageTemplate"
import hitsDiscoveredComparisonImage from "../../../res/assets/Infographics/hits_discovered_comparison.png"
import labOfTheFutureImage from "../../../res/assets/Images/NewsArticles/Lab of the Future - Amsterdam 2023 - Amsterdam Photographer unmaskphotography-253.jpg"
import irak1HitsUmapImage from "../../../res/assets/Images/NewsArticles/IRAK1_hits_UMAP.png"
import hydrascreenPerformanceFullImage from "../../../res/assets/Images/NewsArticles/HydraScreen_performance.png"
import hydrascreenModelFullImage from "../../../res/assets/Images/NewsArticles/HydraScreen_model.png"
import spectraviewV1ReleaseImage from "../../../res/assets/Images/NewsArticles/spectraview_screenshot.png"
import { Link } from "@mui/material"
import NewsSectionTemplate, { NewsLink, NewsParagraph } from "./NewsTemplate"
import posterPdf from "res/assets/Infographics/ro5_strateos_poster_v4.pdf";
import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"


export const NEWS_TITLES = {
    AI_DRIVEN_HIGH_THROUGHPUT_SCREENING_A_NEW_PARADIGM_IN_DRUG_DISCOVERY: "AI-Driven High Throughput Screening: A New Paradigm in Drug Discovery",
    THE_LAB_OF_THE_FUTURE_CONFERENCE_2023: "The Lab of the Future Conference",
    ACCELERATED_HITID_WITH_TARGET_EVAL_DEEP_LEARNING_AND_AUTOMATED_LABS: "Accelerated Hit Identification with Target Evaluation, Deep Learning and Automated Labs: Prospective Validation in IRAK1",
    HYDRASCREEN_CASE_STUDY_IDENTIFICATION_OF_BET_AND_JAK1_INHIBITORS: "HydraScreen Case Studies: Identification of BET and JAK1 Inhibitors",
    COVID_19_PAPER_PUBLISHED_TITLE: "New COVID-19 research paper is published",
    NELFINAVIR_TITLE: "Ro5’s Identified Drug, Nelfinavir, is in Clinical Trials to Treat Covid-19",
    HYDRA_SCREEN_A_GENERALIZABLE_STRUCTURE_BASED_DEEP_LEARNING_APPROACH_TO_DRUG_DISCOVERY: "HydraScreen: A Generalizable Structure-Based Deep Learning Approach to Drug Discovery",
    HYDRA_SCREEN_APPLET_RELEASE: "HydraScreen web application release",
    SPECTRA_VIEW_V1_APP_RELEASE: "SpectraView v1 web application release",
    SARAH_FLATTERS_JOINS: "Dr. Sarah Flatters joins Ro5",
    BROCK_LUTY_JOINS: "Dr. Brock Luty joins Ro5",
    TANYA_PAQUET_JOINS: "Tanya Paquet, Ph.D joins Ro5",
    PIERO_GASPAROTTO_JOINS: "Piero Gasparotto, Ph.D joins Ro5",
    HISHAM_ABDEL_ATY_JOINS: "Hisham Abdel Aty, Ph.D joins Ro5",
}

if (Object.values(NEWS_TITLES).length !== new Set(Object.values(NEWS_TITLES)).size) {
    throw new Error("News articles need to have unique titles")
}

const NEWS_CONTENT = [
    {
        articleId: titleToPath(NEWS_TITLES.HYDRASCREEN_CASE_STUDY_IDENTIFICATION_OF_BET_AND_JAK1_INHIBITORS),
        title: NEWS_TITLES.HYDRASCREEN_CASE_STUDY_IDENTIFICATION_OF_BET_AND_JAK1_INHIBITORS,
        dateStr: "28.09.2023",
        images: [
            {image: irak1HitsUmapImage, imageAlt: "IRAK1 hits UMAP"},
        ],
        contentList: [
            <NewsParagraph style={{ fontStyle: "italic" }}>
                Deep dive in <NewsLink href="https://medium.com/@ro5.ai/hydrascreen-case-studies-potential-use-for-the-identification-of-bet-and-jak1-inhibitors-2bcf7cd9e017">
                Medium
            </NewsLink>
            </NewsParagraph>,
            "This Medium blog post discusses the use of Ro5's HydraScreen, an artificial intelligence (AI) tool, in the field of drug discovery for hit-to-lead compound optimization. Hit-to-lead optimization is the process of optimizing compounds for their activity against a specific target. HydraScreen predicts protein-ligand affinity from docked poses and can help identify promising compounds efficiently, saving time and resources.",
            <NewsParagraph>The blog presents two case studies:<br/><b>BET Inhibitor Case Study</b>: It analyzes the use of HydraScreen in identifying potential inhibitors for the bromodomain and extra-terminal (BET) protein. The results show a high correlation between HydraScreen predictions and experimentally measured values, suggesting its effectiveness in guiding compound selection.<br /><b>JAK1 Inhibitor Case Study</b>: Similarly, the blog explores the use of HydraScreen in identifying inhibitors for JAK1 kinase. The study finds a correlation between predicted affinities and measured activity, indicating its potential in predicting compound activity.</NewsParagraph>,
            "Additionally, HydraScreen provides pose confidence, indicating confidence in the predicted binding modes. The study highlights that HydraScreen could be a valuable tool in drug discovery, helping researchers select active compounds and offering insights into binding modes.",
            <NewsParagraph>Link: <NewsLink href="https://hydrascreen.ro5.ai?ref=ro5.ai">https://hydrascreen.ro5.ai</NewsLink></NewsParagraph>
        ]
    },
    {
        articleId: titleToPath(NEWS_TITLES.ACCELERATED_HITID_WITH_TARGET_EVAL_DEEP_LEARNING_AND_AUTOMATED_LABS),
        title: NEWS_TITLES.ACCELERATED_HITID_WITH_TARGET_EVAL_DEEP_LEARNING_AND_AUTOMATED_LABS,
        dateStr: "26.09.2023",
        images: [
            {image: irak1HitsUmapImage, imageAlt: "IRAK1 hits UMAP"},
        ],
        contentList: [
            <NewsParagraph style={{ fontStyle: "italic" }}>DOI: <Link
                href="https://doi.org/10.26434/chemrxiv-2023-mh22x-v4"
                target="_blank" rel="noreferrer">10.26434/chemrxiv-2023-mh22x-v4</Link></NewsParagraph>,
            "This preprint presents a study in which Ro5’s researchers combined the SpectraView tool for target evaluation and the HydraScreen virtual screening platform with Strateos' automated robotic cloud lab designed for high-speed screening. This integrated approach significantly sped up the processes of selecting the most promising target and identifying potential hits. By using SpectraView to choose IRAK1, we have validated the effectiveness of HydraScreen's deep learning model. We managed to identify nearly 24% of all IRAK1 hits in the top 1% of ranked compounds. HydraScreen also performed better than traditional virtual screening methods and provided confidence scores for the suggested drug poses. Moreover, the study identified three promising scaffolds, two of which are new candidates for IRAK1 and could be developed further.",
            <NewsParagraph>Links:<br /><List><ListItem><NewsLink href="https://hydrascreen.ro5.ai?ref=ro5.ai">https://hydrascreen.ro5.ai</NewsLink></ListItem></List></NewsParagraph>
        ]
    },
    {
        articleId: titleToPath(NEWS_TITLES.THE_LAB_OF_THE_FUTURE_CONFERENCE_2023),
        title: NEWS_TITLES.THE_LAB_OF_THE_FUTURE_CONFERENCE_2023,
        dateStr: "26.09.2023",
        images: [
            {image: labOfTheFutureImage, imageAlt: "Panel discussion at Lab Of The Future 2023"},
        ],
        contentList: [
            <NewsParagraph style={{ fontStyle: "italic" }}>See more at: <NewsLink
                href="https://www.lab-of-the-future.com/europe/">Lab Of The Future Conference 2023</NewsLink></NewsParagraph>,
            "The Lab of the Future conference is a seminal event where software vendors, lab automation providers, and pharma companies meet to discuss the future vision of lab technology and share their experiences in the digital transformation journey. This year, September 26-27, it was happening at the iconic Beurs van Berlage former stock exchange building in Amsterdam. At the conference, Ro5 had a booth in which it presented its platforms and a collaborative project with Strateos.",
            <NewsParagraph>In the Data Strategy and AI track, Ro5’s cofounder and VP of Data science Roy Tal, together with VP of Informatics Povilas Norvaisas, presented the collaboration between Ro5 and Strateos robotic cloud labs in a talk <b>"How AI can Impact Drug Discovery in Synergy with Automated Cloud Labs"</b>. In a joint project, the companies have explored the synergy of their platforms to augment target selection and hit identification in early drug discovery. Focusing on IRAK1 as a target selected using the SpectraView platform, Ro5 has shown how its HydraScreen virtual screening platform can identify up to 24% of all hit compounds in the top 1% of compound library used for high-throughput screening. These and other results from this study are available in a preprint <NewsLink href="https://chemrxiv.org/engage/chemrxiv/article-details/650d65e3b927619fe79c5c5c">"Accelerated Hit Identification with Target Evaluation, Deep Learning and Automated Labs: Prospective Validation in IRAK1"</NewsLink> and <NewsLink href={posterPdf}>a poster</NewsLink> of the same name.</NewsParagraph>,
            "On the last day of the conference, Ro5 confounder Roy Tal participated in a panel discussion, “Technology of the Future – What do today’s start-ups have to offer for the future”. He shared a vision of an integrated research environment without the traditional silos of biology, chemistry, and computer science.",
            "The Lab of the Future was an exciting event where industry leaders could share ideas and forge a vision for a more efficient and better-integrated drug discovery.",
            <NewsParagraph>Links:<br /><List><ListItem><NewsLink href="https://hydrascreen.ro5.ai?ref=ro5.ai">https://hydrascreen.ro5.ai</NewsLink></ListItem></List></NewsParagraph>,
            <div style={{ aspectRatio: 1.78, width: "100%" }}><iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/trerH-AvcUM?si=VGgZJnU9zlRVr373" title="How AI can Impact Drug Discovery in Synergy with Automated Cloud Labs Ro5" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
        ]
    },
    {
        articleId: titleToPath(NEWS_TITLES.AI_DRIVEN_HIGH_THROUGHPUT_SCREENING_A_NEW_PARADIGM_IN_DRUG_DISCOVERY),
        title: NEWS_TITLES.AI_DRIVEN_HIGH_THROUGHPUT_SCREENING_A_NEW_PARADIGM_IN_DRUG_DISCOVERY,
        dateStr: "01.09.2023",
        images: [
            {image: hitsDiscoveredComparisonImage, imageAlt: "HydraScreen hits discovered comparison"},
        ],
        contentList: [
            <NewsParagraph style={{ fontStyle: "italic" }}>Originally published in <NewsLink href="https://medium.com/@ro5.ai/ai-driven-high-throughput-screening-a-new-paradigm-in-drug-discovery-84314d36ed6">Medium</NewsLink></NewsParagraph>,
            "This blog post discusses the use of AI-driven High Throughput Screening (HTS) in early drug discovery, which has traditionally been a lengthy and costly process. HTS involves screening a large number of potential compounds, the majority of which are inactive, making the whole process inefficient. Ro5, in collaboration with Strateos, conducted a prospective experimental validation study of AI-driven HTS.",
            "Ro5's AI model, HydraScreen, uses deep learning to predict protein-ligand pose and binding affinity. It has been trained on a vast dataset of protein-ligand co-crystal structures and docked poses. Strateos provides automated, cloud-based labs for high-quality and reproducible data generation.",
            "In the study, Ro5 used HydraScreen to screen a library of chemical compounds and compared its performance to traditional methods like docking, QSAR, pharmacophore, and shape analysis. HydraScreen outperformed these methods by recovering a higher percentage of hit compounds in the top 1% of the library screened, resulting in significant cost savings and the identification of the most potent chemical classes.",
            "This study emphasizes the potential of AI to revolutionize drug discovery by making it more efficient and cost-effective. It also highlights HydraScreen's capacity to continuously improve and adapt to new data, unlike traditional methods."
        ]
    },
    {
        articleId: titleToPath(NEWS_TITLES.HYDRA_SCREEN_A_GENERALIZABLE_STRUCTURE_BASED_DEEP_LEARNING_APPROACH_TO_DRUG_DISCOVERY),
        title: NEWS_TITLES.HYDRA_SCREEN_A_GENERALIZABLE_STRUCTURE_BASED_DEEP_LEARNING_APPROACH_TO_DRUG_DISCOVERY,
        dateStr: "06.10.2023",
        images: [
            {image: hydrascreenPerformanceFullImage, imageAlt: "HydraScreen performance"},
        ],
        contentList: [
            <NewsParagraph style={{ fontStyle: "italic" }}>DOI: <NewsLink href="https://doi.org/10.26434/chemrxiv-2023-gwm1s-v2">10.26434/chemrxiv-2023-gwm1s-v2</NewsLink></NewsParagraph>,
            "This preprint introduces HydraScreen - an advanced tool for faster and more accurate drug discovery. It uses cutting-edge deep-learning technology to predict how drug molecules interact with proteins. The tool is designed for high-speed screening and optimization of potential drugs. In tests, it performed exceptionally well, predicting molecule binding affinity and pose with high accuracy. This study also introduced a new way to detect biases in the data used to train HydraScreen and identify its applicability domain. HydraScreen is versatile, working effectively with distinct protein families and diverse molecules. It is user-friendly and accessible online, making it a valuable tool for studying drug interactions.",
            <NewsParagraph>Link: <NewsLink href="https://hydrascreen.ro5.ai?ref=ro5.ai">https://hydrascreen.ro5.ai</NewsLink></NewsParagraph>
        ]
    },
    {
        articleId: titleToPath(NEWS_TITLES.HYDRA_SCREEN_APPLET_RELEASE),
        title: NEWS_TITLES.HYDRA_SCREEN_APPLET_RELEASE,
        dateStr: "06.10.2023",
        images: [
            {image: hydrascreenModelFullImage, imageAlt: "HydraScreen model"},
        ],
        contentList: [
            <NewsParagraph>Link: <NewsLink href="https://hydrascreen.ro5.ai?ref=ro5.ai">https://hydrascreen.ro5.ai</NewsLink></NewsParagraph>,
            <NewsParagraph>We are announcing the release of the HydraScreen web application in support of the preprint titled “HydraScreen: A Generalizable Structure-Based Deep Learning Approach to Drug Discovery” that is available on <NewsLink href="https://doi.org/10.26434/chemrxiv-2023-gwm1s-v2">ChemRxiv</NewsLink>.</NewsParagraph>,
            "HydraScreen is an AI tool that predicts protein-ligand affinity and pose score for a set of docked poses. The user submits their docked poses as a protein PDB file and ligand SDFs or PDBs of the docked poses. The model then ranks poses by confidence level and ligands by predicted affinity (e.g. pKi).",
            <NewsParagraph>Compared to traditional methods like docking, HydraScreen demonstrates increased accuracy through public benchmarks and prospective validation studies. More detailed information about the model and its performance is available in our <NewsLink href="https://doi.org/10.26434/chemrxiv-2023-gwm1s-v2">publication</NewsLink>. You can access RD-2020, the re-docked dataset used to train and evaluate HydraScreen, <NewsLink href="https://hydrascreen.ro5.ai/paper?ref=ro5.ai">here</NewsLink>.</NewsParagraph>
        ]
    },
    {
        articleId: titleToPath(NEWS_TITLES.SPECTRA_VIEW_V1_APP_RELEASE),
        title: NEWS_TITLES.SPECTRA_VIEW_V1_APP_RELEASE,
        dateStr: "10.10.2023",
        images: [
            {image: spectraviewV1ReleaseImage, imageAlt: "SpectraView V1 view"},
        ],
        contentList: [
            <NewsParagraph>We are announcing the release of the SpectraView v1 web application. It has been featured in a preprint “Accelerated Hit Identification with Target Evaluation, Deep Learning and Automated Labs: Prospective Validation in IRAK1” that is now available on <NewsLink href="https://doi.org/10.26434/chemrxiv-2023-mh22x-v4">ChemRxiv</NewsLink>.</NewsParagraph>,
            "SpectraView is an innovative tool that allows the researchers to collect insights about gene/protein targets. SpectraView relies on biomedical literature, patents and chemical databases to provide summaries that can be used both in basic research and drug discovery. It is built by scientists for scientists to help answer questions like: Which diseases are discussed in scientific publications and patents in the context of a particular gene/protein? What about biological mechanisms? Who filed those patent applications? When? In what countries? Who owns those patents?",
        ]
    },
]

function NewsPageHandler () {
  const navigate = useNavigate()
  const { articleId } = useParams()

  useEffect(() => {
      if (!Object.values(NEWS_TITLES).map(title => titleToPath(title)).includes(articleId)) {
          navigate("/news")
      }
  }, [articleId, navigate])

  return (
    <PageTemplate>
      {NEWS_CONTENT
          .filter(props => props.articleId === articleId)
          .map(props => <NewsSectionTemplate key={props.articleId} {...props} />)}
      {articleId === titleToPath(NEWS_TITLES.COVID_19_PAPER_PUBLISHED_TITLE) && <CovidNews />}
      {articleId === titleToPath(NEWS_TITLES.SARAH_FLATTERS_JOINS) && <NewMemberSarah />}
      {articleId === titleToPath(NEWS_TITLES.BROCK_LUTY_JOINS) && <NewMemberBrock />}
      {articleId === titleToPath(NEWS_TITLES.NELFINAVIR_TITLE) && <Nelfinavir />}
      {articleId === titleToPath(NEWS_TITLES.TANYA_PAQUET_JOINS) && <NewMemberGeneral name="Tanya Paquet" date="22.07.2022" picture={newMemberTanya} desc={teamData.find(member => member.name === "Tanya Paquet, Ph.D.")?.content} />}
      {articleId === titleToPath(NEWS_TITLES.PIERO_GASPAROTTO_JOINS) && <NewMemberGeneral name="Piero Gasparotto" date="01.03.2022" picture={newMemberPiero} desc={teamData.find(member => member.name === "Piero Gasparotto, Ph.D.")?.content} />}
      {articleId === titleToPath(NEWS_TITLES.HISHAM_ABDEL_ATY_JOINS) && <NewMemberGeneral name="Hisham Abdel Aty" date="01.06.2023" picture={newMemberHisham} desc={teamData.find(member => member.name === "Hisham Abdel Aty")?.content} />}
    </PageTemplate>
  )
}

// date, picture, pictureAlt, name, desc
export default NewsPageHandler
