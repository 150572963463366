import React from "react";
import calendarIcon from "../../../res/assets/Icons/Utility/calendar-blue.png";
import "styles/history.scss";

function NewsWidget(props) {
  const { imgSrc, title, shortDescription, date, unstreched = false, wide = false } = props;
  return (
    <div className="newsWidget">
      <img src={imgSrc} className={wide ? "newsWidgetPhotoWide" : unstreched ? "newsWidgetPhotoUnstretched" : "newsWidgetPhoto"} alt="News" />
      <div
        style={{ fontWeight: "bolder", fontSize: "1.9rem", marginTop: "2vh" }}
      >
        {title}
      </div>
      <p style={{ textAlign: "left", marginTop: "0.5vh" }}>{shortDescription}</p>
      <div className="newsWidgetDate">
        <hr />
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <img src={calendarIcon} className="calendarIcon" alt="Calendar Icon" />
          <div style={{ marginLeft: "0.5vw", fontSize: "1.2rem" }}>{date}</div>
        </div>
      </div>
    </div>
  );
}

export default NewsWidget;
