import NavBarWithLogo from "../components/navigation/NavigationComponent"
import React, { Suspense } from "react"
import Footer from "../components/Footer"
import Loading from "../components/Loading"

export function PageTemplate({children}) {
  return <div style={{ overflow: 'hidden' }}>
    <NavBarWithLogo />
    <Suspense fallback={<Loading />}>
      {children}
    </Suspense>
    <Footer />
  </div>
}