import React, { useEffect } from "react";
import "styles/history.scss";
import PlatformComponent from "Pages/platforms/PlatformComponent";
import molecule from "res/assets/Images/NewsArticles/protein_small.png";
import { CASE_STUDIES_DATA } from "../CaseStudiesData";

function Ro5WeillCornell() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Case Studies - ${CASE_STUDIES_DATA.RO5_WEILL_CORNELL_NEWS.title}`
  }, []);

  const storyStyle = {
    width: "45%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: "67.5px",
    fontFamily: "rubikRegular",
  };
  return (
    <>
      <center
        className="historyHeader"
        style={{ position: "relative", marginBottom: "1.5em" }}
      >
        <div style={storyStyle}>Ro5 Case Studies</div>
        <div className="story">13.04.2022</div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="newMemberPage">
        <center>
          <img
            src={molecule}
            className="newMemberMobilePagePhoto"
            alt="Brock"
          />
        </center>
        <div className="newMemberPageTop">
          <div className="newMemberArticle">
            <PlatformComponent
              title={CASE_STUDIES_DATA.RO5_WEILL_CORNELL_NEWS.title}
              desc={
                <>
                  Weill Cornell has identified a new inhibitor, PK-188,
                  targeting New Delhi Metallo-β Lactamases (NDMs). NDMs, and
                  NDM-1 in particular, are emerging lactamases of concern, as
                  they confer resistance to almost all existing β-lactam
                  antibiotics.
                  <br />
                  <br />
                  Ro5 has provided the AI and computational support in this
                  project to better understand the underlying mechanism of
                  action of this novel inhibitor, including the binding site,
                  pharmacodynamics, binding mode, and protein-ligand
                  interactions, as well as its pharmacokinetic profile (i.e.
                  ADMET and off-target effects).
                  <br />
                  <br />
                  PK-188 has been validated{" "}
                  <span style={{ fontStyle: "italic" }}>in vitro</span>,
                  restoring susceptibility to the β-lactam antibiotic meropenem
                  in previously resistant NDM-1-expressing bacteria. Excitingly,
                  PK-188 also showed activity against NDM-5, 6 and 7. We are
                  continuing to further elucidate the MoA of the candidate and
                  subsequently further optimize it for potency and favorable
                  chemical and pharmacological properties.
                  <br />
                  <br />
                  The abstract describing the results of this collaboration has
                  been accepted and will be presented at the 32nd European
                  Congress of Clinical Microbiology and Infectious Diseases
                  (ECCMID) on the 23rd of April 2022 in Lisbon, Portugal.
                </>
              }
            />
          </div>
          <div>
            <img src={molecule} className="newMemberPagePhoto" alt="molecule" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Ro5WeillCornell;
