/**
 * Parent component for the My Team page.
 *
 * @author Amit Setty
 */

import React, { useState, useEffect } from "react";
import TeamMemberCard from "./TeamMemberCard";
import teamData from "./teamData";
import MyTeamModal from "./MyTeamModal";

import "styles/team.scss";
import { PageTemplate } from "../PageTemplate"

function MeetTheTeam() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPerson, setCurrentPerson] = useState("");

  useEffect(() => {document.title = "Team"}, [])

  const closeModal = () => setModalOpen(false);

  useEffect(function () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <PageTemplate>
    <div className="meet-team-container">
      <div className="meet-team=scroll">
        <div className="foreground">
          <center className="meet-team-header">
            The Ro5 Team
            <div
              className="pageHeaderText"
              style={{
                paddingTop: ".3em",
                margin: "auto",
                fontSize: ".4em",
                fontFamily: "rubikRegular",
              }}
            >
              Complex original thinking requires diverse cultures. With its
              alliance of domain experts, Ro5 lifts the art and science of
              drug discovery with research grounded in human values.
            </div>
            <div className="bg-polygon-outline" />
            <div className="bg-molecule" />
            <div className="bg-rounded-polygon" />
          </center>
          {/* Leadership */}

          <>
            {/* <center className="primaryColor" style={{ fontSize: '2.2em' }}>Cofounders and Leadership</center> */}

            <center
              style={{ flex: 8 }}
              className=" meetTeamGridLeadership smallTeamGrid responsiveGridTeam responsiveGridPrincipalScientists"
            >
              {teamData.map((val, id) => (
                <center key={id}>
                  <TeamMemberCard
                    setCurrentPerson={setCurrentPerson}
                    isModalOpen={isModalOpen}
                    setModalOpen={setModalOpen}
                    {...val}
                  />
                </center>
              ))}
            </center>
          </>
          {/* Scientific Staff */}

          {/* <>
                        <center className="primaryColor" style={{ fontSize: '2.2em', paddingTop: '1em' }}>
                            Principal Scientists
                        </center>
                        <center>

                            <div className="meetTeamGridLeadership smallTeamGrid responsiveGridTeam responsiveGridPrincipalScientists centerLast">
                                {teamData.filter(val => (/scientific/i).test(val.role)).map(val =>
                                    <div>
                                        <div style={{ width: '100%' }}>
                                            <TeamMemberCard setCurrentPerson={setCurrentPerson} isModalOpen={isModalOpen} setModalOpen={setModalOpen} {...val} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </center>
                    </> */}
          {/* Academic Staff */}

          {/* <>
                        <center className="primaryColor" style={{ fontSize: '2.2em', paddingTop: '1em' }}>
                            Scientific Staff
                        </center>
                        <center>
                            <div className="meetTeamGridLeadership smallTeamGrid responsiveGridTeam responsiveGridPrincipalScientists centerLast">
                                {teamData.filter(val => (/academic/i).test(val.role)).map(val =>
                                    <div>
                                        <div>
                                            <TeamMemberCard setCurrentPerson={setCurrentPerson} isModalOpen={isModalOpen} setModalOpen={setModalOpen} {...val} />
                                        </div>
                                    </div>
                                )}

                            </div>
                        </center>
                    </> */}
          {/* DevOps */}

          {/* <>
                        <center className="primaryColor" style={{ fontSize: '2.2em', paddingTop: '1em' }}>
                            Software Developers
                        </center>
                        <center>
                            <div className="meetTeamGridLeadership smallTeamGrid responsiveGridTeam responsiveGridPrincipalScientists centerLast">
                                {teamData.filter(val => (/devOps/i).test(val.role)).map(val =>
                                    <div>
                                        <div>
                                            <TeamMemberCard setCurrentPerson={setCurrentPerson} isModalOpen={isModalOpen} setModalOpen={setModalOpen} {...val} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </center>
                    </> */}
          {/* Public Relations */}

          {/* <>
                        <center className="primaryColor" style={{ fontSize: '2.2em', paddingTop: '1em' }}>
                            Public Relations
                        </center>
                        <center>
                            <div className="responsiveGridTeam meetTeamGridLeadership smallTeamGrid centerOne">
                                <div>

                                </div>

                                {teamData.filter(val => (/Public Relations/i).test(val.role)).map(val =>
                                    <div>
                                        <div className="teamMemberRow">
                                            <TeamMemberCard setCurrentPerson={setCurrentPerson} isModalOpen={isModalOpen} setModalOpen={setModalOpen} {...val} />
                                        </div>
                                    </div>
                                )}
                                <div>

                                </div>
                            </div>
                        </center>
                    </> */}
        </div>
      </div>
      <MyTeamModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        currentPerson={currentPerson}
      />
    </div>
    </PageTemplate>
  );
}

export default MeetTheTeam;
