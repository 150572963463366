import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";

import Logo from "logo.svg";
import "styles/footer.scss";
import DropdownArrow from "res/assets/Icons/DropdownArrow.svg";

function Footer() {
  const location = useLocation();
  const noMargin =
    location.pathname.includes("/news/2") ||
    location.pathname.includes("/news/3") ||
    location.pathname.includes("/services") ||
    location.pathname === "/";

  const [showAbout, setShowAbout] = useState(false);
  const [showAiPlatforms, setShowAiPlatforms] = useState(false);
  const [showServices, setShowServices] = useState(false);

  const LeftColumn = () => (
    <div className="footerLeft">
      <div style={{ display: "flex" }}>
        <img src={Logo} alt="Ro5" className="ro5LogoFooter" />
        <sup style={{ color: "white", fontSize: "1.5rem" }}>®</sup>
      </div>
      <p className="footerPhrase">
        Because
        <span className="bold"> disease doesn't wait</span>
        <sup style={{ fontSize: "1.2rem", marginLeft: "0.2vw", top: "-1rem" }}>
          ®
        </sup>
      </p>
      <div className="mobileFooterAddress">
        <RightColumn isMobile={true} />
      </div>
      <p className="copyright">&copy; Ro5 2017-2023</p>
    </div>
  );

  const MiddleColumn = () => {
    const navigate = useNavigate();

    const toPage = (page, part = false) => {
      window.scrollTo(0, 0);
      part === false
        ? navigate(`/${page}`)
        : navigate(`/${page}`, part);
    };

    return (
      <div className="desktop footerLinks footerMid">
        <div onMouseLeave={() => setShowAbout(false)}>
          <span
            className="footerLinkNoPointer"
            onMouseEnter={() => setShowAbout(true)}
            style={{ cursor: "default", fontSize: "20px" }}
          >
            About
            <img
              className={showAbout ? "caretFooterTransform" : "caretFooter"}
              src={DropdownArrow}
              alt=""
            />
          </span>
          {showAbout ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                className="footerLink indent"
                onClick={() => toPage("the-ro5-team")}
              >
                The Team
              </span>
              <span
                className="footerLink indent"
                onClick={() => toPage("the-ro5-advisory-board")}
              >
                Advisory Board
              </span>
              <span
                className="footerLink indent"
                onClick={() => toPage("history")}
              >
                History
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div onMouseLeave={() => setShowAiPlatforms(false)}>
          <span
            className="footerLinkNoPointer"
            onMouseEnter={() => setShowAiPlatforms(true)}
            style={{ cursor: "default", fontSize: "20px" }}
          >
            AI Platforms{" "}
            <img
              className={
                showAiPlatforms ? "caretFooterTransform" : "caretFooter"
              }
              src={DropdownArrow}
              alt=""
            />
          </span>
          {showAiPlatforms ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                className="footerLink indent"
                onClick={() => toPage("de-novo-drug-discovery")}
              >
                De Novo Drug Discovery
              </span>
              <span
                className="footerLink indent"
                onClick={() => toPage("bioactivity")}
              >
                Bioactivity
              </span>
              <span
                className="footerLink indent"
                onClick={() => toPage("ADMET")}
              >
                ADMET
              </span>
              <span
                className="footerLink indent"
                onClick={() => toPage("knowledge-graph")}
              >
                Knowledge Graph
              </span>
              {/* <span
                className="footerLink indent"
                onClick={() => toPage("clinical-trial-analytics")}
              >
                Clinical Trial Analytics
              </span> */}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div onMouseLeave={() => setShowServices(false)}>
          <span
            className="footerLinkDropdownUndr"
            onMouseEnter={() => setShowServices(true)}
            onClick={() => toPage("services")}
            style={{ fontSize: "20px" }}
          >
            Services{" "}
            <img
              className={
                showAiPlatforms ? "caretFooterTransform" : "caretFooter"
              }
              src={DropdownArrow}
              alt=""
            />
          </span>
          {showServices ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                className="footerLink indent"
                onClick={() => toPage("services", "entry")}
              >
                Entry Programs
              </span>
              <span
                className="footerLink indent"
                onClick={() => toPage("services", "client")}
              >
                Client Programs
              </span>
              
              <span
                className="footerLink indent"
                onClick={() => toPage("services", "discovery")}
              >
                Drug Discovery Programs
              </span>
              {/* <span
                className="footerLink indent"
                onClick={() => toPage("services", "ctp")}
              >
                Clinical Trial Programs
              </span> */}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* <span className="footerLinkUndr" onClick={() => toPage("services")}>
        Services
        </span> */}
        <span className="footerLinkUndr" onClick={() => toPage("patent-page")}>
          Patents
        </span>
        <span className="footerLinkUndr" onClick={() => toPage("news")}>
          News
        </span>

        <span className="footerLinkUndr" onClick={() => toPage("join-us")}>
          Join Us
        </span>
        <span className="footerLinkUndr" onClick={() => toPage("contact-us")}>
          Contact Us
        </span>
      </div>
    );
  };

  const RightColumn = (props) => (
    <div className={props.isMobile ? "footerRightMobile" : "footerRight"}>
      {/* <span className="officeHeader">London Office</span>
      <span>East Side, Platform 1</span>
      <span>King's Cross Station Euston Road</span>
      <span>London N1C 4AX</span>
      <br /> */}
      <span className="officeHeader">Dallas Office</span>
      <span>2801 Gateway Drive</span>
      <span>Irving, Texas 75063</span>
    </div>
  );

  // style={ noMargin ? {marginTop: "0"} : {}} is doubled below, because desktop version uses 'footer-top' css and mobile uses 'responsiveGrid' css

  return (
    <div className="footer-top" style={noMargin ? { marginTop: "0" } : {}}>
      <div
        className="footer responsiveGrid"
        style={noMargin ? { marginTop: "0" } : {}}
      >
        <div className="footer-container">
          <LeftColumn />
          <div className="desktopFooterOptions">
            <div style={{ width: "5vw" }} />
            <MiddleColumn />
            <div style={{ width: "15vw" }} />
            <RightColumn isMobile={false} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
