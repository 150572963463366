/**
 * Part of the landing page -- displays information about what Ro5 does
 * @author Amit Setty
 */
import React from "react";
import patentIcon from "res/assets/Icons/certification-award-blue.png";
import calendarIcon from "res/assets/Icons/Utility/calendar-blue.png";

function PatentWidget(props) {
  const {
    name,
    number,
    description,
    picture,
    date,
    setModalOpen,
    setCurrentPatent,
    noName,
  } = props;

  function handlePatentClick() {
    setCurrentPatent({
      name,
      number,
      description,
      picture,
      date,
      noName,
    });
    setModalOpen(true);
  }

  return (
    <div className="patentWidget" onClick={() => handlePatentClick()}>
      <img src={picture} alt="Patent" className="patentPicture" />
      <div
        style={{ fontSize: "1.7rem", fontWwight: "500", marginBottom: "3vh" }}
      >
        {name}
      </div>
      <div style={{ width: "95%", position: "absolute", bottom: 0 }}>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "0.8vh",
          }}
        >
          {number !== null ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://patents.google.com/patent/${number}/en`}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={patentIcon}
                  className="calendarIcon"
                  alt="Patent Icon"
                />
                <div style={{ marginLeft: "0.5vw", fontSize: "1.2rem" }}>
                  {number}
                </div>
              </div>
            </a>
          ) : (
            <div style={{ display: "flex" }}>
              <img
                src={patentIcon}
                className="calendarIcon"
                alt="Patent Icon"
              />
              <div style={{ marginLeft: "0.5vw", fontSize: "1.2rem" }}>
                Allowed
              </div>
            </div>
          )}
          {number !== null ? (
            <div style={{ display: "flex" }}>
              <img
                src={calendarIcon}
                className="calendarIcon"
                alt="Calendar Icon"
              />
              <div style={{ marginLeft: "0.5vw", fontSize: "1.2rem" }}>
                {date}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatentWidget;
