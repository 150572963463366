/**
 * Component for the De Novo Ro5 platforms.
 *  Contains a header and body using the plattform components.
 * @author Amit Setty
 */
import React, { useEffect } from "react"
import deNovoInfographic from "res/assets/Infographics/DeNovo.svg"
import PlatformComponent from "./PlatformComponent"
import PlatformHeader from "./PlatformHeader"
import ServicesWidget from "Pages/Services/ServicesWidget"

import DeNovoDataVis from 'res/assets/Images/DeNovo/denovo_1725x1100.png';
import macbookAir from "res/assets/Images/Macbook/Macbook_Air.png";
import { useNavigate } from "react-router"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PageTemplate } from "../PageTemplate"

const whyChooseUs = ["Ro5’s methodology achieves unprecedented >90% molecule reconstruction accuracy, 2-20x greater than competitors.",
  "The retrosynthesis model provides the correct compound deconstruction path in the top 1% of suggestions 50% of the time."
]
const benefits = ["Discover and design effective novel compounds based on their criteria.",
  "Directly optimize and generate 3D molecular structures, either in the presence or absence of a reliable protein structure.",
  "Provide synthetic routes for promising compounds.",
  "Prioritize molecules based on their IP potential.",
  "Significantly cut the time and costs of drug discovery programs.",
  "State-of-the-art generative models.",
  "Initiate compound generation with no prior information or using the scaffold you trust."
]


function DeNovo() {
  const navigate = useNavigate()

  useEffect(() => {document.title = "De Novo Platform"}, [])

  const settings = {
    dots: true,
    appendDots: dots => (<div id="macbookSlickDots">{dots}</div>),
    // nextArrow: <NextArrow />,
    // previousArrow: <PreviousArrow />,
    lazyLoad: false,

    afterChange: function (index) {
    }
  };

  const settingsMobile = {
    dots: true,
    appendDots: dots => (<div>{dots}</div>),
    arrows: false,
    lazyLoad: false,

    afterChange: function (index) {
    }
  };

  return (
    <PageTemplate>
      <PlatformHeader
        infographic={deNovoInfographic}
        title="De Novo Platform"
        desc="Ro5’s De Novo Platform leverages AI and chemistry to rapidly generate novel and potent molecules to modulate your target, cutting the time and cost of your drug discovery programs.
        The platform filters out undesirable molecules, provides synthetic routes for the most promising candidates, and automatically evaluates patentability."
      />

      {/* Page Content */}
      <div className="desktopFlex">
        <div style={{ flex: 1 }} />
        <div style={{ flex: 2.5 }} >

          <PlatformComponent
            title="Ligand-Based Generation"
            desc="When the protein target is unknown or a reliable structure is unavailable, we leverage assay data to perfect our models. You can generate molecules to modulate your target of interest even without a known target structure if there are a handful of molecules with assay data.
            Our state-of-the-art models leverage graph, 3D structure, and pharmacophore representations of molecules to efficiently search the chemical space for new compounds.
            The top molecules then are optimized according to your criteria, such as synthetic accessibility, LogP, or quantitative structure-activity relationships extracted from public or proprietary datasets."
          />
          <PlatformComponent
            title="Structure-based generation"
            desc="Our pocket-based de novo pipeline is based on a set of models trained on binding interactions of millions of protein-ligand complexes.
            The platform allows you to directly generate molecular structures designed to bind to a given binding site on your target of interest.
            The molecular structures are optimized to maximize favourable interactions and possess desirable chemical properties."
          />
          <PlatformComponent
            title="Retrosynthesis"
            desc="By implicitly learning the rules of organic chemical synthesis, our efficient deep learning-based search enables complex molecular structures to be broken down and simplified until commercially available precursors are identified.
            With this, we generate optimized synthetic routes for a set of compounds, effectively guiding chemists during the synthesis of novel compounds."
          />
          <PlatformComponent
            title="Patentability Analysis and Precursor Screening"
            desc="The final stage is to evaluate the patentability and purchasability of the most promising candidate molecules to help inform business decisions.
              Our process can match molecular scaffolds to those found in existing patents to help you avoid infringement of patented chemical space while increasing the chances of discovering newly patentable chemical space.
              Our purchasability pipeline screens retrosynthetic precursors against multiple libraries of purchasable translating to less time in the lab wasted on precursor synthesis"
          />



        </div>
        <div style={{ flex: .5 }} />
        <div style={{ flex: 1.9 }} >
          {/* <BenefitsDialogBox benefits={benefits} /> */}
          <ServicesWidget
            listName={"Why choose us?"}
            isWhite={false}
            list={whyChooseUs}
          />
          <ServicesWidget
            listName={"Benefits At a Glance"}
            isWhite={false}
            list={benefits}
          />

          <div style={{ paddingTop: '2em' }} />
        </div>
        <div style={{ flex: .7 }} />
      </div>
      <center>
        <button onClick={() => navigate("/contact-us")} className="form-submit-button btn btn-primary">
          Contact us
        </button>
      </center>

      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <center className="desktopKnowledgeGraphPictures" style={{ position: 'relative', width: '50%', height: '50%' }}>
          <img className="macbookAir" alt={"MacbookAir"} src={macbookAir} />

          <div style={{ position: 'absolute', zIndex: 5, width: '100%', height: '100%', top: '9.7%', left: '13%' }}>

            <Slider {...settings}>
              {[1].map(id =>
                // id === 1 ?
                <img key={id} style={{ zIndex: 2, }} src={DeNovoDataVis} alt="De Novo" />
                // : id === 2 ?
                //   <img style={{ zIndex: 2, }} src={DeNovoPredictions} alt="De Novo" /> :
                //   <img style={{ zIndex: 2, }} src={DeNovoMoleculeDrawer} alt="De Novo" />
              )}
            </Slider>
          </div>
        </center>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <center className="mobileKGSlider" style={{ width: '80%', height: '80%' }}>
          <Slider {...settingsMobile}>
            {[1].map(id =>
              <img key={id} style={{ zIndex: 2, }} src={DeNovoDataVis} alt="De Novo" />
            )}
          </Slider>
        </center>
      </div>
    </PageTemplate>
  )

}


export default DeNovo