import React, { useEffect, useState } from "react";
import advisoryData from "./AdvisoryBoardData";
import TeamMemberCard from "../Team/TeamMemberCard";
import MyTeamModal from "../Team/MyTeamModal";

import "styles/team.scss";
import { PageTemplate } from "../PageTemplate"

function AdvisoryBoard() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPerson, setCurrentPerson] = useState("");

  const closeModal = () => setModalOpen(false);

  useEffect(function () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
    document.title = "Advisory Board"
  }, []);

  return (
    <PageTemplate>
    <div className="meet-team-container">
      <div className="meet-team=scroll">
        <div className="foreground">
          <center className="meet-team-header">
            Advisory Board
            <div className="bg-polygon-outline" />
            <div className="bg-molecule" />
            <div className="bg-rounded-polygon" />
          </center>
          <div className="advisoryBoardWrapper">
            <center>
                {/* in css file team.css uncomment centerLast if there are odd nuber of members in Advisory board */}
              <div className="responsiveGridTeam meetTeamGridLeadership smallTeamGrid centerLast">
                {advisoryData.map((val, index) => (
                  <div key={index}>
                    <div className="teamMemberRow">
                      <TeamMemberCard
                        setCurrentPerson={setCurrentPerson}
                        isModalOpen={isModalOpen}
                        setModalOpen={setModalOpen}
                        advisoryBoard={true}
                        {...val}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </center>
          </div>
        </div>
        <MyTeamModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          currentPerson={currentPerson}
          advisoryBoardMember={true}
        />
      </div>
    </div>
    </PageTemplate>
  );
}

export default AdvisoryBoard;
