import React, { useEffect } from "react"
import "styles/history.scss"
import PlatformComponent from "Pages/platforms/PlatformComponent"

function NewMemberGeneral(props) {
  const {date, picture, name, desc} = props
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `News - New Joiner ${name}`
  }, [name]);

  const storyStyle = {
    width: "45%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: "67.5px",
    fontFamily: "rubikRegular",
  };
  return (
    <>
      <center
        className="historyHeader"
        style={{ position: "relative", marginBottom: "1.5em" }}
      >
        <div style={storyStyle}>Ro5 News</div>
        <div className="story">{date}</div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="newMemberPage">
        <center>
          <img
            src={picture}
            className="newMemberMobilePagePhoto"
            alt={name}
          />
        </center>
        <div className="newMemberPageTop">
          <div className="newMemberArticle">
            <PlatformComponent
              title={name+" joins Ro5"}
              desc={desc}
            />
          </div>
          <div>
            <img src={picture} className="newMemberPagePhoto" alt="Profile" />
          </div>
        </div>
      </div>
     
    </>
  );
}

export default NewMemberGeneral;
