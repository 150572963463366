import React from "react";
import { Modal } from "react-bootstrap";
import patentIcon from "res/assets/Icons/certification-award-blue.png";
import calendarIcon from "res/assets/Icons/Utility/calendar-blue.png";

function PublicationModal(props) {
  const { isModalOpen, closeModal, currentPublication } = props;
  return (
    <Modal size="lg" show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "0.8vh",
            }}
          >
            {currentPublication.number !== null ? (
                <div style={{ display: "flex" }}>
                  <img
                    src={patentIcon}
                    className="calendarIcon"
                    alt="Patent Icon"
                  />
                  <div style={{ marginLeft: "0.5vw", fontSize: "1.2rem" }}>
                    {currentPublication.number}
                  </div>
                </div>
            ) : (
              <div style={{ display: "flex" }}>
                <img
                  src={patentIcon}
                  className="calendarIcon"
                  alt="Patent Icon"
                />
                <div style={{ marginLeft: "0.5vw", fontSize: "1.2rem" }}>
                  Allowed
                </div>
              </div>
            )}
            {currentPublication.number !== null ? (
              <div style={{ display: "flex" }}>
                <img
                  src={calendarIcon}
                  className="calendarIcon"
                  alt="Calendar Icon"
                />
                <div style={{ marginLeft: "0.5vw", fontSize: "1.2rem" }}>
                  {currentPublication.date}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ paddingLeft: "1em", paddingBottom: "3vh" }}>
          <center>
            <img
              style={{ maxWidth: "80%" }}
              src={currentPublication.picture}
              alt="Patents"
            />
            <br />
            <br />
            {currentPublication.noName ? (
              <></>
            ) : (
              <b style={{ fontSize: "1.65rem" }}>{currentPublication.name}</b>
            )}
          </center>
          <div style={{ paddingTop: "1.2em" }} />
          <span style={{ textAlign: "left", fontSize: "1.5rem" }}>
            {currentPublication.description}
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default PublicationModal;
