import proteinIconThumbnail from "res/assets/Images/NewsArticles/protein_with_icon_thumbnail.png";
import teamUp from "res/assets/Images/NewsArticles/Ro5StrateosTeam_BIG_thumbnail.png";

export const CASE_STUDIES_DATA = {
  RO5_WEILL_CORNELL_NEWS: {
    title:
      "Weill Cornell and Ro5 identify a Novel Allosteric Inhibitor Targeting New Delhi Metallo-β Lactamases",
    shortDescription:
      "Ro5 is in an ongoing collaboration with Weill Cornell Medicine to develop next-generation antibiotics for multi-drug resistant pathogens.",
    date: "13.04.2022",
    thumbnail: proteinIconThumbnail,
  },
  RO5_STRATEOS_TEAM_UP: {
    title: "Ro5 and Strateos Team Up to Accelerate Automated Drug Discovery",
    shortDescription:
      "With faster hit identification and better leads, this collaboration promises to move the fields of AI-mediated and automated drug discovery forward.",
    date: "01.09.2022",
    thumbnail: teamUp,
  },
};

