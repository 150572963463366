/**
 * Part of the landing page -- displays information about what Ro5 does
 * @author Amit Setty
 */
import React, { useEffect } from "react";

import moleculeBG from "res/assets/Background/Artboard – 2.svg";

function DoPrinciple(props) {
  const { name, offsetDesktop, bullets } = props
  const offset = window.innerWidth > 500 ? offsetDesktop : 0
  return (
    <div style={{ marginTop: offset }}>
      <div className="categoryTitle">
        {name}
      </div>
      <ul style={{ marginLeft: 0, listStyle:'none' }}>
        {bullets.map((bullet, index) => <li key={index}>
          {bullet} 
          <br/><br/>
        
        </li>
        
        )}
      </ul>
    </div>
  )
}
function DoPrinciples() {
  return <div style={{ paddingBottom: 25 }}>
    <div
      className="responsiveGridFulLWidth responsiveGrid2 howItWorksFeatures"
      style={{ position: "relative", paddingBottom: 0 }}
    >
      <DoPrinciple name="Discover"
        bullets={
          [<>We discover the <b >unsolved challenges</b> in drug discovery by working closely with our biopharma clients.</>,
          <>We discover <b >novel digital solutions</b> to satisfy the nuanced needs at every stage of drug discovery.</>,
          <>We <b >expedite the drug discovery process</b> through our software platforms and enterprise services</>
          ]} />
      <DoPrinciple name="Develop"
        bullets={
          [<>We develop <b>software platforms</b> that convert manual and tedious research workflows into digital, modular, and scalable software solutions.</>,
          <>We develop <b>unique state-of-the-art AI models</b> that can be licensed in their own right to benefit drug discovery research everywhere.</>,
          <>We develop close <b>long-term relationships with our clients</b> to provide the most valuable solutions for their needs.</>
          ]}
        offsetDesktop={150} />
      <DoPrinciple name="Deliver"
        bullets={[<>We deliver <b>state-of-the-art patented software platforms and AI models</b> to satisfy unmet needs across the biopharma industry.</>,
        <>We deliver <b>long-term support</b> with deployment of our software and AI models, making improvements as needed.</>]}
        offsetDesktop={-50} />

    </div>
  </div>
}
function AboutRo5() {
  useEffect(() => {
    // Pre-load background img
    new Image().src = moleculeBG;
  }, []);

  return (
    <>
      <div>
        <div
          className="desktopFlex"
          style={{ position: "relative", paddingBottom: 0 }}
        >
          <div className="bg-molecule-1">
            <img src={moleculeBG} alt="" />
          </div>
          <div style={{ flex: 1 }} />

          <div className="whatWeDoFlex">
            <div />
            <div className="categoryHeader">WHAT WE DO</div>
            <div className="categoryTitle" style={{ textAlign: "left" }}>
              Machine learning and data science revolutionize drug discovery.
            </div>
            <br />
            <p>
              We build the tools to make that promise a reality for our clients.
            </p>
            <br />


            <DoPrinciples />

          </div>



          <div style={{ flex: 1 }} />



          <div style={{ display: "inline-block" }}></div>
        </div>
        <div style={{ marginLeft: "50vw", width: "12em" }}>
          {/* <BlobButton name={"The Ro5 Team"} /> */}
        </div>
      </div>

    </>
  );
}

export default AboutRo5;
