import React from "react";

import "styles/services.scss";

function ServicesWidget(props) {
  const { listName, list, isWhite } = props;

  return (
    <div className={isWhite ? "widgetServices" : "blueWidgetServices"}>
      <div className="widgetListNameHolder">
        <div className={isWhite ? "whiteWidgetName" : "blueWidgetName"}>
          {listName}
        </div>
        <div className="widgetList">
          <ul style={{ marginLeft: "25px" }}>
            {list.map((entry, index) => (
              <li key={index} className="widgetListItem">
                <div className="widgetListItemEntry">{entry}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}


function BulletedServicesWidget(props) {
  const { listName, mappedLists, isWhite } = props;
  return (
    <div className={isWhite ? "widgetServices" : "blueWidgetServices"}>
      <div className="widgetListNameHolder">
        <div className={isWhite ? "whiteWidgetName" : "blueWidgetName"}>
          {listName}
        </div>
        <div className="widgetList">
          <ul style={{ marginLeft: "25px" }}>
            {Object.entries(mappedLists).map(([name, list]) => (
              <li key={name} className="widgetListItem">
                <div className="widgetListItemEntry">{name}</div>
                <ul>

                {list.map((entry, index) => (
                  <li key={index} className="widgetListItem">
                    <div className="widgetListItemEntry2">{entry}</div>
                  </li>
                ))
              }
              </ul>

              </li>
            )
            )
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export { BulletedServicesWidget }

export default ServicesWidget;
