import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "styles/history.scss";
import Ro5WeillCornell from "./CaseStudiesArticles/Ro5WeillCornell";
import Ro5StrateosTeamUp from "./CaseStudiesArticles/Ro5StrateosTeamUp";
import { titleToPath } from "../../utils";
import { PageTemplate } from "../PageTemplate";
import { CASE_STUDIES_DATA } from "./CaseStudiesData";


function CaseStudiesPageHandler() {
  const navigate = useNavigate();
  const { articleId } = useParams();

  useEffect(() => {
    if (
      !Object.values(CASE_STUDIES_DATA)
        .map((data) => titleToPath(data.title))
        .includes(articleId)
    ) {
      navigate("/case-studies");
    }
  }, [articleId, navigate]);

  return (
    <PageTemplate>
      {articleId ===
        titleToPath(CASE_STUDIES_DATA.RO5_WEILL_CORNELL_NEWS.title) && (
        <Ro5WeillCornell />
      )}
      {articleId ===
        titleToPath(CASE_STUDIES_DATA.RO5_STRATEOS_TEAM_UP.title) && (
        <Ro5StrateosTeamUp />
      )}
    </PageTemplate>
  );
}

export default CaseStudiesPageHandler;

