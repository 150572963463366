import React from "react"

function Loading(props) {

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "auto", background: "rgb(255, 255, 255)", display: "block", shapeRendering: "auto" }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="6.451612903225806" cy="32.7205" r="3.13931" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-0.5s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="0s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-0.5s"></animate>
        </circle><circle cx="6.451612903225806" cy="67.2795" r="2.86069" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-1.5s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-0.5s"></animate>
        </circle><circle cx="16.129032258064512" cy="38.4916" r="3.37931" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-0.7s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-0.2s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-0.7s"></animate>
        </circle><circle cx="16.129032258064512" cy="61.5084" r="2.62069" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-1.7s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.2s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-0.7s"></animate>
        </circle><circle cx="25.806451612903224" cy="51.1572" r="3.58069" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-0.9s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-0.4s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-0.9s"></animate>
        </circle><circle cx="25.806451612903224" cy="48.8428" r="2.41931" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-1.9s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.4s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-0.9s"></animate>
        </circle><circle cx="35.48387096774193" cy="62.9187" r="3.34069" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-1.1s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-0.6s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-1.1s"></animate>
        </circle><circle cx="35.48387096774193" cy="37.0813" r="2.65931" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-2.1s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.6s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-1.1s"></animate>
        </circle><circle cx="45.16129032258064" cy="67.635" r="3.10069" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-1.3s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-0.8s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-1.3s"></animate>
        </circle><circle cx="45.16129032258064" cy="32.365" r="2.89931" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-2.3s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.8s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-1.3s"></animate>
        </circle><circle cx="54.838709677419345" cy="67.2795" r="2.86069" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-1.5s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-1.5s"></animate>
        </circle><circle cx="54.838709677419345" cy="32.7205" r="3.13931" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-2.5s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-1.5s"></animate>
        </circle><circle cx="64.51612903225805" cy="61.5084" r="2.62069" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-1.7s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.2s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-1.7s"></animate>
        </circle><circle cx="64.51612903225805" cy="38.4916" r="3.37931" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-2.7s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2.2s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-1.7s"></animate>
        </circle><circle cx="74.19354838709677" cy="48.8428" r="2.41931" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-1.9s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.4s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-1.9s"></animate>
        </circle><circle cx="74.19354838709677" cy="51.1572" r="3.58069" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-2.9s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2.4s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-1.9s"></animate>
        </circle><circle cx="83.87096774193547" cy="37.0813" r="2.65931" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-2.1s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.6s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-2.1s"></animate>
        </circle><circle cx="83.87096774193547" cy="62.9187" r="3.34069" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-3.1s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2.6s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-2.1s"></animate>
        </circle><circle cx="93.54838709677418" cy="32.365" r="2.89931" fill="#666666">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-2.3s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.8s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#12AFE1;#12AFE1;#666666" dur="2s" repeatCount="indefinite" begin="-2.3s"></animate>
        </circle><circle cx="93.54838709677418" cy="67.635" r="3.10069" fill="#bbbbbb">
          <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004" dur="2s" repeatCount="indefinite" begin="-3.3s"></animate>
          <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2.8s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
           <animate attributeName="fill" keyTimes="0;0.5;1" values="#164ba3;#164ba3;#bbb" dur="2s" repeatCount="indefinite" begin="-2.3s"></animate>
        </circle>
      </svg>
    </>
  )
}

export default Loading