/**
 * News page of the site
 *
 * @author Amit Setty
 */
import React, { useEffect } from "react";

import covidGraphic from "res/assets/Background/CovidWeb@2x.png";
import paper from "res/assets/Images/Paper_Preview.png";
import link from "res/assets/Icons/Utility/Link.png";
import download from "res/assets/Icons/Utility/Download.png";
import molecule1 from "res/assets/Molecules/Molecule1.png";
import molecule2 from "res/assets/Molecules/Molecule2.png";
import molecule3 from "res/assets/Molecules/Molecule3.png";

import "styles/news.scss";
import { NEWS_TITLES } from "./NewsMasterPage/NewsPageHandler"

const pictures = [
  covidGraphic,
  paper,
  link,
  download,
  molecule1,
  molecule2,
  molecule3,
];

var ReactGA = require("react-ga");


function Covid19PaperPublished() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `News - ${NEWS_TITLES.COVID_19_PAPER_PUBLISHED_TITLE}`
  }, []);
  
  useEffect(() => {
    pictures.forEach((img) => (new Image().src = img));
  }, []);

  return (
    <>
      <div className="newsHeader newsFlex">
        <div style={{ flex: 0.2 }} />
        <div
          className="category bold"
          style={{ paddingTop: "2.5em", textAlign: "left", flex: 1 }}
        >
          <span className="category" style={{ fontSize: ".4em" }}>
            13.05.2020
          </span>
          <br />
          <span className="bold" style={{ textTransform: "none" }}>
            {NEWS_TITLES.COVID_19_PAPER_PUBLISHED_TITLE}
          </span>
          <h4 style={{ paddingTop: ".5em", textTransform: "none" }}>
            As the clock ticks on Covid-19 research, in a new ChemRxiv.org
            preprint Ro5 adds vital information.
          </h4>
        </div>
        <div style={{ flex: 1.7 }}>
          <img
            className="desktopPaddingNews"
            style={{ maxWidth: "100%" }}
            alt="infographic"
            src={covidGraphic}
          />
        </div>
      </div>

      <div className="tabletFlex">
        <div className="" style={{ flex: 5, display: "block" }}>
          <div>
            <div className="desktop30LPadding regular" style={{ fontSize: "2rem" }}>
              A new ChemRxiv.org preprint by Ro5, the artificial intelligence
              (AI) company of interdisciplinary scientists in Europe, the UK,
              and the US, promotes an HIV drug now at the center of research,
              along with an anti-inflammatory compound, to fight Covid-19 —
              vital information as the clock ticks. The report also identifies
              two completely new molecules for novel drug research.
            </div>
            <p className="desktop30LPadding" style={{ paddingTop: "1.5em" }}>
              The study’s six top drug candidates begins with Nelfinavir and
              Saquinavir, HIV drugs currently approved for market; a third
              HIV-related drug, closed after phase-one clinical trials, merits
              new consideration. The study also suggests that Itacitinib, an
              anti-inflammatory molecule, can help treat the disease in its late
              stages and inhibit the 3CLpro protein essential to its
              replication. Finally, two novel drugs show strong promise to help
              resist the virus.
            </p>
          </div>
          <QuoteBlock
            quote="“In the race to treat Covid-19, among the many candidates, Nelfinavir and Itacitinib should be considered for further investigation”"
            author="ROY TAL, Ro5 COFOUNDER AND PRINCIPAL SCIENTIST OF DATA SCIENCE AND PHARMACOLOGY"
          />
          <div className="desktop30LPadding">
            <p style={{ paddingTop: "1.5em" }}>
              Nelfinavir, approved to treat HIV, is currently in lab studies as
              a possible treatment for Covid-19.
            </p>
          </div>
        </div>

        <center style={{ flex: 3, paddingBottom: 100 }}>
          <div>
            <ReactGA.OutboundLink
              eventLabel="Link To COVID Paper"
              to="https://chemrxiv.org/engage/chemrxiv/article-details/60c74af40f50db3836396b4e"
              target="_blank"
              trackerNames={["Link to COVID Paper"]}
              style={{ paddingTop: "-2em" }}
            >
              <center className="paperLink">
                <img style={{ maxWidth: "50%" }} alt="Paper" src={paper} />
              </center>
              <div style={{ paddingTop: "1em" }} />
              <center className="paperLink">
                <img style={{ maxWidth: "8%" }} alt="Link" src={link} />
                &nbsp;&nbsp; ChemRxiv.org
              </center>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="Download COVID Paper"
              to="https://chemrxiv.org/engage/api-gateway/chemrxiv/assets/orp/resource/item/60c74af40f50db3836396b4e/original/ro5-bioactivity-lab-identification-of-drug-candidates-for-covid-19.pdf"
              target="_blank"
              trackerNames={["Download COVID Paper"]}
              style={{ paddingTop: "-2em" }}
            >
              <div style={{ paddingTop: "1em" }} />
              <div className="paperLink" style={{ paddingRight: "6%" }}>
                <img style={{ maxWidth: "8%" }} alt="Download" src={download} />
                &nbsp;&nbsp; Download
              </div>
            </ReactGA.OutboundLink>
          </div>
        </center>
      </div>

      <div
        className="responsiveGridTablet responsiveGrid3 covid-images"
        style={{ gridGap: "4em" }}
      >
        <div>
          <img className="covid-image" src={molecule1} alt="molecule" />
          <p className="covid-image-desc">
            Docking pose of Nelfinavir in 3CL protease active site. Non-covalent
            docking predicted the binding affinity to be -7.1 kcal/mol.
          </p>
        </div>
        <div>
          <img className="covid-image" src={molecule2} alt="molecule" />
          <p className="covid-image-desc">
            Docking pose of Itacitinib in 3CL protease active site. Non-covalent
            docking predicted the binding affinity to be -8.3 kcal/mol.
          </p>
        </div>
        <div style={{ paddingTop: 12 }}>
          <img className="covid-image" src={molecule3} alt="molecule" />
          <p className="covid-image-desc">
            Docking pose of Saquinavir in 3CL protease active site. Non-covalent
            docking predicted the binding affinity to be -9.3 kcal/mol.
          </p>
        </div>
      </div>

      <div className="tabletFlex">
        <div style={{ flex: 5 }}>
          <div className="desktop30LPadding" style={{ paddingTop: "3em", fontSize: "1.55rem" }}>
            Concurrent to the Ro5 AI predictions, independent experiments for
            Covid-19 treatments corroborate Nelfinavir as the best of all tested
            HIV protease inhibitors. Itacitinib gains importance as researchers
            increasingly suggest inflammation is a leading attribute of the
            disease.
          </div>
          <QuoteBlock
            quote="“In a single day, from a database of millions of compounds, the models extracted 20,000 molecules, then, screening with AI, narrowed to six strong contenders.”"
            author="ŽYGIMANTAS JOČYS, Ro5 COFOUNDER AND PRINCIPAL SCIENTIST OF MACHINE LEARNING"
          />
          <div className="desktop30LPadding" style={{ paddingTop: "3em", fontSize: "1.55rem" }}>
            Ro5 Principal Scientist of Machine Learning Žygimantas Jočys
            describes how AI’s ability to reduce the time of drug discovery and
            repurposing opens shortcuts for researchers: “In a single day, from
            a database of millions of compounds, the models extracted 20,000
            molecules, then, screening with AI, narrowed to six strong
            contenders.”
          </div>
          <div className="desktop30LPadding" style={{ paddingTop: "3em", fontSize: "1.55rem" }}>
            In 2018, Tal and Jočys, with Ro5 CEO Charles Dazler Knuff, formed
            the US- and London-based AI company using ADMET, Bioactivity, De
            Novo Drug Discovery, and Clinical Trial Analytics platforms both to
            serve and to lead medical research. Training its machine learning
            systems on vast data and keeping all processes transparent, Ro5
            renders sophisticated knowledge from even limited data to vastly
            speed drug discovery and shorten the pipeline from possibility to
            patients.
          </div>
        </div>
        <div style={{ flex: 3 }}></div>
      </div>
    </>
  );
}

function QuoteBlock(props) {
  const { quote, author } = props;
  return (
    <div className="desktop30LPadding" style={{ paddingTop: "4em", fontSize: "2rem" }}>
      {quote}
      <br />
      <span
        className="category bright-blue-text"
        style={{ paddingTop: "1.3em", fontSize: "1.7rem" }}
      >
        {author}
      </span>
    </div>
  );
}

export default Covid19PaperPublished;
