import React from "react"
import { PageTemplate } from "./PageTemplate"

function Thanks() {

    const titleStyle = {
        paddingTop: '15%',
        paddingBottom: '7%',
        textAlign: 'left',
        flex: .8,
        fontSize: '1.2em',
        lineHeight: '1em',
    }

    return (
    <PageTemplate>
        <div style={{ display: 'flex' }} className="deNovoHeader">
            <div style={{ flex: .2 }} />
            <div className="bold" style={titleStyle}>
                Thank you for your inquiry
                <span>
                    <div style={{ fontSize: '.4em', fontFamily: 'Rubik', lineHeight: '1.2em', paddingTop: '.5em' }}>
                       We will get in touch with you as soon as possible
                    </div>
                </span>
            </div>
            <div style={{ flex: 1.7 }}>
                {/* <img style={{ maxWidth: '100%' }} alt="infographic" src={infographic} /> */}
            </div>
        </div>
    </PageTemplate>
    )
}

export default Thanks