/**
 * General component for the descriptions of all platforms. This component maintains the 
 * overall structure of the content of the platform pages
 * @author Amit Setty
 */
import React from "react"

function PlatformComponent(props) {
    const { title, desc } = props
    return (
        <div>
            <p className="primaryColor" style={{ fontSize: '42px' }}>
                {title}
            </p>
            <div style={{ fontSize: '1.2rem', lineHeight: '1.2em' }}>{desc}</div>
            <div style={{ paddingTop: '1.2em' }} />
        </div>
    )
}
export default PlatformComponent