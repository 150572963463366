/**
 * This is the navigation bar for the desktop version of the ro5 site
 *
 * @author Amit Setty
 */
import React from "react"
import { useLocation, useNavigate } from "react-router"
import $ from "jquery"

import DropdownArrow from "res/assets/Icons/DropdownArrow.svg"

function NavBar () {
  let currentPath = useLocation()
  const navigate = useNavigate()

  if (currentPath.pathname !== undefined)
    currentPath = currentPath.pathname.substring(1)

  $(document).ready(function () {
    $(".headerText").hover(function () {
      $(".dropdownContainer").show()
    })
  })
  $(document).ready(function () {
    $(".dropdownContainer").click(function () {
      $(this).hide()
    })
  })

  return (
    <div className="navBar">
      <div className="headerTextContainer">
        <span className="link">
          <span
            className={
              /hydrascreen|spectraview|applications/.test(
                currentPath
              )
                ? "selectedHeaderText headerText"
                : "headerText"
            }
          >
            <span className="underline" onClick={() => {navigate("/applications")}}>Applications</span> &nbsp;
            <img className="caret" src={DropdownArrow} alt="" />
            <div className="dropdownContainer">
              <div className="dropdown">
                <DropdownElem url="/applications" name="SpectraView" pagePart="SpectraView" />
                <DropdownElem url="/applications" name="HydraScreen" pagePart="HydraScreen" />
              </div>
            </div>
          </span>
        </span>
      </div>
      <div className="headerTextContainer">
        <span className="link">
          <span
            className={
              /de-novo-drug-discovery|bioactivity|ADMET|knowledge-graph/.test(
                currentPath
              )
                ? "selectedHeaderText headerText"
                : "headerText"
            }
          >
            <span className="unclickableDropdown">Capabilities</span> &nbsp;
            <img className="caret" src={DropdownArrow} alt="" />
            <div className="dropdownContainer">
              <div className="dropdown">
                <DropdownElem url="/de-novo-drug-discovery" name="De Novo Drug Discovery" />
                <DropdownElem url="/bioactivity" name="Bioactivity" />
                <DropdownElem url="/ADMET" name="ADMET" />
                <DropdownElem url="/knowledge-graph" name="Knowledge Graph" />
              </div>
            </div>
          </span>
        </span>
      </div>

      <div className="headerTextContainer">
        <span className="link">
          <span
            className={
              /services/.test(
                currentPath
              )
                ? "selectedHeaderText headerText"
                : "headerText"
            }
          >
            <span className="underline" onClick={() => {navigate("/services")}}>Professional Services</span> &nbsp;
            <img className="caret" src={DropdownArrow} alt="" />
            <div className="dropdownContainer">
              <div className="dropdown">
                <DropdownElem url="/services" name="Client Programs" pagePart="clientProg" />
                <DropdownElem url="/services" name="Drug Discovery Programs" pagePart="discovery" />
              </div>
            </div>
          </span>
        </span>
      </div>

      <div className="headerTextContainer">
        <span className="link">
          <span
            className={
              /the-ro5-advisory-board|the-ro5-team/.test(
                currentPath
              )
                ? "selectedHeaderText headerText"
                : "headerText"
            }
          >
            <span className="unclickableDropdown">Company</span> &nbsp;
            <img className="caret" src={DropdownArrow} alt="" />
            <div className="dropdownContainer">
              <div className="dropdown">
                <DropdownElem url="/the-ro5-team" name="Team" />
                <DropdownElem url="/the-ro5-advisory-board" name="Advisory Board" />
                <DropdownElem url="/join-us" name="Join Us" />
                <DropdownElem url="/contact-us" name="Contact Us" />
              </div>
            </div>
          </span>
        </span>
      </div>

      <div className="headerTextContainer">
        <span className="link">
          <span
            className={
              /news|patent-page|publication-page/.test(
                currentPath
              )
                ? "selectedHeaderText headerText"
                : "headerText"
            }
          >
            <span className="underline" onClick={() => {navigate("/")}}>Publications and News</span> &nbsp;
            <img className="caret" src={DropdownArrow} alt="" />
            <div className="dropdownContainer">
              <div className="dropdown">
                <DropdownElem url="/news" name="News" pagePart="ctp" />
                <DropdownElem url="/publication-page" name="Publications" pagePart="discovery" />
                <DropdownElem url="/patent-page" name="Patents" pagePart="entry" />
                <DropdownElem url="/case-studies" name="Case Studies" pagePart="entry" />
              </div>
            </div>
          </span>
        </span>
      </div>
    </div>
  )
}

export default NavBar

function DropdownElem ({ url, name, pagePart }) {
  let currentPath = useLocation()
  if (currentPath.pathname !== undefined)
    currentPath = currentPath.pathname.substring(1)

  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate(url, { state: { pagePart } })}
      className={
        currentPath.substring(currentPath.indexOf("/") + 1) !== name
          ? "dropdownElem"
          : "dropdownElem selectedDropdownElem"
      }
    >
      {name}
    </div>
  )
}
