import React, { useEffect } from "react"
import "styles/history.scss"
import molecule from "res/assets/Images/NewsArticles/Molecule_with_bg.png"
import pipeline from "res/assets/Images/NewsArticles/NelfinavirPipeline.png"
import { NEWS_TITLES } from "../NewsMasterPage/NewsPageHandler"


function Nelfinavir() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `News - ${NEWS_TITLES.NELFINAVIR_TITLE}`
  }, []);

  return (
    <>
      <div className="newsHeader newsFlex" style={{ marginBottom: "10vh" }}>
        <div style={{ flex: 0.2 }} />
        <div
          className="category bold"
          style={{ paddingTop: "2.5em", textAlign: "left", flex: 1.3 }}
        >
          <span className="category" style={{ fontSize: ".4em" }}>
            28.04.2021
          </span>
          <br />
          <span className="bold" style={{ textTransform: "none" }}>{NEWS_TITLES.NELFINAVIR_TITLE}</span>
          <h4 style={{ paddingTop: ".5em", textTransform: "none" }}>
            Nelfinavir (Viracept), the drug that Ro5 identified for repurposing
            towards Covid-19 early on in the pandemic, entered clinical trials
            for Covid-19 in April 2021.
          </h4>
        </div>
        <center style={{ flex: 1.7 }}>
          <img
            className="desktopPaddingNews"
            style={{
              maxWidth: "60%",
              paddingTop: "15vh",
              paddingBottom: "5vh",
            }}
            alt="infographic"
            src={molecule}
          />
        </center>
      </div>

      <div
        className="tabletFlex"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="nelfinavirText">
          <div>
            <div className="regular" style={{ fontSize: "2rem" }}>
              Nelfinavir (Viracept), the drug Ro5 identified early in the
              pandemic to repurpose toward Covid-19, entered clinical trials for
              Covid-19 in April 2021, demonstrating the Ro5 AI Chemistry
              platform’s ability to rapidly discover life-changing drugs.
            </div>
            <p className="" style={{ paddingTop: "1.5em", fontSize: "1.7rem" }}>
              In 2020, using its biomedical knowledge graph, Ro5 identified
              20,000 antiviral and anti-inflammatory molecules as candidates to
              treat Covid-19. Within days--a fraction of the time of
              conventional workflows--the Ro5 patented bioactivity system had
              screened the compounds against the 3CLpro enzyme of SARS-CoV-2.
            </p>
          </div>
          <QuoteBlock
            quote="Certain molecules stood out early in the process. “In the race to treat Covid-19, among the many candidates, Nelfinavir [and Itactinib] should be considered for further investigation,”"
            author="Ro5 cofounder Roy Tal said in 2020, when the pandemic began."
          />
        </div>
      </div>
      <div className="nelfinavirPhotoDesktop">
        <img
          src={pipeline}
          alt="Pipeline"
          style={{ width: "100%", marginTop: "4vh", marginBottom: "2vh" }}
        />
      </div>
      <div
        className="tabletFlex"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="nelfinavirText">
          <div>
            <div className="">
              <p style={{ paddingTop: "1.5em", fontSize: "1.7rem" }}>
                In May 2020, Ro5 released a preprint to the scientific community (
                <a
                  href="https://chemrxiv.org/engage/chemrxiv/article-details/60c74af40f50db3836396b4e"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textTransform: "none" }}
                >
                  Yang et al., 2020
                </a>
                ). Among the six drug candidates identified in the paper, two
                compounds exhibited potency{" "}
                <span style={{ fontStyle: "italic" }}>in vitro:</span> Nelfinavir
                and Saquinavir, both drugs originally designed as HIV protease
                inhibitors.
              </p>
            </div>
            <QuoteBlock
              quote="“Both HIV and SARS CoV-2 produce polyproteins that have to be cleaved by a protease to be functional. If you block the viral protease with an inhibitor like Nelfinavir, you can effectively suppress viral replication,”"
              author="Brock Luty said. Luty worked with the research team at Agouron Pharmaceuticals that originally developed Nelfinavir."
            />
            <div className="">
              <p style={{ paddingTop: "1.5em", fontSize: "1.7rem" }}>
                Once significant evidence showed Nelfinavir as a potential
                treatment for Covid-19, Nelfinavir entered the clinic to
                investigate its efficacy and safety in humans (
                <a
                  href="https://trialsjournal.biomedcentral.com/articles/10.1186/s13063-021-05282-w"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textTransform: "none" }}
                >
                  Hosogaya et al., 2021
                </a>
                ). There is still a need to identify more treatment options for
                Covid-19 besides the recently FDA EUA-approved antivirals,
                Paxlovid (Nirmatrelvir and Ritonavir) and Molnupiravir, to keep up
                with increasing demand for courses of treatment.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="dislogHolderAboutRo5" style={{width: "50%", marginRight: "4vw"}}>
          <div style={{ marginTop: "4em" }} className="dialogBox">
            <div style={{ paddingTop: ".5em" }} />
            <p className="dialogText">
              “Both HIV and SARS CoV-2 produce polyproteins that have to be
              cleaved by a protease to be functional. If you block the viral
              protease with an inhibitor like Nelfinavir, you can effectively
              suppress viral replication,”
            </p>
            <div>
              Brock Luty said. Luty worked with the research team at Agouron
              Pharmaceuticals that originally developed Nelfinavir.
            </div>
          </div>
        </div> */}

      </div>
    </>
  );
}

function QuoteBlock(props) {
  const { quote, author } = props;
  return (
    <div className="" style={{ paddingTop: "2vh", fontSize: "2rem" }}>
      {quote}
      <br />
      <span
        className="category bright-blue-text"
        style={{ paddingTop: "1.3em", fontSize: "1.7rem" }}
      >
        {author}
      </span>
    </div>
  );
}

export default Nelfinavir;
