/**
 * Card displaying picture and info for each team member
 *
 * @author Amit Setty, Zvonty Flugence
 */
import React, { useEffect } from "react";

import Roy from "res/assets/Team/Roy-Tal.png";
import Charles from "res/assets/Team/Charles Dazler Knuff.58603a5b_ed.png";
import Aurimas from "res/assets/Team/Aurimas.png";
import Dainius from "res/assets/Team/Dainius_Salkauskas_edit.png";
import Orestis from "res/assets/Team/Orestis Bastas.de530996edit.png";
import Amit from "res/assets/Team/AmitSetty_edit.png";
import Tim from "res/assets/Team/Tim-Kras.png";
import Gintautas from "res/assets/Team/Gintautas-Kamuntavicius.png";
import Will from "res/assets/Team/Will-Motgomery.png";
import Alvaro from "res/assets/Team/Alvaro_Prat.png";
import Jon from "res/assets/Team/JonBerke.png";
import JKindler from "res/assets/AdvisoryBoard/JKindler.png";
import CRoutledge from "res/assets/AdvisoryBoard/CRoutledge.png";
import JHowe from "res/assets/AdvisoryBoard/JHowe.png";
import SKatzman from "res/assets/AdvisoryBoard/SKatzman.png";
import TTully from "res/assets/AdvisoryBoard/TTully.png";
import TuviaBarak from "res/assets/AdvisoryBoard/TuviaBarak.png";
import RCollins from "res/assets/AdvisoryBoard/RCollins.png";
import WCCollins from "res/assets/AdvisoryBoard/BillCollins.png";
import Sarah from "res/assets/Team/Sarah_Flatters.png";
import Hisham from "res/assets/Team/HishamAbdelAty.png";
import Hamed from "res/assets/Team/Hamed.png";
import Siim from "res/assets/Team/Siim.png";
import Tanya from "res/assets/Team/tanya_small.png";
import Piero from "res/assets/Team/piero.png";
import Mike from "res/assets/AdvisoryBoard/Mike.png";

function TeamMemberCard(props) {
  const {
    branch,
    setCurrentPerson,
    setModalOpen,
    name,
    img,
    headline,
    content,
    advisoryBoard,
    roles,
    bioRole,
    cofounder,
  } = props;

  let image = "";
  const firstName = name.split(" ")[0];
  if (/Roy/.test(firstName)) image = Roy;
  if (/Charles/.test(firstName)) image = Charles;
  if (/Orestis/.test(firstName)) image = Orestis;
  if (/Amit/.test(firstName)) image = Amit;
  if (/Aurimas/.test(firstName)) image = Aurimas;
  if (/Dainius/.test(firstName)) image = Dainius;
  if (/Gintautas/.test(firstName)) image = Gintautas;
  if (/Tim/.test(firstName)) image = Tim;
  if (/Will/.test(firstName)) image = Will;
  if (/Alvaro/.test(firstName)) image = Alvaro;
  if (/Jon/.test(firstName)) image = Jon;
  if (/Jeffrey/.test(firstName)) image = JKindler;
  if (/John/.test(firstName)) image = JHowe;
  if (/Carol/.test(firstName)) image = CRoutledge;
  if (/Timothy/.test(firstName)) image = TTully;
  if (/Steven/.test(firstName)) image = SKatzman;
  if (/Tuvia/.test(firstName)) image = TuviaBarak;
  if (/Robert/.test(firstName)) image = RCollins;
  if (/William/.test(firstName)) image = WCCollins;
  if (/Sarah/.test(firstName)) image = Sarah;
  if (/Hisham/.test(firstName)) image = Hisham;
  if (/Hamed/.test(firstName)) image = Hamed;
  if (/Siim/.test(firstName)) image = Siim;
  if (/Tanya/.test(firstName)) image = Tanya;
  if (/Piero/.test(firstName)) image = Piero;
  if (/Mike/.test(firstName)) image = Mike;

  function openModal() {
    setModalOpen(true);
    setCurrentPerson({
      image,
      branch,
      headline,
      img,
      content,
      name,
      roles,
      bioRole,
    });
  }

  useEffect(() => {
    // Force browser to pre-load image
    new Image().src = image;
  }, [image]);

  return (
    <div
      onClick={() => {
        openModal();
      }}
      className={advisoryBoard ? "advisory-member-card" : "team-member-card"}
    >
      <center className="team-member-image-holder">
        <div className="moreInfo" />

        <center style={{ position: "relative", borderRadius: "100vh" }}>
          <img className="team-member-image" src={image} alt="avatar" />
        </center>
      </center>
      <div>
        <div className="team-member-name">{name}</div>

        {!advisoryBoard && (
          <center className="team-member-headline">
            {headline && <>{headline} <br /></>}
            {branch && (
              <b
                className="primary"
                style={{
                  color: "black",
                  fontSize: "1.3rem",
                }}
              >
                {branch}
              </b>
            )}

            {cofounder && (
              <div
                className="primary"
                style={{ color: "black", paddingLeft: ".2em" }}
              >
                {cofounder}
              </div>
            )}
          </center>
        )}

        {advisoryBoard && (
          <center
            className="advisory-roles"
            style={{ color: "black", fontSize: "1.1em" }}
          >
            <div>
              {roles &&
                roles.map((role, index) => {
                  return <div key={index} style={{ fontSize: "1.5rem" }}>{role}</div>;
                })}
            </div>
          </center>
        )}
      </div>
    </div>
  );
}
export default TeamMemberCard;
