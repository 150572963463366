import React, { useEffect } from "react"
import JeffreyImage from "res/assets/AdvisoryBoard/JKindler.png"
import quote from 'res/assets/Background/quote.svg';

function OurImpact(id) {

    useEffect(() => {
        // Force pre-load image
        new Image().src = JeffreyImage
    }, [])

    return (
        <div className="blueBg" style={{ display: 'flex', position: 'relative' }} id="Our Impact">
            <div className='bg-quote' style={{ backgroundImage: `url(${quote})` }} />
            <div style={{ width: '70vw', margin: 'auto', paddingTop: '5em', paddingBottom: '5em' }}>
                <div style={{}}>

                    <blockquote>
                        <p className="quoteFont" style={{ color: 'white',  lineHeight: '1.2em', }}>“<b>The Ro5 Platforms are a revolutionary solution to the most complex problems in drug discovery.</b>”
                        </p>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ borderRadius: '100%', paddingTop: 20 }}>
                                <img style={{ width: 150, height: 150 }} alt="Jeffrey Kindler" src={JeffreyImage} />
                            </div>
                            <cite><span className="bold">Jeffrey Kindler</span> <br/>Former CEO of Pfizer</cite>
                        </div>
                    </blockquote>
                </div>
            </div>
            <div style={{ lex: 1 }}>
                {/* <img style={{maxHeight:'20em'}} src={mediumTree} alt="medium tree"/> */}
            </div>
        </div>
    )
}

export default OurImpact