import React, { useEffect } from "react"
import "styles/history.scss"
import DrugDiscoveryImage from "res/assets/Images/ClientPrograms/drug_discovery.png"
import ClinicalTrialImage from "res/assets/Images/ClientPrograms/Clinical_Trial_cleaned.png"

function ClientPrograms () {

  useEffect(() => {document.title = "Client Programs"}, [])

  return (
    <center className="whiteBgFlexLanding" style={{marginTop: "2rem"}}>
      <div style={{ width: "65%", marginBottom: "3rem" }}>
        <center
          className="whiteBgTitle"
          style={{ position: "relative", marginBottom: "1.5em" }}
        >
          Client Programs
          <div className="bg-molecule" />
          <div className="bg-rounded-polygon" />
          <div className="bg-polygon-outline" />
        </center>
        <center>
          <div className="whiteBgIntroText">
            Our pipeline demonstrates the diversity of programs currently
            undertaken by Ro5 to accelerate drug discovery and to derisk clinical
            trials.
          </div>
        </center>
      </div>

      <p
        className="primaryColor"
        style={{ fontSize: "1.8em", marginBottom: "5vh" }}
      >
        Drug Discovery
      </p>
      <img
        src={DrugDiscoveryImage}
        alt={"Drug discovery"}
        style={{
          width: "80%",
          marginBottom: "10vh",
          imageRendering: "high-quality",
        }}
      />
      <p
        className="primaryColor"
        style={{ fontSize: "1.8em", marginBottom: "5vh" }}
      >
        Clinical Trial Analytics
      </p>
      <img
        src={ClinicalTrialImage}
        alt={"Clinical Trials"}
        style={{ width: "80%", imageRendering: "high-quality" }}
      />
    </center>
  )
}

export default ClientPrograms
