import React from "react";
import jobsData from "./jobsData";
import { useLocation, useNavigate } from "react-router-dom";
import "styles/history.scss";
import { PageTemplate } from "../PageTemplate"

function JobPage() {
  const location = useLocation();
  const navigate = useNavigate();

  let jobId = location.pathname.split("/");
  jobId = jobId[jobId.length - 1];

  if (jobId >= jobsData.length){
    navigate(`/join-us`);
  }
  
  const storyStyle = {
    width: "37%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: ".4em",
    fontFamily: "rubikRegular",
  };

  return (
  <PageTemplate>
    <center>
      <center className="historyHeader" style={{ position: "relative" }}>
        Join Us
        <div style={storyStyle}>
          Your work can make a world of difference.
          <br />
          <i>We’re a widely diverse team using AI to serve humankind.</i>
        </div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div style={{ maxWidth: "60%", textAlign:"left" }}>
        {jobId >= jobsData.length ? "" : jobsData[jobId].jobDescription}
      </div>
    </center>
  </PageTemplate>
  );
}

export default JobPage;
