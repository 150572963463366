import React, { useEffect } from "react";
import "styles/history.scss";
import { PageTemplate } from "../PageTemplate";
import { CASE_STUDIES_DATA } from "./CaseStudiesData";
import CaseStudiesWidget from "./CaseStudiesWidget";

function CaseStudiesMasterPage() {
  useEffect(() => {
    document.title = "Case Studies";
  }, []);

  return (
    <PageTemplate>
      <center>
        <center
          className="historyHeader"
          style={{ position: "relative", marginBottom: "1.5em" }}
        >
          Ro5 Case Studies
          <div className="bg-molecule" />
          <div className="bg-rounded-polygon" />
          <div className="bg-polygon-outline" />
        </center>

        <div className="widgetHolder">
          {Object.values(CASE_STUDIES_DATA).map((data, id) => (
            <CaseStudiesWidget
              key={id}
              title={data.title}
              date={data.date}
              picture={data.thumbnail}
            />
          ))}
        </div>
      </center>
    </PageTemplate>
  );
}

export default CaseStudiesMasterPage;

