import React, { useEffect } from "react";
import WhiteSection from "./WhiteSection";

import "styles/services.scss";
import { PageTemplate } from "../PageTemplate"

function TargetEvalPrio() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageTemplate>
      <div
        className="historyHeader"
        style={{
          position: "relative",
          marginBottom: 0,
        }}
      >
        <center className="serviceSectionTitle">
          <div style={{ width: "90%" }}>
            Target Evaluation and Prioritization
          </div>
        </center>
        <center className="servicesStory">
          Ro5’s Biomedical Knowledge Graph uncovers new associations between
          biological processes and evaluates targets based on risks, biology,
          therapeutic and market potential.
        </center>
        <div className="bg-molecule" style={{ width: "30%" }} />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </div>
      <div className="smallPageSection">
        <WhiteSection
          benefitList={[
            "Intelligently select the most promising targets and rank them by their risks and potential.",
            "Identify unique scientific and market opportunities.",
            "Derisk decision making at early stages.",
          ]}
          introParagraph={null}
          smallParagraphs={[
            {
              title: "Target prioritization",
              content:
                "Rank hypotheses by the type and strength of evidence, scientific credibility, drug development potential, clinical trial risks, therapeutic opportunity, and competition.",
            },
            {
              title: "Risk assessment",
              content:
                "Assess the risks of drugging a given target using assay, rodent, human, genetic, and clinical data. Using historical data, evaluate the success rate and risks in pursuing a clinical trial in a disease area.",
            },
            {
              title: "Market opportunity assessment",
              content:
                "Identify the market size, opportunities, risks, and competitive landscape for a given target, drug, or disease.",
            },
          ]}
        />
      </div>
    </PageTemplate>
  );
}

export default TargetEvalPrio;
