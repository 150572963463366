import React, { useEffect } from "react";
import { PageTemplate } from "../PageTemplate"
import WhiteSection from "./WhiteSection";

import "styles/services.scss";

function HitLeadOpt() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageTemplate>
      <div
        className="historyHeader"
        style={{
          position: "relative",
          marginBottom: 0,
        }}
      >
        <center className="serviceSectionTitle">
          <div style={{ width: "90%" }}>Hit to Lead and Lead Optimization</div>
        </center>
        <center className="servicesStory">
          Effectively generate compounds using Ro5’s proprietary patent pending{" "}
          <span style={{ fontStyle: "italic" }}>de novo</span> molecule
          generation module, whether you have a protein structure or not. After
          the compounds are generated, Ro5 evaluates their IP status and using
          patent pending technology, ADMET properties are predicted{" "}
          <span style={{ fontStyle: "italic" }}>in silico</span> to select the
          most promising candidates. For the most promising compounds, Ro5’s
          patented retrosynthesis module proposes different ways of
          synthesizing.
        </center>
        <div className="bg-molecule" style={{ width: "30%" }} />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </div>
      <div className="smallPageSection">
        <WhiteSection
          sectionName={"Hit-to-lead and lead optimization"}
          benefitList={[
            "Effectively explore chemical space, while optimizing for activity and other desirable chemical properties.",
            "Evaluate the generated compounds by chemical properties, IP Status etc.",
            "Reduce the time and costs of synthesizing compounds.",
            "Rapid expansion of IP portfolio and improved freedom to operate.",
          ]}
          introParagraph={null}
          smallParagraphs={[
            {
              title: (
                <div>
                  <span style={{ fontStyle: "italic" }}>De Novo</span> compound
                  generation
                </div>
              ),
              content:
                "Using patented, state of the art 3D modules, that allow for Multi Objective Optimization (MOO).",
            },
            {
              title: "Evaluation of the compounds",
              content:
                "Evaluate the risks and IP status of the generated compounds.",
            },
            {
              title: "Compound optimization",
              content:
                "Sophisticated in house cheminformatics and AI pipelines to elucidate structure activity relationships, binding mode and subsequent multi property optimization of the compounds using fine tuned machine learning models.",
            },
            {
              title: (
                <div>
                  ADMET property prediction{" "}
                  <span style={{ fontStyle: "italic" }}>in silico</span>
                </div>
              ),
              content:
                "Identify compounds with favorable ADMET properties to select the best compounds.",
            },
            {
              title: "Retrosynthesis",
              content:
                "Find the most effective route to synthesize the compounds.",
            },
          ]}
        />
      </div>
    </PageTemplate>
  );
}

export default HitLeadOpt;
