/**
 * This navigation component which for the mobile version of the site
 *
 * It isn't fully developed and should be improved upon
 *
 * @author Amit Setty
 */

import React from "react"
import clsx from "clsx"
import { useTheme } from "@mui/material/styles"
import Drawer from "@mui/material/Drawer"
import CssBaseline from "@mui/material/CssBaseline"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useLocation, useNavigate } from "react-router-dom"
import Data from "../../res/assets/Icons/Ro5_Modules/Data_white.png"
import admetIcon from "../../res/assets/Icons/Ro5_Modules/ADMET_white.png"
import bioactivityIcon from "../../res/assets/Icons/Ro5_Modules/Bioactivity_white.png"
import deNovoIcon from "../../res/assets/Icons/Ro5_Modules/De_Novo_white.png"
import joinUsIcon from "../../res/assets/Icons/suitcase-work.png"
import patentsIcon from "../../res/assets/Icons/certification-award-white.png"
import targetWhite from "res/assets/Images/Services/target_white_low.png"
import hitIDwhite from "res/assets/Images/Services/molecule_white_low.png"
import "styles/navbar.scss"

function NavBarMobile () {
  const [open, setOpen] = React.useState(false)
  return (
    <div style={{ overflow: "hidden" }}>
      <div onClick={() => setOpen(true)}>
        <i
          style={{
            position: "absolute",
            right: 20,
            color: "white",
            marginTop: ".5em",
            fontSize: "2em",
          }}
          className="fas fa-bars"
        />
      </div>
      <DrawerComp open={open} setOpen={setOpen} />
    </div>
  )
}

const drawerWidth = 200

function getMobileMenuListItem (openPage, text) {
  return <ListItem
    onClick={() => openPage(text.url, text.pagePart)}
    button
    key={text.name}
  >
    <ListItemIcon style={{ color: "white" }}>
      {" "}
      {text.icon}
    </ListItemIcon>
    <ListItemText primary={text.name} />
  </ListItem>
}

function DrawerComp ({ open, setOpen }) {
  const navigate = useNavigate()
  const currentPath = useLocation().pathname

  function openPage (page, pagePart = false) {
    window.scrollTo(0, 0)
    handleDrawerClose()
    pagePart === false
      ? navigate("/" + page)
      : navigate("/" + page, { state: { pagePart } })
  }

  const theme = useTheme()

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const navBarIconStyle = {
    maxHeight: 35,
    maxWidth: 35,
    marginLeft: 8,
  }

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{ width: drawerWidth, flexShrink: 0 }}
        onClose={handleDrawerClose}
        anchor="right"
        open={open}
        classes={{
          paper: { width: drawerWidth },
        }}
      >
        <div className="navBarMobileBg">
          <List>
            <p
              style={{
                textAlign: "left",
                marginLeft: "1.2em",
                paddingTop: ".1em",
                cursor: "pointer",
              }}
              onClick={() => openPage("applications")}
            >
              Applications
            </p>
            {[
              {
                url: "applications",
                name: "SpectraView",
                pagePart: "SpectraView",
                icon: (
                  <img
                    alt="SpectraView"
                    style={navBarIconStyle}
                    src={targetWhite}
                  />
                ),
              },
              {
                url: "applications",
                name: "HydraScreen",
                pagePart: "HydraScreen",
                icon: (
                  <img
                    alt="HydraScreen"
                    style={navBarIconStyle}
                    src={hitIDwhite}
                  />
                ),
              },
            ].map((text) => getMobileMenuListItem(openPage, text))}
          </List>
          <Divider />
          <List>
            <p
              style={{
                textAlign: "left",
                marginLeft: "1.2em",
                paddingTop: ".1em",
              }}
            >
              Capabilities
            </p>
            {[
              {
                url: "de-novo-drug-discovery",
                name: "De Novo Drug Discovery",
                icon: (
                  <img alt="De Novo" style={navBarIconStyle} src={deNovoIcon} />
                ),
              },
              {
                url: "bioactivity",
                name: "Bioactivity",
                icon: (
                  <img
                    alt="Bioactivity"
                    style={navBarIconStyle}
                    src={bioactivityIcon}
                  />
                ),
              },
              {
                url: "ADMET",
                name: "ADMET",
                icon: (
                  <img alt="ADMET" style={navBarIconStyle} src={admetIcon} />
                ),
              },
              {
                url: "knowledge-graph",
                name: "Knowledge Graph",
                icon: (
                  <img
                    alt="Knowledge Graph"
                    style={navBarIconStyle}
                    src={Data}
                  />
                ),
              },
            ].map((text) => (
              <ListItem
                className={
                  currentPath.includes(text.url) ? "selectedMenuItemMobile" : ""
                }
                onClick={() => openPage(text.url)}
                button
                key={text.name}
              >
                <ListItemIcon style={{ color: "white" }}>
                  {" "}
                  {text.icon}
                </ListItemIcon>
                <ListItemText primary={text.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            <p
              style={{
                textAlign: "left",
                marginLeft: "1.2em",
                paddingTop: ".1em",
                cursor: "pointer",
              }}
              onClick={() => openPage("services")}
            >
              Services
            </p>
            {[
              {
                url: "services",
                name: "Client Programs",
                pagePart: "clientProg",
                icon: (
                  <img
                    alt="Client Programs"
                    style={navBarIconStyle}
                    src={targetWhite}
                  />
                ),
              },
              {
                url: "services",
                name: "Drug Discovery Services",
                pagePart: "discovery",
                icon: (
                  <img
                    alt="Drug Discovery Services"
                    style={navBarIconStyle}
                    src={hitIDwhite}
                  />
                ),
              },
            ].map((text) => getMobileMenuListItem(openPage, text))}
          </List>
          <Divider />
          <List>
            <p
              style={{
                textAlign: "left",
                marginLeft: "1.2em",
                paddingTop: ".1em",
              }}
            >
              Company
            </p>
            {[
              {
                url: "the-ro5-team",
                name: "The Team",
                icon: (
                  <i
                    className="fas fa-users"
                    style={{
                      marginLeft: 12,
                      maxHeight: 50,
                      maxWidth: 50,
                      color: "white",
                    }}
                  />
                ),
              },
              {
                url: "the-ro5-advisory-board",
                name: "Advisory Board",
                icon: (
                  <i
                    className="fas fa-hands-helping"
                    style={{
                      marginLeft: 12,
                      maxHeight: 50,
                      maxWidth: 50,
                      color: "white",
                    }}
                  />
                ),
              },
              {
                url: "join-us",
                name: "Join Us",
                icon: (
                  <img
                    src={joinUsIcon}
                    alt="Join Us"
                    style={{
                      maxHeight: 25,
                      maxWidth: 25,
                      marginLeft: 12,
                      color: "white",
                    }}
                  />
                ),
              },
              {
                url: "contact-us",
                name: "Contact Us",
                icon: (
                  <i
                    className="fas fa-phone-alt"
                    style={{ maxWidth: 30, marginLeft: 15, color: "white", }}
                  />
                ),
              },
            ].map((val) => (
              <ListItem
                onClick={() => openPage(val.url)}
                button
                key={val.name}
                className={
                  currentPath.includes(val.url) ? "selectedMenuItemMobile" : ""
                }
              >
                <ListItemIcon>{val.icon}</ListItemIcon>
                <ListItemText primary={val.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            <p
              style={{
                textAlign: "left",
                marginLeft: "1.2em",
                paddingTop: ".1em",
              }}
            >
              News
            </p>
            {[{
                name: "News", url: "news", icon: (<i
                  style={{ maxHeight: 50, maxWidth: 30, marginLeft: 15, color: "white" }}
                  className="fas fa-newspaper"
                />)
              },
              {
                url: "publication-page",
                name: "Publications",
                icon: (
                  <img
                    alt="Publications"
                    style={{
                      maxHeight: 25,
                      maxWidth: 25,
                      marginLeft: 12,
                    }}
                    src={patentsIcon}
                  />
                ),
              },
              {
                url: "patent-page",
                name: "Patents",
                icon: (
                  <img
                    alt="Patents"
                    style={{
                      maxHeight: 25,
                      maxWidth: 25,
                      marginLeft: 12,
                    }}
                    src={patentsIcon}
                  />
                ),
              },
              {
                url: "case-studies",
                name: "Case Studies",
                icon: (
                  <img
                    alt="Case Studies"
                    style={{
                      maxHeight: 25,
                      maxWidth: 25,
                      marginLeft: 12,
                    }}
                    src={patentsIcon}
                  />
                ),
              },
            ].map((val) => (
              <ListItem
                onClick={() => openPage(val.url)}
                button
                key={val.name}
                className={
                  currentPath.includes(val.url) ? "selectedMenuItemMobile" : ""
                }
              >
                <ListItemIcon>{val.icon}</ListItemIcon>
                <ListItemText primary={val.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
        </div>
      </Drawer>
      <main
        className={clsx({
          flexGrow: 1,
          padding: theme.spacing(3),
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: -drawerWidth,
        }, {
          [{
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          }]: open,
        })}
      >
        <div className={{
          display: "flex",
          alignItems: "center",
          padding: theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
          justifyContent: "flex-end",
        }} />
      </main>
    </div>
  )
}

export default NavBarMobile
