import "styles/joinUs.scss";

const jobsData = [
  // {
  //   jobTitle: "Lead Machine Learning Scientist",
  //   branch: "AI Chemistry",
  //   jobDescription: (
  //     <>
  //       <div>
  //         <h4 style={{ marginBottom: "1vh" }}>
  //           <b>Lead Machine Learning Scientist</b>
  //         </h4>
  //         <div className="h4-extra" style={{ marginBottom: "4vh" }}>
  //           AI Chemistry
  //         </div>
  //         <p>
  //           We are looking for a candidate who is as driven as us in
  //           transforming drug discovery and can work at the intersection of
  //           machine learning, chemistry and product development. You are a
  //           machine learning scientist with a strong background in chemistry and
  //           drug discovery. You have designed novel deep learning models and are
  //           able to apply a ‘first principles approach’ when tackling new
  //           problems. You are curious and willing to acquire interdisciplinary
  //           skills. You are someone who can conduct independent research
  //           projects but thrives working in a multidisciplinary team where you
  //           share your experience, and learn from experts in machine learning,
  //           cheminformatics, bioinformatics, data science, statistics and
  //           software engineering.
  //         </p>
  //         <br></br>
  //         <p>
  //           You will be providing direction for the AI Chemistry team, where you
  //           will use your experience in cheminformatics and machine learning.
  //           You will be responsible for researching and implementing
  //           state-of-the-art methods for creating novel chemical matter,
  //           conducting analyses, and using your knowledge of chemistry to
  //           interpret the results. You will be combining standard approaches in
  //           drug design, such as pharmacophore screening, QSAR modeling and
  //           physics-based simulations (e.g. molecular dynamics, docking) with
  //           novel ML-based approaches. You will be providing guidance to the ML
  //           staff for the development of next-generation models for drug
  //           discovery and design. You will be working closely with the software
  //           engineers and data scientists, as well as the web app development
  //           team to productionize the pipelines you have created.
  //         </p>
  //         <br></br>
  //         <p>
  //           The dynamic environment that you will join at Ro5 will reward
  //           curiosity and present many opportunities to grow as a researcher,
  //           developer and leader. Driven, talented and motivated individuals
  //           will be able to influence the development of our platform and take
  //           ownership of its key parts.
  //         </p>
  //       </div>
  //       <div className="list-container">
  //         <div className="h4-extra">
  //           <b>Primary responsibilities:</b>
  //         </div>
  //         <p>
  //           <ul className="list-style">
  //             <li>Lead the R&D of Ro5’s AI Chemistry team</li>
  //             <li>
  //               Lead the drug discovery projects in which the company engages by
  //               working closely with medicinal and computational chemists, and
  //               the rest of the project team.
  //             </li>
  //             <li>Provide strategic direction to the AI Chemistry team.</li>
  //             <li>
  //               Develop next-generation cheminformatics solutions to address
  //               standing challenges in drug discovery.
  //             </li>
  //           </ul>
  //         </p>
  //       </div>
  //       <div className="list-container">
  //         <div className="h4-extra">
  //           <b>Additional responsibilities:</b>
  //         </div>
  //         <p>
  //           <ul className="list-style">
  //             <li>
  //               Reporting and presentation of project results to our clients and
  //               external stakeholders.
  //             </li>
  //             <li>Management of external collaborations.</li>
  //             <li>
  //               Management of scientific outputs, such as publications and
  //               conference presentations.
  //             </li>
  //           </ul>
  //         </p>
  //       </div>
  //       <div className="list-container">
  //         <div className="h4-extra">
  //           <b>We are looking for someone with:</b>
  //         </div>
  //         <p>
  //           <ul className="list-style">
  //             <li>
  //               A PhD in cheminformatics, machine learning, or related field,
  //               with a strong background in chemistry.
  //             </li>
  //             <li>
  //               At least one first-author publication or equivalent in the
  //               fields of cheminformatics and/or machine learning.
  //             </li>
  //             <li>
  //               Experience applying Deep Learning techniques to Chemistry.
  //             </li>
  //             <li>Experience working with PyTorch, Cuda etc.</li>
  //             <li>
  //               Collaborative, team-oriented, and able to thrive in a
  //               multidisciplinary environment.
  //             </li>
  //           </ul>
  //         </p>
  //       </div>
  //       <div className="list-container">
  //         <div className="h4-extra">
  //           <b>Nice to haves...</b>
  //         </div>
  //         <p>
  //           <ul className="list-style">
  //             <li>
  //               2 or more years of experience applying Deep Learning to
  //               Chemistry.
  //             </li>
  //             <li>
  //               2 or more years working in drug discovery within the
  //               Pharmaceutical industry.
  //             </li>
  //             <li>
  //               Good understanding of the drug discovery process: Hit
  //               Identification, Hit-to-Lead, Lead Optimization, etc.
  //             </li>
  //             <li>Professional software development experience.</li>
  //             <li>Agile work practices.</li>
  //           </ul>
  //         </p>
  //       </div>
  //       <br />
  //       <b>
  //         Please contact Ro5 Careers with any queries concerning this position:
  //       </b>
  //       <br />
  //       <b>careers@ro5.ai</b>
  //     </>
  //   ),
  // },
  // {
  //   jobTitle: "Lead Cheminformatics Scientist",
  //   branch: "AI Chemistry",
  //   jobDescription: (
  //     <>
  //       <div>
  //         <h4 style={{ marginBottom: "1vh" }}>
  //           <b>Lead Cheminformatics Scientist</b>
  //         </h4>
  //         <div className="h4-extra" style={{ marginBottom: "4vh" }}>
  //           AI Chemistry
  //         </div>
  //         <div style={{ fontSize: "2.5rem", marginBottom: "2vh" }}>
  //           The Company
  //         </div>
  //         <p>
  //           Ro5 is a drug discovery, analytics, and research company with a
  //           mission to accelerate drug discovery, augment drug development, and
  //           boost clinical trial success using AI. Ro5 has developed
  //           state-of-the-art cheminformatics, biomedical knowledge graph and
  //           clinical trial analytics platforms to deliver value to pharma and
  //           biotech clients, as well as academic research groups. The company
  //           has bases in both Europe (London) and the US (Dallas), with most of
  //           the team working remotely and opportunities to join co-working
  //           spaces in Barcelona, London and Vilnius, as well as ‘workations’
  //           across the globe, which are organized every quarter.
  //         </p>
  //         <br></br>
  //         <div style={{ fontSize: "2rem", marginBottom: "2vh" }}>
  //           Who we are
  //         </div>
  //         <p>
  //           The Ro5 team consists of a diverse, multicultural group of machine
  //           learning scientists, data scientists, pharmacologists, chemists,
  //           bioinformaticians and software engineers from around the world. We
  //           believe that originality requires incorporating different views and
  //           perspectives. At Ro5, we strive to:
  //         </p>
  //         <ul className="list-style">
  //           <li style={{ marginBottom: "0.8vh" }}>
  //             <span style={{ fontStyle: "italic" }}>Keep it human</span> - Our
  //             ultimate goal is to reduce suffering and illness - the patients
  //             are at the heart of everything we do. We accelerate drug discovery
  //             to get life-saving drugs to these patients faster, because disease
  //             doesn’t wait.
  //           </li>
  //           <li style={{ marginBottom: "0.8vh" }}>
  //             <span style={{ fontStyle: "italic" }}>Learn from everything</span>{" "}
  //             - In the world of experiments, failure is an important teacher.
  //             The only failure is the failure to try.
  //           </li>
  //           <li style={{ marginBottom: "0.8vh" }}>
  //             <span style={{ fontStyle: "italic" }}>
  //               Respect the unexpected
  //             </span>{" "}
  //             - “Plans are useless. Planning is everything.” The challenge is
  //             both to plan and to stay open to the unplanned, often the best
  //             opportunities of all.
  //           </li>
  //         </ul>
  //         <br></br>
  //         <div style={{ fontSize: "2.5rem", marginBottom: "2vh" }}>
  //           The Role
  //         </div>
  //         <p>
  //           We are looking for a candidate who is as driven as us in
  //           transforming drug discovery and can work at the intersection of
  //           machine learning, chemistry, and product development. You are a
  //           cheminformatics scientist with a strong background in computational
  //           methods applied in chemistry and drug discovery. Moreover, you have
  //           worked on small molecule drug discovery and have experience across
  //           the different phases of the pharmaceutical pipeline. You are curious
  //           and willing to acquire interdisciplinary skills; someone who can
  //           conduct independent research projects but thrives working in a
  //           multidisciplinary team where you share your experience, and learn
  //           from experts in machine learning, cheminformatics, bioinformatics,
  //           data science, statistics and software engineering.
  //         </p>
  //         <br></br>
  //         <p>
  //           You will use your experience in cheminformatics and drug discovery
  //           to provide direction to Ro5’s AI Chemistry team.. Furthermore, you
  //           will be responsible for researching and implementing
  //           state-of-the-art methods for virtual high throughput screening, de
  //           novo drug discovery, conducting cheminformatics analyses, and using
  //           your knowledge of chemistry to interpret the results. You will be
  //           combining standard approaches in drug design, such as pharmacophore
  //           screening, QSAR modeling and physics-based simulations (e.g.
  //           molecular dynamics, docking, free energy perturbation) with novel
  //           ML-based approaches. You will be formulating and delegating tasks to
  //           the ML staff for the continued development of next-generation models
  //           for drug discovery and design. In addition, you will be working
  //           closely with software engineers and data scientists, as well as the
  //           platform development team to productionize the pipelines you have
  //           created.
  //         </p>
  //         <br></br>
  //         <p>
  //           The dynamic environment that you will join at Ro5 rewards curiosity
  //           and presents many opportunities to grow as a researcher, developer
  //           and leader. Driven, talented and motivated individuals will be able
  //           to influence the development of our platform and take ownership of
  //           its key parts.
  //         </p>
  //       </div>
  //       <div className="list-container">
  //         <div className="h4-extra">
  //           <b>Primary responsibilities:</b>
  //         </div>
  //         <p>
  //           <ul className="list-style">
  //             <li>Lead the R&D of Ro5’s AI Chemistry team.</li>
  //             <li>
  //               Lead the drug discovery projects that the company is engaged in
  //               by working closely with medicinal and computational chemists,
  //               and the rest of the project team.
  //             </li>
  //             <li>
  //               Provide strategic direction to the AI Chemistry team and manage
  //               daily task assignments.
  //             </li>
  //             <li>
  //               Develop next-generation cheminformatics solutions to address
  //               standing challenges in drug discovery.
  //             </li>
  //           </ul>
  //         </p>
  //       </div>
  //       <div className="list-container">
  //         <div className="h4-extra">
  //           <b>Additional responsibilities:</b>
  //         </div>
  //         <p>
  //           <ul className="list-style">
  //             <li>
  //               Reporting and presentation of project results to our clients and
  //               external stakeholders.
  //             </li>
  //             <li>Management of external collaborations.</li>
  //             <li>
  //               Management of scientific outputs, such as publications and
  //               conference presentations.
  //             </li>
  //           </ul>
  //         </p>
  //       </div>
  //       <div className="list-container">
  //         <div className="h4-extra">
  //           <b>We are looking for someone with:</b>
  //         </div>
  //         <p>
  //           <ul className="list-style">
  //             <li>A PhD in cheminformatics or computational chemistry.</li>
  //             <li>
  //               At least one first-author publication or equivalent in the
  //               fields of cheminformatics and/or machine learning.
  //             </li>
  //             <li>Experience in small molecule drug discovery.</li>
  //             <li>
  //               A collaborative and team-oriented spirit, who is able to thrive
  //               in a multidisciplinary environment.
  //             </li>
  //           </ul>
  //         </p>
  //       </div>
  //       <div className="list-container">
  //         <div className="h4-extra">
  //           <b>Nice to haves...</b>
  //         </div>
  //         <p>
  //           <ul className="list-style">
  //             <li>2 or more years experience in cheminformatics.</li>
  //             <li>Experience with machine learning.</li>
  //             <li>
  //               2 or more years working in drug discovery within the
  //               pharmaceutical industry.
  //             </li>
  //             <li>
  //               Good understanding of the drug discovery process: hit
  //               identification, hit-to-lead, lead optimization, etc.
  //             </li>
  //             <li>Professional software development experience.</li>
  //             <li>Agile work practices.</li>
  //           </ul>
  //         </p>
  //       </div>
  //       <br />
  //       <b>
  //         Please contact Ro5 Careers with any queries concerning this position:
  //       </b>
  //       <br />
  //       <b>careers@ro5.ai</b>
  //     </>
  //   ),
  // },
];

export default jobsData;
