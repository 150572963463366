import "styles/patents.scss";
import React from "react";
import { Link } from "@mui/material"
import posterPdf from "res/assets/Infographics/ro5_strateos_poster_v4.pdf";
import hitsDiscoveredComparisonImage from "res/assets/Infographics/hits_discovered_comparison.png";
import posterThumbnail from "res/assets/Infographics/ro5_strateos_poster_v4_96_thumbnail.png";
import hydrascreenPerformanceImage from "res/assets/Infographics/HydraScreen_performance.png";
import drugCandiadatesForCovid19Thumbnail from "res/assets/Infographics/drug_candidates_for_covid19.png";

const PUBLICATION_DATA = [
  {
    name: "Preprint: Accelerated Hit Identification with Target Evaluation, Deep Learning and Automated Labs: Prospective Validation in IRAK1",
    number: <Link href="https://doi.org/10.26434/chemrxiv-2023-mh22x-v4" target="_blank" rel="noreferrer">10.26434/chemrxiv-2023-mh22x-v4</Link>,
    date: "26.09.2023",
    picture: hitsDiscoveredComparisonImage,
    description: (
      <>
        In this study, we synergistically integrate Ro5's target evaluation (SpectraView) and deep-learning-driven virtual screening (HydraScreen) tools with Strateos automated robotic cloud lab optimized for ultra high-throughput screening, to experimentally validate Ro5's tools. This integrated approach leads to a significant acceleration in the processes of target identification and hit discovery. By using SpectraView to select IRAK1 as the focal point of our investigation, we prospectively validate the HydraScreen structure-based deep learning model. We can achieve the identification of an 23.8% of all IRAK1 hits within the top 1% of ranked compounds. HydraScreen also outperforms traditional virtual screening techniques and offers advanced features such as ligand pose confidence scoring. Simultaneously, we identify three potent (nanomolar) scaffolds from our compound library, two of which represent novel candidates for IRAK1 and hold potential for future development. Our platforms and innovative tools promise to expedite the early stages of drug discovery.
      </>
    ),
  },
  {
    name: "Poster: Accelerated Hit Identification with Target Evaluation, Deep Learning and Automated Labs: Prospective Validation in IRAK1",
      number: <Link href={posterPdf} target="_blank" rel="noreferrer">Download</Link>,
    date: "26.09.2023",
    picture: posterThumbnail,
    description: (
      <>
        This poster presents a study in which Ro5’s researchers combined the SpectraView tool for target evaluation and the HydraScreen virtual screening platform with Strateos' automated robotic cloud lab designed for high-speed screening. This integrated approach significantly sped up the processes of selecting the most promising target and identifying potential hits. By using SpectraView to choose IRAK1, we have validated the effectiveness of HydraScreen's deep learning model. We managed to identify nearly 24% of all IRAK1 hits in the top 1% of ranked compounds. HydraScreen also performed better than traditional virtual screening methods and provided confidence scores for the suggested drug poses. Moreover, the study identified three promising scaffolds, two of which are new candidates for IRAK1 and could be developed further.
      </>
    ),
  },
  {
    name: "Preprint: HydraScreen: A Generalizable Structure-Based Deep Learning Approach to Drug Discovery",
      number: <Link href="https://doi.org/10.26434/chemrxiv-2023-gwm1s-v2" target="_blank" rel="noreferrer">10.26434/chemrxiv-2023-gwm1s-v2</Link>,
    date: "06.10.2023",
    picture: hydrascreenPerformanceImage,
    description: (
      <>
        We propose HydraScreen, a deep-learning approach that aims to provide a framework for more robust machine-learning-accelerated drug discovery. HydraScreen utilizes a state-of-the-art 3D convolutional neural network, designed for the effective representation of molecular structures and interactions in protein-ligand binding. We design an end-to-end pipeline for high-throughput screening and lead optimization, targeting applications in structure-based drug design. We assess our approach using established public benchmarks based on the CASF 2016 core set, achieving top-tier results in affinity and pose prediction (Pearson's r = 0.86, RMSE = 1.15, Top-1 = 0.95). Furthermore, we present a novel interaction profiling approach to identify potential biases in the model and dataset to boost interpretability and support the unbiased nature of our method. Finally, we showcase HydraScreen's capacity to generalize across unseen proteins and ligands, offering directions for future development of robust machine learning scoring functions. HydraScreen, accessible at https://hydrascreen.ro5.ai, provides a user-friendly GUI and a public API, facilitating easy assessment of individual protein–ligand complexes.
      </>
    ),
  },
  {
    name: "Preprint: Ro5 Bioactivity Lab: Identification of Drug Candidates for COVID-19",
      number: <Link href="https://doi.org/10.26434/chemrxiv.12275741.v1" target="_blank" rel="noreferrer">10.26434/chemrxiv.12275741.v1</Link>,
    date: "12.05.2020",
    picture: drugCandiadatesForCovid19Thumbnail,
    description: (
      <>
        The public health emergency known as the coronavirus disease 2019 (COVID-19), caused by the severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2), has led to a large number of deaths worldwide and major socioeconomic disruption. To date, no broadly effective antiviral treatment or vaccine has been developed for COVID-19. In response to this dire situation, Ro5 deployed its AI Lab to accelerate the search for potential treatments. This report focuses on our use of the Ro5 Bioactivity model, which has been designed to predict the inhibitory activity of small molecules against protein targets. The model screened a vast range of compounds in silico to uncover potential inhibitors of the SARS-CoV-2 3CL protease. We hereby present the most propitious candidates from this screen. The highest-ranking molecules include Nelfinavir, Saquinavir, Itacitinib, Kynostatin-272, BOG-INS-6c2-1, and BEN-VAN-d2b-11. Subsequent docking simulations corroborate their plausibility as 3CLpro inhibitors. Nelfinavir and Itacitinib hold the most promise for drug repurposing, among all the molecules proposed herein, due to their high predicted inhibition and affinity against the 3CL protease, favourable pharmacokinetics, and encouraging experimental data for treating viral replication and hyperinflammation, respectively.
      </>
    ),
  },
];

export default PUBLICATION_DATA;
