import React from "react";
import calendarIcon from "res/assets/Icons/Utility/calendar-blue.png";

function PublicationWidget(props) {
  const {
    name,
    number,
    description,
    picture,
    date,
    setModalOpen,
    setCurrentPatent,
    noName,
  } = props;

  function handlePatentClick() {
    setCurrentPatent({
      name,
      number,
      description,
      picture,
      date,
      noName,
    });
    setModalOpen(true);
  }

  return (
    <div className="patentWidget" onClick={() => handlePatentClick()}>
      <img src={picture} alt="Patent" className="patentPicture" />
      <div
        style={{ fontSize: "1.7rem", fontWeight: "500", marginBottom: "3vh" }}
      >
        {name}
      </div>
      <div style={{ width: "95%", position: "absolute", bottom: 0 }}>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "0.8vh",
          }}
        >
          {number && <div style={{fontSize: "1.2rem"}}>{number}</div>}
          {date && (
            <div style={{ display: "flex" }}>
              <img
                src={calendarIcon}
                className="calendarIcon"
                alt="Calendar Icon"
              />
              <div style={{ marginLeft: "0.5vw", fontSize: "1.2rem" }}>
                {date}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PublicationWidget;
