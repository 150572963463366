import React, { useEffect } from "react";
import WhiteSection from "./WhiteSection";

import "styles/services.scss";
import { PageTemplate } from "../PageTemplate"

function HitIdentification() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageTemplate>
      <div
        className="historyHeader"
        style={{
          position: "relative",
          marginBottom: 0,
        }}
      >
        <center className="serviceSectionTitle">
          <div style={{ width: "90%" }}>Hit Identification</div>
        </center>
        <center className="servicesStory">
          Use Ro5 tools to rank your internal libraries or purchasable compounds
          using state of the art methods. We use an ensemble of techniques to
          provide a transparent ranking, and our Knowledge Graph provides
          additional context on a compound’s IP status, toxicity, and synthesis.
        </center>
        <div className="bg-molecule" style={{ width: "30%" }} />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </div>
      <div className="smallPageSection">
        <WhiteSection
          benefitList={[
            "Reduce the cost and time of hit identification.",
            "Derisk the entire drug discovery pipeline by identifying unfavorable compounds early on.",
            "Evaluate the IP status of your compound library.",
          ]}
          introParagraph={null}
          smallParagraphs={[
            {
              title: "Compound prioritization",
              content:
                "Prioritize the compound library based on the predictions from state of the art machine learning models based on 3D representations and insights from the Knowledge Graph.",
            },
            {
              title: "Risk flagging",
              content:
                "Understand the toxicological risks and potential off target effects of your candidates early on.",
            },
            {
              title: "IP status evaluation",
              content: "Flag patented chemical space to avoid infringement.",
            },
          ]}
        />
      </div>
    </PageTemplate>
  );
}

export default HitIdentification;
