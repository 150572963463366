import React, { useEffect } from "react";
import "styles/history.scss";
import PlatformComponent from "Pages/platforms/PlatformComponent";
import image from "res/assets/Images/NewsArticles/Ro5StrateosTeam_BIG.png";
import slide2 from "res/assets/Images/NewsArticles/teamUpSlide2.png";
import slide3 from "res/assets/Images/NewsArticles/teamUpSlide3.png";
import { CASE_STUDIES_DATA } from "../CaseStudiesData";


function Ro5StrateosTeamUp() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Case Studies - ${CASE_STUDIES_DATA.RO5_STRATEOS_TEAM_UP.title}`
  }, []);

  const storyStyle = {
    width: "45%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: "67.5px",
    fontFamily: "rubikRegular",
  };
  return (
    <>
      <center
        className="historyHeader"
        style={{ position: "relative", marginBottom: "1.5em" }}
      >
        <div style={storyStyle}>Ro5 Case Studies</div>
        <div className="story">01.09.2022</div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="newMemberPage">
        <center>
          <img src={image} className="eventMobilePhoto" alt="event" />
          <p className="mobileEventImageCaption">
            Roy (Ro5) and Dan (Strateos) presented the results from the
            collaboration on the 5th of July during the Pharma IQ event.
          </p>
        </center>
        <div className="eventPageTop">
          <div className="newMemberArticle">
            <PlatformComponent
              title={CASE_STUDIES_DATA.RO5_STRATEOS_TEAM_UP.title}
              desc={
                <>
                  In July, Ro5 Cofounder and Principal Scientist Roy Tal and
                  Strateos’ VP of Technology Enablement Services Dan Rines
                  presented preliminary results on the fusion of these
                  companies’ ground-breaking technologies.
                  <br />
                  <br />
                  Ro5 offers AI-driven solutions for target identification, hit
                  identification, hit-to-lead, lead optimization and clinical
                  trial analytics, while Strateos occupies the wet-lab niche of
                  automated, cloud-based robotic labs. Strateos and Ro5 have
                  built a closed loop, automated design-make-test-analyze (DMTA)
                  system that takes advantage of Strateos’s Cloud Lab
                  Automation-as-a-Service and Ro5’s Knowledge Graph and AI
                  Chemistry platforms.
                  <br />
                  <br />
                  It enables fast progression from target identification to lead
                  optimization and allows scientists to evaluate targets,
                  efficiently identify hit compounds, and rapidly design
                  promising drug candidates.
                  <br />
                  <br />
                  In July, lead scientists presented a preliminary study
                  utilizing this collaborative system to identify a prospective
                  oncology target and initiate a drug discovery program. The
                  results show that the automated AI-driven DMTA workflow can
                  rapidly identify the majority of hits with only 10% library
                  screened and a diverse set of scaffolds with only 1% of the
                  library screened for subsequent lead optimization.
                  <br />
                  <br />
                  This study is a prospective validation of the Ro5-Strateos
                  combined platform for automated early drug discovery.
                  Strateos’ automated labs provide clean, reliable, AI-ready
                  data for validation, model fine-tuning and hypothesis
                  refinement, while Ro5’s proprietary AI Chemistry models
                  provide strong predictive power to reliably identify hit
                  compounds.
                  <br />
                  <br />
                  Using data science techniques to digitize and automate the
                  drug discovery process saves decades and billions of research
                  dollars. AI can rapidly identify hit compounds with
                  substantially fewer resources, and in combination with robotic
                  cloud labs, this facilitates a ‘fast fail’ approach-
                  eliminating poor candidates much more efficiently and zeroing
                  in on promising hits.
                  <br />
                  <br />
                  This study demonstrated the value of integrating Ro5’s AI
                  Chemistry Platform with Strateos’ Robotic Cloud Labs, and is
                  the first step towards continuing to refine this collaboration
                  for improved automated drug discovery pipelines.
                </>
              }
            />
          </div>
          <div className="eventImageList">
            <div>
              <img src={image} className="eventPhoto" alt="event poster" />
              <p className="eventImageCaption">
                Roy (Ro5) and Dan (Strateos) presented the results from the
                collaboration on the 5th of July during the Pharma IQ event.
              </p>
            </div>
            {/* <div>
                <img src={slide1} className="eventPhoto" alt="slide 1" />
            </div> */}
            <div>
              <img src={slide2} className="eventPhoto" alt="slide 2" />
              <p className="eventImageCaption">
                Ro5's AI technology was used with Strateos’ robotics labs to
                identify 18% of all active compounds by screening 1% of the
                library.
              </p>
            </div>
            <div>
              <img src={slide3} className="eventPhoto" alt="slide 3" />
              <p className="eventImageCaption">
                By combining Ro5's AI technology with Robotic labs we accelerate
                and derisk the drug discovery process starting with hit
                identification.
              </p>
            </div>
          </div>
        </div>
        <center>
          <div className="eventMobileBottomPictures">
            <img src={slide2} className="eventMobilePhoto" alt="slide 2" />
            <p className="mobileEventImageCaption">
              Ro5's AI technology was used with Strateos’ robotics labs to
              identify 18% of all active compounds by screening 1% of the
              library.
            </p>
            <img src={slide3} className="eventMobilePhoto" alt="slide 3" />
            <p className="mobileEventImageCaption">
              By combining Ro5's AI technology with Robotic labs we accelerate
              and derisk the drug discovery process starting with hit
              identification.
            </p>
          </div>
        </center>
      </div>
    </>
  );
}

export default Ro5StrateosTeamUp;
