import React from "react";
import { useNavigate } from "react-router-dom"
import FeatureComponent from "./FeatureComponent";
import targetWhite from "res/assets/Images/Services/target_white_low.png";
import hitIDwhite from "res/assets/Images/Services/molecule_white_low.png";
import hitToLeadWhite from "res/assets/Images/Services/moleculeTube_white_low.png";
import custom from "res/assets/Images/Services/custom_color_low.png";

import "styles/services.scss";

function BlueBg(props) {
  const navigate = useNavigate();
  const {
    sectionName,
    introParagraph,
    noBottomMargin = false,
    noTopMargin = false,
    targetEval = false,
    hitIden = false,
    customP = false,
    hitLeadOpt = false,
    cta = false,
    bottomTitle,
    bottomList,
  } = props;

  const styleOfZone =
    noBottomMargin && noTopMargin
      ? {
          position: "relative",
          paddingTop: 100,
          paddingBottom: 5,
          width: "100%",
        }
      : noBottomMargin && !noTopMargin
      ? {
          position: "relative",
          paddingTop: 100,
          paddingBottom: 5,
          marginTop: "4vh",
          width: "100%",
        }
      : !noBottomMargin && noTopMargin
      ? {
          position: "relative",
          paddingTop: 100,
          paddingBottom: 5,
          width: "100%",
        }
      : {
          position: "relative",
          paddingTop: 130,
          paddingBottom: 5,
          marginTop: "4vh",
          width: "100%",
        };

  return (
    <>
      <div style={styleOfZone} className="blueBg">
        <div className="blueBgFlex">
          <div style={{ width: "65%"}}>
            <center className="blueBgSectionName">{sectionName}</center>
            <div className="blueBgIntroText">{introParagraph}</div>
          </div>
          <div className="featureComponentList">
            {targetEval ? (
              <div
                onClick={() => navigate("/services/target-eval-and-prio")}
              >
                <FeatureComponent
                  icon={targetWhite}
                  title="Target Evaluation and Prioritization"
                  white={true}
                />
              </div>
            ) : (
              <></>
            )}
            {hitIden ? (
              <div onClick={() => navigate("/services/hit-identification")}>
                <FeatureComponent
                  icon={hitIDwhite}
                  title="Hit Identification"
                  white={true}
                />
              </div>
            ) : (
              <></>
            )}
            {hitLeadOpt ? (
              <div
                onClick={() =>
                  navigate("/services/hit-to-lead-and-lead-optimization")
                }
              >
                <FeatureComponent
                  icon={hitToLeadWhite}
                  title="Hit to Lead and Lead Optimization"
                  white={true}
                />
              </div>
            ) : (
              <></>
            )}
            {customP ? (
              <div onClick={() => navigate("/contact-us")}>
                <FeatureComponent icon={custom} title="Custom Programs" />
              </div>
            ) : (
              <></>
            )}
            {cta ? (
              <div onClick={() => navigate("/services/clinical-trial-programs")}>
                <FeatureComponent icon={custom} title={null} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {bottomTitle !== undefined || bottomList !== undefined ? (
          <center>
            <div className="paraTitleWhite">{bottomTitle}</div>
            <div className="blueBgBottomList">
              {bottomList.map((entry) => (
                <div style={{marginTop: "1vh"}}>{entry}</div>
              ))}
            </div>
          </center>
        ) : (
          <></>
        )}
        <div/>
        <div className="bg-core-polygon" />
        <div className="bg-core-molecule" />
      </div>
    </>
  );
}

export default BlueBg;
