import React from "react";
import ServicesWidget from "./ServicesWidget";
import SmallParagraph from "./SmallParagraph";

import "styles/services.scss";

function WhiteSection(props) {
  const { benefitList, introParagraph, smallParagraphs } = props;

  return (
    <>
      <div className="whiteBgFlex">
        <div style={{ flex: 0.6 }} />
        <div style={{ flex: 1.2 }}>
          <div className="">
            <div className="blueBgIntroText">{introParagraph}</div>
            {smallParagraphs.map((element, index) => (
              <SmallParagraph
                key={index}
                title={element.title}
                content={element.content}
                white={false}
              />
            ))}
          </div>
        </div>
        <div style={{ flex: 0.3 }} />
        <div style={{ flex: 1 }}>
          <ServicesWidget
            listName={"Benefits"}
            isWhite={false}
            list={benefitList}
          />
        </div>
        <div style={{ flex: 0.6 }} />
      </div>
      <div className="whiteBgFlexMobile">
        <div>
          <div className="blueBgIntroText">{introParagraph}</div>
          {smallParagraphs.map((element, index) => (
            <SmallParagraph
              key={index}
              title={element.title}
              content={element.content}
              white={false}
            />
          ))}
        </div>
        <div className="mobileServicesWidget">
          <ServicesWidget
            listName={"Benefits"}
            isWhite={false}
            list={benefitList}
          />
        </div>
      </div>
    </>
  );
}

export default WhiteSection;
