/**
 * Component containing the header for the landing page
 * @author Amit Setty
 */
import React from "react";

function LandingPageHeader(props) {
    const protein = props.images && props.images[0];

    return (
        <div className="foreground">
            <center>
                <div className="inner header">
                    <div className="polygon"></div>
                    <div className="landingpageContainer">
                        <div style={{ flex: 0.1 }} />
                        <div
                            className="moleculeImage"
                            style={{ flex: 0.85, paddingBottom: 0 }}
                        >
                            <div className="plane-3d-1" />
                            <div className="plane-3d-2" />
                            <img style={{ maxWidth: "100%" }} src={protein} alt="protein" />
                        </div>
                        <div style={{ flex: 0.95 }}>
                            <div>
                                <div className="homePageHeadline">
                                    <h1 style={{ fontSize: "2.3vw", paddingBottom: ".5em" }}>
                                        Accessible AI for <i>all</i> drug discovery researchers.
                                    </h1>
                                    <h4>
                                        The AI revolution is here, but the digital infrastructure
                                        that biopharma companies need is not. At Ro5
                                        <sup style={{ fontSize: "50%", top: "-1em" }}>®</sup>, we
                                        provide the benefits of AI and digital transformation
                                        through a software platform that empowers biopharma
                                        companies to focus on what they’re best at - drug discovery.
                                        <br />
                                        <br />
                                        <i>Because disease doesn’t wait.</i>
                                    </h4>
                                    <div style={{ paddingTop: "1.7em" }} />
                                    <div style={{ display: "inline-block" }}></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 0.05 }} />
                    </div>
                </div>
            </center>
        </div>
    );
}

export default LandingPageHeader;
