import React, {useState, useEffect, useRef} from "react";
import BlueBg from "./BlueBg";
import moleculeBG from "res/assets/Background/moleculeRotated.svg";
import { useLocation } from "react-router-dom";

import "styles/services.scss";
import { PageTemplate } from "../PageTemplate"
import ClientPrograms from "../ClientPrograms/ClientPrograms"

function Services() {
  const location = useLocation();
  const [loaded, setLoaded] = useState(null);
  const clientProg = useRef(null);
  const drugDisc = useRef(null);

  useEffect(() => {document.title = "Services"}, [])

  useEffect(() => {
    if (location.state == null || location.state.pagePart == null) {
      return;
    }
    if (
      clientProg.current &&
      drugDisc.current
    ) {
      switch (location.state.pagePart) {
        case "clientProg":
          window.scrollTo({
            top: clientProg.current.offsetParent.offsetTop + clientProg.current.offsetTop,
            behavior: "smooth"
          })
          break;
        case "discovery":
          window.scrollTo({
            top: drugDisc.current.offsetParent.offsetTop + drugDisc.current.offsetTop,
            behavior: "smooth"
          })
          break;
        default:
          break;
      }
    }
  }, [loaded, location]);

  return (
    <PageTemplate>
      <center
        className="historyHeader"
        style={{
          position: "relative",
          marginBottom: 0,
        }}
      >
        <div className="serviceSectionTitleLanding">Professional Services</div>
        <div className="servicesStory">
          Ro5 is focused on providing pharma, biotech, and academic institutions
          with advanced AI, data science, and clinical trial analytics
          capabilities. Services are provided as individualized tools for drug
          discovery and clinical trial programs, or as comprehensive processes
          for the entire drug pipeline, complemented with a consulting service
          by Ro5 scientists.
        </div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="servicesHolder">
        <div style={{ position: "relative" }}>
          <div style={{ paddingBottom: "1.5em" }} />
          <div className="allServicesList">
            <div onLoad={() => setLoaded(true)} ref={clientProg}/>
            <ClientPrograms />
            <div onLoad={() => setLoaded(true)} ref={drugDisc}/>
            <BlueBg
              sectionName={"Drug Discovery Programs"}
              introParagraph="Ro5 provides support for each individual stage of the drug discovery process for pharma, 
              biotech, and academic institutions with Ro5’s AI platforms. Namely, Ro5 supports the following 
              phases of the drug discovery pipeline:"
              targetEval={true}
              hitIden={true}
              hitLeadOpt={true}
              noTopMargin={true}
            />
          </div>
          <div
            className="bg-molecule-2"
            style={{ backgroundImage: `url(${moleculeBG})` }}
          />
        </div>
      </div>
    </PageTemplate>
  );
}

export default Services;
