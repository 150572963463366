/**
 * This navigation component which implements the desktop and mobile versions
 * of navigation
 *
 * @author Amit Setty
 */
import React from "react";
import NavBar from "./DesktopNavBar";
import NavBarMobile from "./NavBarMobile";
import { useNavigate } from "react-router-dom"
import LogoSvg from "../../logo.svg";

import "styles/header.scss";

function NavBarWithLogo() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        paddingTop: "0em",
        position: "absolute",
        zIndex: 999,
        width: "100%",
        display: "flex",
        maxHeight: "7.5em",
      }}
    >
      <div style={{ flex: 0.2 }} />
      <div onClick={() => navigate("/")} style={{ flex: 0.6 }}>
        <div style={{ maxHeight: "inherit" }}>
          <img
            className="logo"
            src={LogoSvg}
            onClick={() => navigate("/")}
            style={{
              minWidth: 100,
              maxHeight: "5.5em",
              paddingLeft: "2px",
              paddingTop: "1.5em",
              paddingBottom: "1.5em",
              maxWidth: "65%",
            }}
            alt="Ro5"
          />
        </div>
      </div>
      <center style={{ flex: 1.7 }}>
        <div style={{ float: "right", marginRight: 80, marginTop: 10 }}>
            <div className="desktop" style={{width:"65vw"}}>
              <NavBar />
            </div>

          <div className="tablet">
            <NavBarMobile />
          </div>

          <div className="mobile">
            <NavBarMobile />
          </div>
        </div>
      </center>
    </div>
  );
}
export default NavBarWithLogo;
