/**
 * Component for the Clinical Trials Ro5 platforms.
 *  Contains a header and body using the plattform components.
 * @author Amit Setty
 */
import React from "react";
import ClinicalTrialsInfographic from "res/assets/Infographics/ClinicalTrials.svg";
import PlatformHeader from "./PlatformHeader";
import PlatformComponent from "./PlatformComponent";
import ctData from "res/assets/Images/ClinicalTrials/CT-Data.png";
import ServicesWidget from "Pages/Services/ServicesWidget";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import macbookAir from "../../res/assets/Images/Macbook/Macbook_Air.png";
import { PageTemplate } from "../PageTemplate"

const benefits = [
  "Design and plan future clinical trials",
  "Use auxiliary data to predict patient risks",
  "Seamlessly integrate and harmonize clinical trial data",
  "Improve patient outcomes and prevent serious adverse events",
  "Learn the context of biomarkers, compounds and disease targets",
  "Save costs in clinical trials and time needed to discover and develop new drugs",
];

function ClinicalTrials() {
  const settings = {
    dots: true,
    appendDots: (dots) => <div id="macbookSlickDots">{dots}</div>,
    // nextArrow: <NextArrow />,
    // previousArrow: <PreviousArrow />,
    lazyLoad: false,

    afterChange: function (index) {
    },
  };
  
  const settingsMobile = {
    dots: true,
    appendDots: (dots) => <div>{dots}</div>,
    arrows: false,
    lazyLoad: false,

    afterChange: function (index) {
    },
  };

  return (
    <PageTemplate>
      <PlatformHeader
        title="Clinical Trial Analytics"
        infographic={ClinicalTrialsInfographic}
        desc="One toolset helps predict and prevent adverse events, derisk clinical trials, and improve patient outcomes.
          Clinical trials represent the largest risk, cost and time — commitment in all aspects of drug development — and our clinical trial analytics platform reduces all three.
          Ro5’s toolset helps predict and prevent adverse events, and ultimately improves the patient outcome."
      />

      {/* Page Content */}
      <div className="desktopFlex">
        <div style={{ flex: 1 }} />
        <div style={{ flex: 2.5 }}>
          <PlatformComponent
            title="Explore"
            desc="Use our knowledge graph to explore links between clinical trails and diseases, genes, biomarkers, drugs, sponsors, primary and secondary outcomes, adverse events, and more."
          />
          <PlatformComponent
            title="Design"
            desc="Employ models for trial sample size and patient accrual prediction using historical data.
            Gain insights from previous trials about the effect size requirements for specific primary and secondary outcomes."
          />
          <PlatformComponent
            title="Knowledge Graph"
            desc="Knowledge graphs help gauge the viability of future trials. As scientists and management teams swiftly
              estimate a trial’s success, they also gauge how participants and subpopulations will respond to treatments.
              Accompanying information includes toxicity values, side effects, and pharmacological information for the most
              similar compounds."
          />
          <PlatformComponent
            title="Assess Risk"
            desc="Predict side-effect profiles for a particular drug in a specific patient population using clinical data."
          />
          <PlatformComponent
            title="Manage Data"
            desc="Collect clinical trial data across multiple sites on our cloud platform.
              Conveniently manage data ingestion, harmonize data between different sites, and validate its integrity.
              In your patient population, track biomarker measurements, changes in primary endpoints, and instances of adverse events."
          />
          <PlatformComponent
            title="Patient Monitoring"
            desc="Analyze completed and ongoing clinical trials.
              Assess patient risks and identify biomarkers predictive of both positive and negative patient outcomes, and outliers. "
          />
          <PlatformComponent
            title="Contextualize"
            desc="Use the wealth of data available in our biomedical knowledge graph for insights such as identified biomarkers on clinical trial analysis results."
          />
          <PlatformComponent
            title="Track Drugs"
            desc="See adverse-event reports for drugs in the market; perform drug off-target analyses based on molecular and clinical data."
          />
        </div>
        <div style={{ flex: 0.5 }} />
        <div style={{ flex: 1.9 }}>
          {/* <BenefitsDialogBox benefits={benefits} /> */}
          <ServicesWidget
            listName={"Benefits At a Glance"}
            isWhite={false}
            list={benefits}
          />
        </div>
        <div style={{ flex: 0.7 }} />
      </div>
      <br />
      {/*<center>*/}
      {/*  <Suspense fallback="">*/}
      {/*    <Slider {...options}>*/}
      {/*      {*/}
      {/*        images.map((image, idx) => (*/}
      {/*          <div key={idx}>*/}
      {/*            <img style={{ maxWidth: '60%', minWidth: '10%' }} src={image} alt='Clinical Trials Thumbnail' />*/}
      {/*          </div>*/}
      {/*        ))*/}
      {/*      }*/}
      {/*    </Slider>*/}
      {/*  </Suspense>*/}
      {/*</center>*/}
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <center className="desktopKnowledgeGraphPictures" style={{ position: "relative", width: "50%", height: "50%" }}>
          <img className="macbookAir" alt={"MacbookAir"} src={macbookAir} />

          <div
            style={{
              position: "absolute",
              zIndex: 5,
              width: "100%",
              height: "100%",
              top: "9.7%",
              left: "13%",
            }}
          >
            <Slider {...settings}>
              {[1].map((id) => (
                <img key={id} style={{ zIndex: 2 }} src={ctData} alt="Clinical Trials" />
              ))}
            </Slider>
          </div>
        </center>
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <center className="mobileKGSlider" style={{ width: "80%", height: "80%" }}>
            <Slider {...settingsMobile}>
              {[1].map((id) => (
                <img key={id} style={{ zIndex: 2 }} src={ctData} alt="Clinical Trials" />
              ))}
            </Slider>
        </center>
      </div>
    </PageTemplate>
  );
}

export default ClinicalTrials;
