import React, { useEffect, useState } from "react"
import "styles/history.scss";
import patentData from "./patentData";
import PatentWidget from "./PatentWidget";
import PatentModal from "./PatentModal";
import { PageTemplate } from "../PageTemplate"

function PatentPage() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPatent, setCurrentPatent] = useState("");

  useEffect(() => {document.title = "Patents"}, [])

  const closeModal = () => setModalOpen(false);

  const storyStyle = {
    width: "45%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: ".4em",
    fontFamily: "rubikRegular",
  };

  return (
  <PageTemplate>
    <center>
      <center
        className="historyHeader"
        style={{ position: "relative", marginBottom: "1.5em" }}
      >
        Ro5 Patents
        <div style={storyStyle}>
          {`The Ro5 IP portfolio is designed to protect our unique and
          differentiated platforms and technology, with 13 approved patents and
          8 pending approval.`}
        </div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="widgetHolder">
        {patentData.map((singlePatent, id) => (
          <PatentWidget
            key={id}
            name={singlePatent.name}
            noName={singlePatent.noName}
            number={singlePatent.number}
            description={singlePatent.description}
            picture={singlePatent.picture}
            date={singlePatent.date}
            setModalOpen={setModalOpen}
            setCurrentPatent={setCurrentPatent}
          />
        ))}
      </div>
      <PatentModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        currentPatent={currentPatent}
      />
    </center>
  </PageTemplate>
  );
}

export default PatentPage;
