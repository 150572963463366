import React, { useEffect } from "react";

import PlatformHeader from "./PlatformHeader";
import PlatformComponent from "./PlatformComponent";
import KnowledgeGraphInfographic from "res/assets/Infographics/Ro5_KnowledgeGraph.svg";
import ServicesWidget from "Pages/Services/ServicesWidget";

import kgSc from "res/assets/Images/ADMET/knowledge-graph-screenshot.png";
import pubAllWrap from "res/assets/Images/ADMET/publications_all_wrap.png";
import pubPharma from "res/assets/Images/ADMET/publications_pharma.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/knowledgeGraph.scss";
import macbookAir from "../../res/assets/Images/Macbook/Macbook_Air.png";
import { PageTemplate } from "../PageTemplate"

const pictures = [KnowledgeGraphInfographic];
const benefitsList = [
  "Support and cross reference your findings against the entire scientific literature",
  "Supplement your research with access to more than 20 curated structured data sources, all interconnected",
  "Explore, process, analyse data and evaluate results with a large suite of tools integrated directly alongside the data",
  "Save costs and time needed in hypothesis testing and overall end-to-end drug discovery",
];

function KnowledgeGraph() {

  useEffect(() => {document.title = "Knowledge Graph"}, [])

  const settingsMobile = {
    dots: true,
    arrows: false,
    appendDots: (dots) => <div>{dots}</div>,
    lazyLoad: false,

    afterChange: function (index) {
    },
  };

  const settings = {
    dots: true,
    appendDots: (dots) => <div id="macbookSlickDots">{dots}</div>,
    lazyLoad: false,

    afterChange: function (index) {
    },
  };

  useEffect(() => {
    pictures.forEach((img) => (new Image().src = img));
  }, []);

  return (
    <PageTemplate>
      <div>
        <PlatformHeader
          title="Knowledge Graph"
          infographic={KnowledgeGraphInfographic}
          desc={
            "Ro5 Data platform provides you with advanced means to explore, analyze and evaluate biomedical data"
          }
        />
      </div>

      {/* Page Content */}
      <div className="desktopFlex">
        <div style={{ flex: 1 }} />
        <div style={{ flex: 2.5 }}>
          <PlatformComponent
            title="Leverage the universe of biomedical data for new discoveries"
            desc="The Ro5 data platform provides an expansive suite of tools and data to enrich every aspect of the drug discovery and development process. Derive new insights and perform context-driven analyses: target identification, target or drug repurposing, chemical and target similarity search, market opportunity or competitive landscape evaluation, clinical trial and pharmacovigilance data summary."
          />
          <PlatformComponent
            title="Exploratory Drug Analysis"
            desc="Interact with the Ro5DB with an intuitive user interface. Access all our tools, import your data, structure your projects, and extract all the relevant data with no need for programming expertise."
          />
          <PlatformComponent
            title="Target Identification"
            desc="Survey all recent targets in a given disease area. Swiftly identify new target hypotheses for a drug discovery program from ‘omics data and scientific literature. Then augment publicly available information with your own proprietary data from biological assays and ‘omics experiments."
          />
          <PlatformComponent
            title="The Ro5 Drive"
            desc={
              "Organize your project workflow and data. Across the Ro5 suite, all your project-related data and workflows are organized and securely stored for you to organize and structure in one location."
            }
          />
          <PlatformComponent
            title="Knowledge Graph Exploration"
            desc="Know everything available about a chosen disease, drug, target, or mechanism. Our knowledge graph seamlessly incorporates both scientific literature and the biomedical databases, putting a wealth of biomedical data at your fingertips. Move easily from drugs to related targets with experimental activity data, to genes, to pathways, to diseases, to clinical trials. Every element of relevant information on compounds, targets, genes, pathways, clinical trials, and more can be summarized and presented for evaluation."
          />
          <PlatformComponent
            title="Target or Drug Repurposing"
            desc="Explore any target or a drug for potential repurposing. Access an extensive summary of all previous work around a particular target or a drug in a particular disease and area identify the most relevant recent relationships in scientific literature and biomedical databases. You can rank the target-disease and drug-disease associations based on multiple metrics such as relationship strength, credibility, novelty, and over/under exploration.
"
          />
          <PlatformComponent
            title="Market Analytics"
            desc="See the market for a given hypothesis. Before committing your resources to a research program, you can assess its biological rationale, corroborative work, market context, and competitive landscape. Contextualize your hypothesis from integrated resources across the biomedical domain able to gauge its validity. Learn a given market’s size and hidden opportunities. Identify the research institutions and private companies currently working on a particular problem, and evaluate their credentials, track record, and patents."
          />
          <PlatformComponent
            title="Custom Queries"
            desc="State your hypothesis, test them, and hypothesize again. Your Ro5 knowledge graph exploration is as easy as a single keyword and a button click . . .  or as detailed and extensive as you need. From a standard question to a nuanced query, our data scientists and domain experts help you navigate the universe of biomedical data."
          />
          <PlatformComponent
            title="Confidence Score"
            desc="Evaluate relationships. Prioritize relationships by their strength, credibility, novelty, over/under exploration, and many other metrics."
          />
          <PlatformComponent
            title="Smart Search"
            desc="Search directly for — or in the local space around — a given compound/target/disease/mechanism. Search for particular compounds, compounds with similar structures, or their substructures, and match protein sequence motifs against PDB. Find similar binding sites and collect their assay data."
          />
        </div>
        <div style={{ flex: 0.5 }} />
        <div style={{ flex: 1.9 }}>
          <div className="kgMobileWidget">
            <ServicesWidget
              listName={"Benefits At a Glance"}
              isWhite={false}
              list={benefitsList}
            />
          </div>
          {/* <BenefitsDialogBox benefits={benefitsList} /> */}
          {/* <img className="sidePictures" src={kgSc} alt="Knowledge Graph" />
          <img
            className="sidePictures"
            src={pubAllWrap}
            alt="Publications All Wrap"
          />
          <img
            className="sidePictures"
            src={pubPharma}
            alt="Publications Pharma"
          /> */}
        </div>
        <div style={{ flex: 0.7 }} />
        <div className="mobileKGSlider">
          <Slider {...settingsMobile}>
            {[1, 2, 3].map((id) =>
              id === 1 ? (
                <img
                  key={id}
                  style={{ zIndex: 2 }}
                  src={kgSc}
                  alt="Knowledge Graph"
                />
              ) : id === 2 ? (
                <img
                  key={id}
                  style={{ zIndex: 2 }}
                  src={pubAllWrap}
                  alt="Publications All Wrap"
                />
              ) : (
                <img
                  key={id}
                  style={{ zIndex: 2 }}
                  src={pubPharma}
                  alt="Publications Pharma"
                />
              )
            )}
          </Slider>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "5vh",
        }}
      >
        <center
          className="desktopKnowledgeGraphPictures"
          style={{ position: "relative", width: "50%", height: "50%" }}
        >
          <img className="macbookAir" alt={"MacbookAir"} src={macbookAir} />

          <div
            style={{
              position: "absolute",
              zIndex: 5,
              width: "100%",
              height: "100%",
              top: "9.7%",
              left: "14%",
            }}
          >
            <Slider {...settings}>
              {[1, 2, 3].map((id) =>
                id === 1 ? (
                  <img
                    key={id}
                    style={{ zIndex: 2 }}
                    src={kgSc}
                    alt="Knowledge Graph"
                  />
                ) : id === 2 ? (
                  <img
                    key={id}
                    style={{ zIndex: 2 }}
                    src={pubAllWrap}
                    alt="Publications All Wrap"
                  />
                ) : (
                  <img
                    key={id}
                    style={{ zIndex: 2 }}
                    src={pubPharma}
                    alt="Publications Pharma"
                  />
                )
              )}
            </Slider>
          </div>
        </center>
      </div>
    </PageTemplate>
  );
}

export default KnowledgeGraph;
