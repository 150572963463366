import React, { useEffect, useMemo, useState } from "react"
import LandingPageContent from "./LandingPageContent"
import LandingPageHeader from "./LandingPageHeader"

import protein from "res/assets/Images/Home.png"

import 'styles/landing.scss';
import { PageTemplate } from "../PageTemplate"

function LandingPage() {
    const [currentLink, setCurrentLink] = useState("")
    const headerImages = useMemo(() => [protein], [])

    useEffect(() => {document.title = "Ro5"}, [])

    useEffect(function () {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });

        headerImages.forEach(img => new Image().src = img);
    }, [headerImages])

    return (
        <PageTemplate>
            <div className="landing">
                <LandingPageHeader images={headerImages} />
            </div>

            <div>
                <div id="Discover" style={{ maxWidth: '100vw', margin: 'auto', paddingTop: '0em', color: 'black', }}>
                    <LandingPageContent currentLink={currentLink} setCurrentLink={setCurrentLink} />
                </div>
            </div>
        </PageTemplate>
    )
}

export default LandingPage