import React, { useEffect } from "react";
import ServicesWidget from "./ServicesWidget";
import ongoing from "res/assets/Images/Services/Ongoing_CTA.svg";
import retrospective from "res/assets/Images/Services/Retrospective_CTA.svg";
import SmallParagraph from "./SmallParagraph";

import "styles/services.scss";
import { PageTemplate } from "../PageTemplate"

function ClinicalTrialAnalytics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageTemplate>
      <center
        className="historyHeader"
        style={{
          position: "relative",
          marginBottom: "1.1em",
        }}
      >
        <div className="serviceSectionTitle">
          <div style={{ width: "90%" }}>Clinical Trial Programs</div>
        </div>
        <div className="servicesStory">
          Reduce the risks of clinical trials by effectively stratifying your
          patient groups and flagging patients at risk using Ro5’s Clinical
          Trial Analytics program.
        </div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="whiteBgFlex">
        <div style={{ flex: 0.6 }} />
        <div style={{ flex: 1.2 }}>
          <div className="">
            <div className="whiteBgIntroText">
              Effectively stratify patients, identify risks, and contextualize
              clinical trials using Ro5’s Clinical Trial Analytics Platform. Our
              patented analytics technology, augmented with a web of biomedical
              data and literature, provides cutting edge solutions to derisk
              clinical trials and support the New Drug Application (NDA)
              process.
            </div>
            <SmallParagraph
              title={"Clinical trial analysis"}
              content={
                "Use ML methods to carry out unbiased clinical trial analysis."
              }
            />
            <SmallParagraph
              title={"Patient contextualisation"}
              content={
                "Receive comprehensive summaries of potential risks for patient subgroups through Ro5’s Clinical Trial Analytics Platform and Knowledge Graph"
              }
            />
            <SmallParagraph
              title={"Clinical trial contextualization"}
              content={
                "Understand a clinical trial in the context of historic data to derive additional insights from the previous successes and failures to support clinical trial planning and interpretation of results."
              }
            />
          </div>
        </div>
        <div style={{ flex: 0.3 }} />
        <div style={{ flex: 1 }}>
          <ServicesWidget
            listName={"Benefits:"}
            isWhite={false}
            list={[
              "Derisk the clinical trial process by stratifying patient groups",
              "Identify the risk for serious adverse events in patients",
              "Leverage vast clinical data resources",
              "Learn the context of biomarkers, compounds, and disease targets",
            ]}
          />
        </div>
        <div style={{ flex: 0.6 }} />
      </div>
      <div className="whiteBgFlexMobile">
        <div>
          <div className="">
            <div className="whiteBgIntroText">
              Effectively stratify patients, identify risks, and contextualize
              clinical trials using Ro5’s Clinical Trial Analytics Platform. Our
              patented analytics technology, augmented with a web of biomedical
              data and literature, provides cutting edge solutions to derisk
              clinical trials and support the New Drug Application (NDA)
              process.
            </div>
            <SmallParagraph
              title={"Clinical trial analysis"}
              content={
                "Use ML methods to carry out unbiased clinical trial analysis."
              }
            />
            <SmallParagraph
              title={"Patient contextualisation"}
              content={
                "Receive comprehensive summaries of potential risks for patient subgroups through Ro5’s Clinical Trial Analytics Platform and Knowledge Graph"
              }
            />
            <SmallParagraph
              title={"Clinical trial contextualization"}
              content={
                "Understand a clinical trial in the context of historic data to derive additional insights from the previous successes and failures to support clinical trial planning and interpretation of results."
              }
            />
          </div>
        </div>
        <ServicesWidget
          listName={"Benefits:"}
          isWhite={false}
          list={[
            "Derisk the clinical trial process by stratifying patient groups",
            "Identify the risk for serious adverse events in patients",
            "Leverage vast clinical data resources",
            "Learn the context of biomarkers, compounds, and disease targets",
          ]}
        />
      </div>
      <div
        className="blueBg"
        style={{
          paddingTop: "8vh",
          paddingBottom: "8vh",
          position: "relative",
        }}
      >
        <div className="whiteBgFlex">
          <div style={{ flex: 0.6 }} />
          <div style={{ flex: 1.2 }}>
            <div
              className="bold"
              style={{
                fontSize: "42.75px",
                marginBottom: "3vh",
                color: "#FFFFFF",
              }}
            >
              Trials and Phases
            </div>
            <div className="blueBgIntroText">
              Trials and phases are chosen based on their relevance and in the
              context of ongoing or upcoming trials or other interests. Ro5
              provides the Clinical Trial Analytics services in two modes:
              retrospective and ongoing trial analysis.
            </div>
          </div>
          <div style={{ flex: 0.3 }} />
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <img src={ongoing} alt={"Ongoing"} style={{ width: "115px" }} />
              <div style={{ marginTop: "1.2vh", marginLeft: "1vw" }}>
                <div
                  className={"paraTitleWhite"}
                  style={{ marginBottom: "25px" }}
                >
                  Ongoing Clinical Trial Analytics
                </div>
                <div className={"paraContentWhite"}>
                  Flag risky patients and monitor them closely to derisk the
                  ongoing trials.
                </div>
                <div className={"paraContentWhite"}>
                  Contextualize the observed phenomena with public data to make
                  data driven decisions fast.
                </div>
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <img src={retrospective} alt={"Retrospective"} style={{ width: "115px" }} />
              <div style={{ marginTop: "1.2vh", marginLeft: "1vw" }}>
                <div
                  className={"paraTitleWhite"}
                  style={{ marginBottom: "25px" }}
                >
                  Retrospective Clinical Trial Analytics
                </div>
                <div className={"paraContentWhite"}>
                  Analyze former phases to better prepare for subsequent phases.
                </div>
                <div className={"paraContentWhite"}>
                  Find additional evidence to support the results.
                </div>
              </div>
            </div>
          </div>
          <div style={{ flex: 0.6 }} />
        </div>
        <div className="whiteBgFlexMobile">
          <div>
            <div
              className="bold"
              style={{
                fontSize: "42.75px",
                marginBottom: "3vh",
                color: "#FFFFFF",
              }}
            >
              Trials and Phases
            </div>
            <div className="blueBgIntroText">
              Trials and phases are chosen based on their relevance and in the
              context of ongoing or upcoming trials or other interests. Ro5
              provides the Clinical Trial Analytics services in two modes:
              retrospective and ongoing trial analysis.
            </div>
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <img src={ongoing} alt={"Ongoing"} style={{ width: "115px" }} />
              <div style={{ marginTop: "1.2vh", marginLeft: "1vw" }}>
                <div
                  className={"paraTitleWhite"}
                  style={{ marginBottom: "25px" }}
                >
                  Ongoing Clinical Trial Analytics
                </div>
                <div className={"paraContentWhite"}>
                  Flag risky patients and monitor them closely to derisk the
                  ongoing trials.
                </div>
                <div className={"paraContentWhite"}>
                  Contextualize the observed phenomena with public data to make
                  data driven decisions fast.
                </div>
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <img src={retrospective} alt={"Retrospective"} style={{ width: "115px" }} />
              <div style={{ marginTop: "1.2vh", marginLeft: "1vw" }}>
                <div
                  className={"paraTitleWhite"}
                  style={{ marginBottom: "25px" }}
                >
                  Retrospective Clinical Trial Analytics
                </div>
                <div className={"paraContentWhite"}>
                  Analyze former phases to better prepare for subsequent phases.
                </div>
                <div className={"paraContentWhite"}>
                  Find additional evidence to support the results.
                </div>
              </div>
            </div>
          </div>
          <div style={{ flex: 0.6 }} />
        </div>
        <div className="bg-core-polygon" />
        <div className="bg-core-molecule" />
      </div>
    </PageTemplate>
  );
}

export default ClinicalTrialAnalytics;
