import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import "styles/history.scss";
import NewsWidget from "./NewsWidget";
import testPhoto from "../../../res/assets/Images/Paper_Preview.png";
import newMemberSarahThumbnail from "../../../res/assets/Images/NewsArticles/Sarah_Flatters_thumbnail.png";
import newMemberBrock from "../../../res/assets/Images/NewsArticles/brock_lowqual.jpg";
import newMemberHisham from "../../../res/assets/Team/HishamAbdelAty_thumbnail.png";
import hitsDiscoveredComparisonImage from "res/assets/Infographics/hits_discovered_comparison.png";
import hydraScreenPerformanceSmallImage from "res/assets/Infographics/HydraScreen_performance.png";
import hydraScreenModelThumbnail from "res/assets/Images/NewsArticles/HydraScreen_model_thumbnail.png";
import spectraviewV1Thumbnail from "res/assets/Images/NewsArticles/spectraview_screenshot_thumbnail.png";
import newMemberPiero from "../../../res/assets/Team/piero_thumbnail.png";
import newMemberTanya from "../../../res/assets/Team/tanya_small.png";
import labOfTheFutureThumbnail from "../../../res/assets/Images/NewsArticles/Lab of the Future - Amsterdam 2023 - Amsterdam Photographer unmaskphotography-253_thumbnail.png"
import irak1HitsUmapImage from "../../../res/assets/Images/NewsArticles/IRAK1_hits_UMAP.png"
import molecule1Thumbnail from "res/assets/Molecules/Molecule1_thumbnail.png";
import proteinIconThumbnail from "res/assets/Images/NewsArticles/protein_with_icon_thumbnail.png";
import teamUp from "res/assets/Images/NewsArticles/Ro5StrateosTeam_BIG_thumbnail.png";
import { titleToPath } from "../../../utils"
import { PageTemplate } from "../../PageTemplate"
import { NEWS_TITLES } from "./NewsPageHandler"
import { CASE_STUDIES_DATA } from "Pages/CaseStudies/CaseStudiesData";

function NewsMasterPage() {
  const navigate = useNavigate();

  useEffect(() => {document.title = "News"}, [])

  const storyStyle = {
    width: "45%",
    minWidth: 250,
    paddingTop: ".3em",
    margin: "auto",
    fontSize: "67.5px",
    fontFamily: "rubikRegular",
  };

  function handleArticleClick(newsTitle) {
    navigate(`/news/${titleToPath(String(newsTitle))}`);
  }

  function handleCaseStudyClick(newsTitle) {
    navigate(`/case-studies/${titleToPath(String(newsTitle))}`);
  }

  return (
  <PageTemplate>
    <center>
      <center
        className="historyHeader"
        style={{ position: "relative", marginBottom: "1.5em" }}
      >
        <div style={storyStyle}>Ro5 News</div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
      </center>

      <div className="newsMasterPage">
        {/* ! THE NEWS HAVE TO BE ORDERED BY DATE ! */}
        <div onClick={() => handleArticleClick(NEWS_TITLES.SPECTRA_VIEW_V1_APP_RELEASE)} style={{ cursor: "pointer" }}>
          <NewsWidget
              imgSrc={spectraviewV1Thumbnail}
              unstreched={true}
              title={NEWS_TITLES.SPECTRA_VIEW_V1_APP_RELEASE}
              shortDescription={
                  "We are announcing the release of the SpectraView v1 web application."
              }
              date={"10.10.2023"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.HYDRA_SCREEN_APPLET_RELEASE)} style={{ cursor: "pointer" }}>
          <NewsWidget
              imgSrc={hydraScreenModelThumbnail}
              title={NEWS_TITLES.HYDRA_SCREEN_APPLET_RELEASE}
              shortDescription={
                  "We are announcing the release of the HydraScreen web application in support of the preprint."
              }
              date={"06.10.2023"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.HYDRA_SCREEN_A_GENERALIZABLE_STRUCTURE_BASED_DEEP_LEARNING_APPROACH_TO_DRUG_DISCOVERY)} style={{ cursor: "pointer" }}>
          <NewsWidget
              imgSrc={hydraScreenPerformanceSmallImage}
              title={NEWS_TITLES.HYDRA_SCREEN_A_GENERALIZABLE_STRUCTURE_BASED_DEEP_LEARNING_APPROACH_TO_DRUG_DISCOVERY}
              shortDescription={
                  "This preprint introduces HydraScreen - an advanced tool for faster and more accurate drug discovery."
              }
              date={"06.10.2023"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.ACCELERATED_HITID_WITH_TARGET_EVAL_DEEP_LEARNING_AND_AUTOMATED_LABS)} style={{ cursor: "pointer" }}>
          <NewsWidget
              imgSrc={irak1HitsUmapImage}
              title={NEWS_TITLES.ACCELERATED_HITID_WITH_TARGET_EVAL_DEEP_LEARNING_AND_AUTOMATED_LABS}
              shortDescription={
                  "This preprint presents a study in which Ro5’s researchers combined the SpectraView tool..."
              }
              date={"26.09.2023"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.THE_LAB_OF_THE_FUTURE_CONFERENCE_2023)} style={{ cursor: "pointer" }}>
          <NewsWidget
              imgSrc={labOfTheFutureThumbnail}
              title={NEWS_TITLES.THE_LAB_OF_THE_FUTURE_CONFERENCE_2023}
              unstreched={true}
              shortDescription={
                  "The Lab of the Future conference is a seminal event where software vendors, lab automation providers, and pharma companies meet to discuss the future vision of lab technology."
              }
              date={"26.09.2023"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.AI_DRIVEN_HIGH_THROUGHPUT_SCREENING_A_NEW_PARADIGM_IN_DRUG_DISCOVERY)} style={{ cursor: "pointer" }}>
          <NewsWidget
              imgSrc={hitsDiscoveredComparisonImage}
              title={NEWS_TITLES.AI_DRIVEN_HIGH_THROUGHPUT_SCREENING_A_NEW_PARADIGM_IN_DRUG_DISCOVERY}
              shortDescription={
                  "Ro5 used HydraScreen to screen a library of compounds and compared its performance to methods like docking, QSAR, pharmacophore, and shape analysis."
              }
              date={"01.09.2023"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.HISHAM_ABDEL_ATY_JOINS)} style={{ cursor: "pointer" }}>
          <NewsWidget
            imgSrc={newMemberHisham}
            title={NEWS_TITLES.HISHAM_ABDEL_ATY_JOINS}
            shortDescription={
              "We are excited to announce that Hisham Abdel Aty has joined Ro5 as Principal Computational Chemist."
            }
            date={"01.06.2023"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.PIERO_GASPAROTTO_JOINS)} style={{ cursor: "pointer" }}>
          <NewsWidget
            imgSrc={newMemberPiero}
            title={NEWS_TITLES.PIERO_GASPAROTTO_JOINS}
            shortDescription={
              "We are excited to announce that Piero Gasparotto, has joined Ro5 as Principal ML Scientist."
            }
            date={"01.03.2022"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.TANYA_PAQUET_JOINS)} style={{ cursor: "pointer" }}>
          <NewsWidget
            imgSrc={newMemberTanya}
            title={NEWS_TITLES.TANYA_PAQUET_JOINS}
            shortDescription={
              "We are excited to announce that Tanya Paquet, has joined Ro5 as Principal Computational Medicinal Chemist."
            }
            date={"22.07.2022"}
          />
        </div>
        <div onClick={() => handleCaseStudyClick(CASE_STUDIES_DATA.RO5_STRATEOS_TEAM_UP.title)} style={{ cursor: "pointer" }}>
          <NewsWidget
            imgSrc={teamUp}
            wide={true}
            title={CASE_STUDIES_DATA.RO5_STRATEOS_TEAM_UP.title}
            shortDescription={CASE_STUDIES_DATA.RO5_STRATEOS_TEAM_UP.shortDescription}
            date={CASE_STUDIES_DATA.RO5_STRATEOS_TEAM_UP.date}
          />
        </div>
        <div onClick={() => handleCaseStudyClick(CASE_STUDIES_DATA.RO5_WEILL_CORNELL_NEWS.title)} style={{ cursor: "pointer" }}>
          <NewsWidget
            imgSrc={proteinIconThumbnail}
            title={CASE_STUDIES_DATA.RO5_WEILL_CORNELL_NEWS.title}
            shortDescription={CASE_STUDIES_DATA.RO5_WEILL_CORNELL_NEWS.shortDescription}
            date={CASE_STUDIES_DATA.RO5_WEILL_CORNELL_NEWS.date}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.BROCK_LUTY_JOINS)} style={{ cursor: "pointer" }}>
          <NewsWidget
            imgSrc={newMemberBrock}
            title={NEWS_TITLES.BROCK_LUTY_JOINS}
            shortDescription={
              "Ro5 Welcomes Dr. Brock Luty As Principal Computational Chemist."
            }
            date={"17.01.2022"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.SARAH_FLATTERS_JOINS)} style={{ cursor: "pointer" }}>
          <NewsWidget
            imgSrc={newMemberSarahThumbnail}
            title={NEWS_TITLES.SARAH_FLATTERS_JOINS}
            shortDescription={
              "We are excited to announce that Sarah Flatters, Ph.D., is the Principal Translational Medicine Data Scientist at Ro5."
            }
            date={"17.01.2022"}
          />
        </div>
        <div onClick={() => handleArticleClick(NEWS_TITLES.NELFINAVIR_TITLE)} style={{ cursor: "pointer" }}>
          <NewsWidget
            imgSrc={molecule1Thumbnail}
            title={NEWS_TITLES.NELFINAVIR_TITLE}
            shortDescription={
              "Nelfinavir (Viracept), the drug that Ro5 identified for repurposing towards Covid-19 early on in the pandemic, entered clinical trials for Covid-19 in April 2021."
            }
            date={"15.04.2021"}
          />
        </div>

        <div onClick={() => handleArticleClick(NEWS_TITLES.COVID_19_PAPER_PUBLISHED_TITLE)} style={{ cursor: "pointer" }}>
          <NewsWidget
            imgSrc={testPhoto}
            title={NEWS_TITLES.COVID_19_PAPER_PUBLISHED_TITLE}
            shortDescription={
              "As the clock ticks on Covid-19 research, in a new ChemRxiv.org preprint Ro5 adds vital information."
            }
            date={"13.05.2020"}
          />
        </div>
      </div>
    </center>
  </PageTemplate>
  );
}

export default NewsMasterPage;
