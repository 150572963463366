import React from "react";
import "styles/services.scss";

function FeatureComponent(props) {
  const { title, icon, white = false, largerIcon = false } = props;
  return (
    <div className="featureComponent">
      <div>
        <img
          style={
            largerIcon
              ? { maxWidth: "80px", marginRight: "23px" }
              : { maxWidth: "60px", marginRight: "23px" }
          }
          src={icon}
          alt="icon"
        />
      </div>
      <div
        className="FC-TitleHolder"
        style={largerIcon ? { width: "100%" } : {}}
      >
        <div className="FC-Title" style={white ? { color: "#FFFFFF" } : {}}>
          {title}
        </div>
      </div>
    </div>
  );
}

export default FeatureComponent;
