import React from "react";

import "styles/services.scss";

function SmallParagraph(props) {
  const {
    title,
    content,
    white = false,
  } = props;

  return (
    <div>
      <div>
        {title !== null ? (
          <div className={white ? "paraTitleWhite" : "paraTitle"}>{title}</div>
        ) : (
          <></>
        )}
        {content !== null ? (
          <div className={white ? "paraContentWhite" : "paraContent"}>
            {content}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default SmallParagraph;
